import { Avatar, Box, Button, Paper, Typography, Stack } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useMeetingStatus } from 'components/hooks/useMeetingStatus';
import { IMeeting } from 'types';
import { useLocale, useUserShow } from '@koopajs/react';
import { StartMeeting } from 'components/Modals/StartMeeting';
import { useCallback, useState } from 'react';
import { DateTime } from 'luxon';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import GestureIcon from '@mui/icons-material/Gesture';
import { Link as RouterLink } from 'react-router-dom';

interface IStartMeetingBannerProps {
  meeting: IMeeting;
  sx?: SxProps<Theme>;
}

export const StartMeetingBanner: React.FC<IStartMeetingBannerProps> = (props) => {
  const { meeting, sx } = props;

  const { t } = useLocale();
  const keyPrefix = 'Components.StartMeetingBanner';

  const [isStartMeetingModalOpen, setIsStartMeetingModalOpen] = useState(false);

  const handleOpenStartMeetingModal = useCallback(() => {
    setIsStartMeetingModalOpen(true);
  }, []);

  const handleCloseStartMeetingModal = useCallback(() => {
    setIsStartMeetingModalOpen(false);
  }, []);

  const { user } = useUserShow();

  const checkIfMeetingIsToday = (): boolean => {
    if (!meeting.startDateTime) return false;
    const dateToCheck = DateTime.fromISO(meeting.startDateTime);
    const today = DateTime.local();

    // Compare the year, month, and day
    return dateToCheck.hasSame(today, 'day');
  };
  const isMeetingToday = checkIfMeetingIsToday();

  const { canUserContinueMeeting, isMeetingReadyToStart, hasMeetingStarted, isMeetingAgendaSent } =
    useMeetingStatus({ meeting, user });

  //covers the meetings that did not require an agenda to be sent in the past
  const isMeetingMarkAsSent = Boolean(meeting.markAsSentAt);

  // if meeting has started, user can't continue meeting and there is no location - don't show the banner
  if (hasMeetingStarted && !canUserContinueMeeting && !meeting.location?.url && !meeting.location?.address)
    return null;

  return (
    <Paper sx={{ p: 3, ...sx }} variant="outlined">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: '14px', sm: 0 }
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, mr: 'auto' }}>
          <Avatar sx={isMeetingToday ? { bgcolor: 'secondary.lighter' } : {}}>
            <PlayArrowIcon sx={isMeetingToday ? { color: 'primary.main' } : {}} />
          </Avatar>
          <Typography component="span" variant="body1" color="text.primary">
            {t(keyPrefix + '.labelStartMeeting')}
          </Typography>
        </Box>

        <Stack gap="14px" flexDirection="row">
          {meeting.location?.url && (
            <Button
              variant="outlined"
              startIcon={<OpenInNewIcon />}
              href={meeting.location?.url}
              target="_blank"
              rel="noopener"
            >
              {t(keyPrefix + '.labelButtonJoinMeeting')}
            </Button>
          )}

          {canUserContinueMeeting && (
            <>
              <StartMeeting
                meeting={meeting}
                userId={user?.id}
                isOpen={isStartMeetingModalOpen}
                onClose={handleCloseStartMeetingModal}
              />
              <Button
                variant="contained"
                onClick={handleOpenStartMeetingModal}
                disabled={!isMeetingReadyToStart}
                startIcon={<PlayArrowIcon />}
              >
                {t(`${keyPrefix}.labelWriteMinutes`)}
              </Button>
            </>
          )}
        </Stack>
      </Box>
      {meeting.location?.address && (
        <Stack direction="row" justifyContent="end" sx={{ mt: 1 }}>
          <Typography sx={{ display: 'inline' }} variant="body2" color="text.secondary">
            {meeting.location?.address}
          </Typography>
        </Stack>
      )}
      {!isMeetingAgendaSent && !isMeetingMarkAsSent && (
        <Typography sx={{ textAlign: { sm: 'right' }, color: 'text.secondary', mt: 1 }}>
          {t(keyPrefix + '.sendAgendaAlert')}
        </Typography>
      )}
    </Paper>
  );
};
