import { forwardRef } from 'react';
import { IResolution } from 'types';
import { Box, Typography, Divider } from '@mui/material';
import { ResolutionOutcomeText } from 'components/ResolutionOutcome/ResolutionOutcomeText';
import { SignatureList } from 'components/Signature/SignatureList';
import { useWorkspaceShow } from '@koopajs/react';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';
import { useLocale } from '@koopajs/react';
import { isEmpty } from 'lodash';
import { ResolutionMinutesDetailsCard } from 'components/ResolutionMinutesDetailsCard';
import { WrittenResolutionDialogDetailsCard } from 'components/Resolutions/WrittenResolutionDialogDetailsCard';
import { getSignaturesWithTitles } from 'utils/getSignaturesWithTitles';
import { ResolutionOutcomeVotes } from 'components/ResolutionOutcome/ResolutionOutcomeVotes';
import { theme } from 'components/Core/theme';

interface IDialogViewResolutionProps {
  resolution?: IResolution;
  ref: React.ForwardedRef<unknown>;
}

export const ViewResolutionPrintVersion: React.FC<IDialogViewResolutionProps> = forwardRef((props, ref) => {
  const { resolution } = props;
  const { workspace } = useWorkspaceShow();
  const { t } = useLocale();
  const keyPrefix = 'Resolutions.DialogView';

  const isAttachedToMeetingTopic = Boolean(resolution?.attachedToMeetingTopic);
  const isAttachedToEmail = Boolean(resolution?.attachedToEmail);

  const { membersAgainst, membersAbstained, membersFor } = resolution?.resolutionOutcome || {};
  const showResolutionOutcomeVotes = membersFor?.length || membersAbstained?.length || membersAgainst?.length;

  const signaturesWithTitles = getSignaturesWithTitles(resolution);

  if (!resolution) return null;

  return (
    <Box
      ref={ref}
      className="rr-block"
      sx={{
        display: 'flex',
        '@media print': {
          '@page': {
            margin: '1in'
          },
          '@page:first': {
            marginTop: '0.7in'
          }
        },
        flexDirection: 'column',
        gap: '14px'
      }}
    >
      {/* LOGO */}
      {workspace?.icon && (
        <Box>
          <img src={workspace.icon} alt="Logo" style={{ maxHeight: '50px', maxWidth: '200px' }} />
        </Box>
      )}
      {isAttachedToMeetingTopic && (
        <ResolutionMinutesDetailsCard resolution={resolution} title={t('Resolutions.resolutionExtract')} />
      )}

      {resolution.attachedToEmail && <WrittenResolutionDialogDetailsCard resolution={resolution} />}
      <Divider />
      <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
        <Typography variant="body2">
          {resolution.title
            ? resolution.title
            : resolution.attachedToMeetingTopic?.type
            ? t('common:topicTypes.' + resolution.attachedToMeetingTopic.type)
            : null}
        </Typography>
      </Box>
      {resolution?.notes && (
        <RichTextReadOnly
          value={resolution.notes}
          sxEditorContainer={{
            ...theme.typography.body2
          }}
        />
      )}
      {isAttachedToMeetingTopic &&
        resolution?.resolutionOutcome &&
        !isEmpty(resolution.resolutionOutcome) && (
          <Typography variant="body2" sx={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
            <ResolutionOutcomeText topic={resolution} />
            {showResolutionOutcomeVotes && <ResolutionOutcomeVotes topic={resolution} hideIcon />}{' '}
          </Typography>
        )}
      {isAttachedToEmail && resolution.resolutionOutcome?.outcome === 'declined' && (
        <>
          <Divider />
          <Box>{t(keyPrefix + '.writtenResolutionSignatureDescriptionDeclined')}</Box>
        </>
      )}
      {signaturesWithTitles && (
        <>
          <Divider />
          <Typography variant="body2">
            <SignatureList
              isPrintMode={true}
              signatures={signaturesWithTitles}
              description={
                isAttachedToEmail &&
                resolution.resolutionOutcome?.outcome === 'approved' && (
                  <Box>{t(keyPrefix + '.writtenResolutionSignatureDescriptionApproved')}</Box>
                )
              }
            />
          </Typography>
        </>
      )}
    </Box>
  );
});
