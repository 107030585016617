import { IMeeting } from 'types';
import { Stack, Typography, Box, List } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { DateTimeStartEnd } from 'components/DateTimeStartEnd';
import { getCommitteeMembersTitlesObj } from 'utils/getCommitteeMembersTitlesObj';
import { useCommitteeName } from '../hooks/useCommitteeName';
import { TimeStartEnd } from 'components/TimeStartEnd';
import { UsersWithTitlesList } from 'components/UsersWithTitlesList';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface IMeetingHeaderCard {
  meeting: IMeeting;
}

export const MeetingHeaderCardPrint: React.FC<IMeetingHeaderCard> = (props) => {
  const { meeting } = props;

  const { t } = useLocale();
  const keyPrefix = 'MeetingEdit';

  const minutesType = useCommitteeName(meeting.committeeId);

  const committeeMembersTitlesObj = getCommitteeMembersTitlesObj(meeting.activeCommitteeMembers);

  return (
    <Box
      sx={{
        display: 'flex'
      }}
    >
      <Stack sx={{ flexGrow: 1, minWidth: 0 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            marginBottom: '14px'
          }}
        >
          <Typography variant="overline">{t(keyPrefix + '.titleNotice')}</Typography>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 1, flexWrap: 'wrap' }}>
            <Typography variant="h6">
              {' '}
              <DateTimeStartEnd isoStringDate={meeting.startDateTime} dateFormat="DATE_HUGE" />
            </Typography>{' '}
            <Typography variant="h6" sx={{ display: { xs: 'none', sm: 'inline-block' } }}>
              {' | '}
            </Typography>
            <Typography variant="h6"> {minutesType}</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            <Typography variant="body2">{meeting?.title}</Typography>
            <Typography variant="body2" sx={{ opacity: 0.6 }}>
              &#183;
            </Typography>

            <Typography variant="body2" sx={{ opacity: 0.6 }}>
              <TimeStartEnd isoStringStart={meeting?.startDateTime} isoStringEnd={meeting?.endDateTime} />
            </Typography>
          </Box>{' '}
          <Box sx={{ display: 'flex', gap: 1 }}>
            {meeting.location?.type && (
              <Typography variant="body2" sx={{ opacity: 0.6 }}>
                {t(`common:locationType.${meeting?.location?.type}`)}
              </Typography>
            )}

            {meeting.location?.url && (
              <>
                <Typography variant="body2" sx={{ opacity: 0.6 }}>
                  &#183;
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'link.main'
                  }}
                >
                  {t('common:meetingLink')}
                  <OpenInNewIcon fontSize="small" sx={{ ml: 0.5 }} />
                </Typography>
              </>
            )}
          </Box>
          {meeting.location?.address && (
            <Typography variant="body2" sx={{ opacity: 0.6 }}>
              {meeting?.location?.address}
            </Typography>
          )}
        </Box>

        {meeting?.participants && meeting.participants.length > 0 && (
          <Box>
            <Typography variant="caption">
              {`${t('common:guests')} (${meeting?.participants?.length})`}
            </Typography>
            <List sx={{ pt: 0 }}>
              <UsersWithTitlesList
                userIds={meeting.participants.map((p) => p.userId)}
                committeeMembersTitles={committeeMembersTitlesObj}
              />
            </List>
          </Box>
        )}
      </Stack>
    </Box>
  );
};
