import React from 'react';
import { ICoreProps } from '@koopajs/mui';
import { Button, Typography, Container, Divider, Tabs, Tab, Box, useMediaQuery } from '@mui/material';
import { Link, Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useLocale } from '@koopajs/mui/dist/useCoreLocale';

export interface IMultiPageContainerProps extends ICoreProps {
  /**
   * @deprecated Do not use. Will be removed soon.
   */
  title?: React.ReactNode;
  homepagePath: string;
  rightButton?: React.ReactNode;
  pages: {
    component: React.FC;
    title: React.ReactNode;
    path: string;
  }[];
}

export const MultiPageContainerTemp: React.FC<IMultiPageContainerProps> = (props) => {
  const { i18n, sx, homepagePath, pages, rightButton } = props;
  const activePage = useLocation().pathname.split('/')[2];
  const activePageTitle = pages.find((page) => page.path === activePage)?.title;
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useLocale({ ...i18n, coreKeyPrefix: 'LayoutMultiPageContainer' });

  return (
    <Container maxWidth={false} sx={sx}>
      {(isLargeScreen || !activePage) && (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 1
            }}
          >
            <Typography variant="h1" style={{ fontSize: 24, display: 'inline-block' }}>
              {t('title')}
            </Typography>
            {rightButton}
          </Box>
        </>
      )}
      {!isLargeScreen && activePage && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            my: 2
          }}
        >
          <Button component={Link} to={homepagePath} startIcon={<ArrowBackIosNewIcon />}>
            {t('labelBack')}
          </Button>
          <Typography variant="h1" sx={{ fontSize: 18, fontWeight: 500 }}>
            {activePageTitle}
          </Typography>
          <Button component={Link} to="/">
            {t('labelDone')}
          </Button>
        </Box>
      )}
      {(isLargeScreen || !activePage) && (
        <>
          <Tabs
            value={isLargeScreen ? activePage || pages[0].path : false}
            orientation={isLargeScreen ? 'horizontal' : 'vertical'}
            variant={isLargeScreen ? 'scrollable' : 'fullWidth'}
            scrollButtons="auto"
          >
            {pages.map((page) => (
              <Tab
                key={page.path}
                value={page.path}
                label={page.title}
                component={Link}
                to={`${homepagePath}/${page.path}`}
                icon={isLargeScreen ? '' : <ChevronRightIcon />}
                iconPosition="end"
                sx={{ justifyContent: 'space-between' }}
              />
            ))}
          </Tabs>
          <Divider sx={{ mb: 4 }} />
        </>
      )}
      <Switch>
        {pages.map((page) => (
          <Route key={page.path} path={`${homepagePath}/${page.path}`} component={page.component} />
        ))}

        {isLargeScreen && <Redirect exact from={homepagePath} to={`${homepagePath}/${pages[0].path}`} />}
      </Switch>
    </Container>
  );
};
