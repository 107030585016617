import { useLocale } from '@koopajs/react';
import { Chip, SxProps, Theme, Typography } from '@mui/material';

export const chipColorNames: { [key: string]: string } = {
  information: 'bluePurple',
  discussion: 'orange',
  resolution: 'greenOne'
};

interface ITopicChip {
  topicType: string;
  sx?: SxProps<Theme>;
  isOutlined?: boolean;
}

export const TopicChip: React.FC<ITopicChip> = (props) => {
  const { topicType, sx, isOutlined } = props;
  const { t } = useLocale();
  // accept information, resolution, discussion
  if (!topicType) return null;

  const chipColor = chipColorNames[topicType];

  const borderColor = isOutlined ? `${chipColor}.100` : undefined;

  return (
    <Chip
      label={t(`common:topicTypes.${topicType}`)}
      sx={{
        ...sx,
        backgroundColor: `${chipColor}.30`,
        ...(isOutlined ? { border: '1px solid', borderColor } : {}),
        '@media print': {
          borderColor: 'customGrey.light'
        }
      }}
    />
  );
};
