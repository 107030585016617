import React, { useCallback, useMemo, useState } from 'react';
import { Form } from '@koopajs/mui';
import { useResourceCreate, useComponentVisibility, useResourceShow } from '@koopajs/react';
import { Typography, Button, TextField as MUITextField, Box, Stack, Divider } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { TrackEvent } from '@koopajs/app';
import { Alert } from '@mui/material';
import { TextField } from 'components/temp/TextFieldTemp';
import { TextMultiLine } from 'components/temp/TextMultiLineTemp';
import { ICommittee, IMeeting, ITopic } from 'types';
import { ParticipantsList } from 'components/ParticipantsList';
import { DialogFormTemp } from 'components/temp/DialogFormTemp';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useAppSnackbar } from 'components/hooks/useAppSnackbar';
import { getSignatureEmailRecipients } from 'utils/getSignatureEmailRecipients';

export const SendResolutionExtract: React.FC = () => {
  const { t } = useLocale();

  const keyPrefix = 'Dialogs.DialogSendResolutionExtract';
  const { triggerSnackbar } = useAppSnackbar();

  const [confirmDeleteFieldText, setConfirmDeleteFieldText] = useState('');

  const sendResolutionDialog = useComponentVisibility('sendResolutionExtract');
  const resolutionExtractPreviewDialog = useComponentVisibility('resolutionPreviewDialog');

  const {
    topic: resolution,
    isCustomResNum,
    meeting
  } = sendResolutionDialog.getContext() as {
    topic: ITopic;
    isCustomResNum?: boolean;
    meeting?: IMeeting;
  };

  const { resource: committee } = useResourceShow<ICommittee>({
    path: '/committees',
    id: meeting?.committeeId
  });

  const handleShowResolutionExtractPreview = (): void => {
    resolutionExtractPreviewDialog.setVisibleWithContext({
      resolution,
      meeting
    });
  };

  const signeeIds = useMemo(() => {
    if (meeting && committee) {
      return getSignatureEmailRecipients({ meeting, committee, models: ['resolutions'] });
    }
  }, [JSON.stringify(meeting?.activeCommitteeMembers), JSON.stringify(committee?.operationsRequired)]);

  const hasSignees = signeeIds && signeeIds.length > 0;

  const { createResource } = useResourceCreate({
    path: `/meetings/${meeting?.id}/topics/${resolution?.id}/extract-resolution`,
    customReducer: {
      path: {
        resourceType: `/meetings`,
        resourceId: meeting?.id || ''
      },
      mapping: (meeting: object, editedTopic: object) => {
        const oldMeeting = meeting as IMeeting;
        const newEditedTopic = editedTopic as ITopic;
        return {
          ...oldMeeting,
          topics: oldMeeting.topics?.map((t: ITopic) => {
            if (t.id === newEditedTopic.id) {
              return newEditedTopic;
            } else {
              return t;
            }
          })
        };
      }
    }
  });

  const isCustomResNumMissing = isCustomResNum && !resolution?.resolutionNumber;

  const handleSubmit = useCallback(
    async (data: object): Promise<boolean> => {
      const formData = data as { message?: string };

      if (!formData.message) {
        formData.message = undefined;
      }
      const res = await createResource(data);

      if (res) {
        triggerSnackbar({
          snackbarText: t(keyPrefix + '.snackbarSendResolutionSuccess'),
          variant: 'success'
        });

        TrackEvent('resolution-extract-sent');
      } else {
        triggerSnackbar({
          snackbarText: t(keyPrefix + '.snackbarSendResolutionFail'),
          variant: 'error'
        });

        TrackEvent('resolution-extract-send-fail');
      }

      return res;
    },
    [resolution?.id, meeting?.id]
  );

  const meetingReminderOptions = [0.25, 1, 3, 7].map((option) => {
    let label = 'optionLabelDays';
    let count = option;
    if (option > 0 && option < 1) {
      label = 'optionLabelHours';
      count = option * 24;
    }
    return {
      id: `${option}`,
      label: t(`${keyPrefix}.Form.RadioFields.${label}`, { count })
    };
  });

  const handleOnClose = (): void => {
    setConfirmDeleteFieldText('');
  };

  return (
    <DialogFormTemp
      dialogKey="sendResolutionExtract"
      onSubmit={handleSubmit}
      i18n={{
        keyPrefix: `${keyPrefix}.DialogFormSend`
      }}
      maxWidth="md"
      leftAction={<Button onClick={sendResolutionDialog.setHidden}>{t('common:labelCancel')}</Button>}
      secondaryAction={
        <Button onClick={handleShowResolutionExtractPreview}>{t(keyPrefix + '.previewExtractLabel')}</Button>
      }
      dialogProps={{ className: 'rr-block', PaperProps: { 'data-cy': 'send-resolution-extract-dialog' } }}
      mainActionBtnVariant="contained"
      mainActionBtnSx={{
        transition: 'background-color 0.3s ease-in-out',
        backgroundColor: 'error.main',
        color: 'error.contrastText',

        '&:hover': {
          backgroundColor: 'error.dark'
        }
      }}
      mainActionButtonLabel={
        hasSignees ? undefined : t(keyPrefix + '.DialogFormSend.ButtonSubmit.labelExtract')
      }
      isSubmitDisabled={confirmDeleteFieldText !== t(keyPrefix + '.confirmationText')}
      onClose={handleOnClose}
    >
      {isCustomResNumMissing && (
        <Alert
          severity="warning"
          variant="standard"
          sx={{
            border: '1px solid',
            borderColor: 'warning.main',
            '& .MuiAlert-icon': { color: 'warning.main' }
          }}
        >
          {t(keyPrefix + '.Form.customResNumberMissingAlert')}
        </Alert>
      )}

      {hasSignees && (
        <>
          <Typography sx={{ my: 2 }}>{t(keyPrefix + '.Form.signatoriesLabel')}</Typography>
          <ParticipantsList participants={signeeIds.map((userId) => ({ userId }))} chipVariant="outlined" />
          <TextField
            name="subject"
            validationRules={{ maxLength: 256 }}
            i18n={{ keyPrefix: keyPrefix + '.Form.FieldSubject' }}
          />
          <TextMultiLine
            rows={4}
            name="message"
            validationRules={{ maxLength: 1000 }}
            isOptional={true}
            i18n={{ keyPrefix: keyPrefix + '.Form.FieldMessage' }}
          />
          <Form.Radio
            name="reminder"
            i18n={{ keyPrefix: keyPrefix + '.Form.RadioFields' }}
            options={meetingReminderOptions}
            row
            sx={{ mt: 1, mb: 0 }}
            isOptional={true}
          />

          <Divider sx={{ mb: '14px', mt: 0.5 }} />
        </>
      )}

      <Stack flexDirection="row" sx={{ ...(!hasSignees ? { mt: 1 } : {}) }}>
        <ErrorOutlineIcon sx={{ mr: 1, color: 'customGrey.darker' }} />
        <Box component="span">{t(`${keyPrefix}.Form.alert`)}</Box>
      </Stack>

      <MUITextField
        label={t(keyPrefix + '.ConfirmField.label')}
        value={confirmDeleteFieldText}
        helperText={t(keyPrefix + '.ConfirmField.helperText')}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setConfirmDeleteFieldText(event.target.value);
        }}
        sx={{
          width: '100%',
          mt: '14px'
        }}
        data-cy="confirm-send-extract-field"
      />
    </DialogFormTemp>
  );
};
