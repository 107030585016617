import { IUseResourceListResponse, useLocale, usePermissions } from '@koopajs/react';
import { ICommittee } from 'types';
import { CommitteeCard } from 'components/Organization/OrganizationCommittees/CommitteeCard';
import { Typography, Box, Button } from '@mui/material';
import { Loading } from '@koopajs/mui';
import AddIcon from '@mui/icons-material/Add';

interface IOrganizationCommitteesProps {
  committeesUseResourceList: IUseResourceListResponse<ICommittee>;
}

export const OrganizationCommittees: React.FC<IOrganizationCommitteesProps> = (props) => {
  const { t } = useLocale();
  const keyPrefix = 'Organization.OrganizationCommittees';

  const { committeesUseResourceList } = props;

  const { resources: committees, state } = committeesUseResourceList;

  const alphabeticalCommitteeSortFunction = (a: ICommittee, b: ICommittee): number => {
    const committeeA: string = a.name || '';
    const committeeB: string = b.name || '';
    return committeeA.localeCompare(committeeB, 'en', { sensitivity: 'base' });
  };

  const isUserWorkspaceOwner = usePermissions({ requiredPermissions: 'owner' });

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '24px' }}>
        <Typography variant="h2" sx={{ fontWeight: 500, fontSize: '20px' }}>
          {t(keyPrefix + '.title')}
        </Typography>
        {isUserWorkspaceOwner && (
          <Button
            data-cy="organization_add-committee-button"
            variant="outlined"
            startIcon={<AddIcon />}
            target="_blank"
            rel="noopener"
            component="a"
            href="https://usepanorama.atlassian.net/servicedesk/customer/portal/1/group/1/create/13"
          >
            {t(keyPrefix + '.addGroupButtonLabel')}
          </Button>
        )}
      </Box>

      <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
        {state === 'loading' || state === 'reloading' ? (
          <Loading sx={{ backgroundColor: 'transparent', position: 'absolute' }} />
        ) : (
          <>
            {committees.sort(alphabeticalCommitteeSortFunction).map((c) => {
              return <CommitteeCard committee={c} key={c.id} />;
            })}
          </>
        )}
      </Box>
    </>
  );
};
