import React from 'react';
import { CreateFeedback } from './CreateFeedback';
import { Dialog } from '@koopajs/mui';
import { RecordVote } from './RecordVote';
import { AddResolution } from './AddResolution';
import { SendMeeting } from './SendMeeting';
import { AddLinkDialog } from './AddLinkDialog';
import { SendWrittenResolution } from './SendWrittenResolution';
import { AddDocumentRecordDialog } from './AddDocumentRecordDialog';
import { AddDocumentToDocumentRecordDialog } from './AddDocumentToDocumentRecordDialog';
import { AddDocumentToHistoryDialog } from './AddDocumentToHistoryDialog';
import { BoardMemberViewDocumentDialog } from './BoardMemberViewDocumentDialog';
import { ViewDocumentPdf } from './ViewDocumentPdf';
import { SendResolutionExtract } from './SendResolutionExtract';
import { ViewMinutesVisibilityDialog } from './Minutes/ViewMinutesVisibilityDialog';
import { ViewPastMeetingVisibilityDialog } from './PastMeeting/ViewPastMeetingVisibilityDialog';
import { ViewResolutionVisibilityDialog } from './Resolution/ViewResolutionVisibilityDialog';
import { ViewResolutionPreview } from './ViewResolutionPreview';
import { SealMinutesDialog } from './SealMinutesDialog';
import { ViewSealedMinutesPreview } from './ViewSealedMinutesPreview';

export const Dialogs: React.FC = () => (
  <>
    <CreateFeedback />
    <Dialog.InviteUser
      options={[
        // { id: 'admin', key: 'admin' },
        { id: 'member', key: 'member' }
      ]}
      i18n={{ keyPrefix: 'Dialogs.InviteUser' }}
    />
    {/* <Dialog.SelectPlan /> */}
    {/* // TODO: Fix default koopa translations */}
    <Dialog.CreateWorkspace i18n={{ keyPrefix: 'koopaCore:DialogCreateWorkspace' }} />
    <RecordVote />
    <AddResolution />
    <SendMeeting />
    <AddLinkDialog />
    <SendWrittenResolution />
    <AddDocumentRecordDialog />
    <AddDocumentToDocumentRecordDialog />
    <AddDocumentToHistoryDialog />
    <BoardMemberViewDocumentDialog />
    <ViewDocumentPdf />
    <SendResolutionExtract />
    <ViewMinutesVisibilityDialog />
    <ViewPastMeetingVisibilityDialog />
    <ViewResolutionPreview />
    <ViewResolutionVisibilityDialog />
    <ViewMinutesVisibilityDialog />
    <ViewPastMeetingVisibilityDialog />
    <SealMinutesDialog />
    <ViewSealedMinutesPreview />
  </>
);
