import { IMeeting, ITopic } from 'types';
import { useLocale, useWorkspaceShow } from '@koopajs/react';
import { Box, Divider, Typography } from '@mui/material';
import React, { forwardRef } from 'react';
import { MeetingHeaderCardPrint } from './MeetingHeaderCardPrint';
import { TimerOutlined as TimerOutlinedIcon } from '@mui/icons-material';
import { TopicCardMeetingPrint } from 'components/TopicCard/TopicCardMeetingPrint';
import { calculateTopicsStartTime } from 'utils/calculateTopicsStartTime';

interface IMeetingPrintVersion {
  meeting?: IMeeting;
  ref: React.ForwardedRef<unknown>;
  showApprovedPastMeetingsToApprove?: boolean;
  topics?: ITopic[];
  meetingDuration: string;
}

export const MeetingPrintVersion: React.FC<IMeetingPrintVersion> = forwardRef((props, ref) => {
  const { meeting, showApprovedPastMeetingsToApprove, meetingDuration } = props;

  const { workspace } = useWorkspaceShow();

  const { t } = useLocale();

  const keyPrefix = 'MeetingEdit';

  const topicsWithStartTime = meeting && calculateTopicsStartTime(meeting);

  if (!meeting) return null;

  return (
    <Box
      className="printWrapper rr-block"
      ref={ref}
      sx={{
        display: 'block',
        '@media print': {
          '@page': {
            margin: '1in'
          },
          '@page:first': {
            marginTop: '0.7in'
          }
        }
      }}
    >
      {/* LOGO */}
      {workspace?.icon && (
        <Box>
          <img src={workspace.icon} alt="Logo" style={{ maxHeight: '50px', maxWidth: '200px' }} />
        </Box>
      )}

      <MeetingHeaderCardPrint meeting={meeting} />

      <Divider sx={{ my: '14px' }} />

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
        <Typography variant="overline">{t(keyPrefix + '.titleAgenda')}</Typography>

        {topicsWithStartTime
          ?.filter((t) => !t.deletedAt)
          .map((topic, index, array) => {
            return (
              <Box key={topic.id}>
                <TopicCardMeetingPrint
                  topic={topic}
                  order={index + 1}
                  meeting={meeting}
                  showApprovedPastMeetingsToApprove={showApprovedPastMeetingsToApprove ? true : false}
                />
              </Box>
            );
          })}
      </Box>

      <Box
        sx={{
          '@media print': {
            breakInside: 'avoid',
            breakBefore: 'avoid'
          }
        }}
      >
        <Divider
          sx={{
            my: '14px'
          }}
        />

        {/* DURATION */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Typography variant="body1" sx={{ display: 'flex' }}>
            <TimerOutlinedIcon color="action" sx={{ mr: 1 }} />
            {t(keyPrefix + '.totalMeetingTime')}: {meetingDuration}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
});
