import { useCallback, useEffect, useState } from 'react';
import {
  useLocale,
  useParamsKey,
  useResourceList,
  useResourceShow,
  useResourceUpdate,
  useUserShow
} from '@koopajs/react';
import { IDocument, IMeeting, ITopic } from 'types';
import { useHistory } from 'react-router-dom';
import { Api, IUserPublicProfile } from '@koopajs/app';
import { TFunction } from 'react-i18next';
import { alphabeticalUserSortFunction } from 'utils/alphabeticalUserSortFunction';
import { useQueryParams, StringParam } from 'use-query-params';

export interface IUseLiveMeetingProps {
  meeting: IMeeting;
}

export interface IUseLiveMeetingResponse {
  // Helpers
  t: TFunction;

  locale: string;

  // States
  isProcessing: boolean;
  errorMessage?: string;
  meetingId: string;
  currentTopicId: string;
  previousTopicId?: string;
  nextTopicId?: string;
  currentTopicIndex: number;
  isCommitteeTakingResolutions?: boolean;

  // Models
  meeting: IMeeting;
  topic?: ITopic;
  users: IUserPublicProfile[];
  user?: IUserPublicProfile;
  agendaDocuments: IDocument[];
  minutesDocuments: IDocument[];
  participants: IUserPublicProfile[];
  presentParticipants: IUserPublicProfile[];

  // Handlers
  handleChangeCurrentTopic: (topicId: string) => void;
  handleMeetingPermanentlyFinished: () => Promise<void>;
  handleMeetingTemporaryExit: () => Promise<void>;
  handleMeetingUpdate: (formData: Record<string, unknown>) => Promise<boolean>;
  handleTopicUpdate: (formData: Record<string, unknown>) => Promise<boolean>;
  // handleUpdateFormButtonsContainerWidth: (width: number) => void;
}

export const useLiveMeeting = (props: IUseLiveMeetingProps): IUseLiveMeetingResponse => {
  const { meeting } = props;

  /**
   * Helpers
   */
  const _history = useHistory();
  const { t, locale } = useLocale();
  const [nextTopicId, setNextTopicId] = useState<string | undefined>();
  const [previousTopicId, setPreviousTopicId] = useState<string | undefined>();

  /**
   * States & Models
   */
  const meetingId = useParamsKey('meetingId');
  const currentTopicId = useParamsKey('topicId');
  const { user } = useUserShow();
  const { resources: users } = useResourceList<IUserPublicProfile>({
    path: '/users',
    searchParams: { size: 50 }
  });
  const { updateResource: handleMeetingUpdate, ...meetingState } = useResourceUpdate({
    path: '/meetings',
    id: meetingId
  });
  const { updateResource: handleMeetingEnd } = useResourceUpdate({
    path: `/meetings/${meeting.id}/end`,
    id: '',
    customReducer: {
      path: {
        resourceType: '/meetings',
        resourceId: meeting.id
      },
      mapping: (prevObj: unknown, newObj: unknown) => {
        return newObj as IMeeting;
      }
    }
  });
  const _topicsIds = meeting?.topics?.map((topic) => topic.id) || [];
  const topic: ITopic | undefined = meeting?.topics?.find((topic) => topic.id === currentTopicId);
  const { updateResource: updateTopic, ...topicState } = useResourceUpdate({
    path: `/meetings/${meetingId}/topics`,
    id: currentTopicId,
    customReducer: {
      path: { resourceType: '/meetings', resourceId: meetingId },
      mapping: (prevObj: unknown, newObj: unknown) => {
        const meeting = prevObj as IMeeting;
        const editedTopic = newObj as ITopic;

        const updatedMeeting: IMeeting = {
          ...meeting,
          topics: meeting?.topics?.map((t: ITopic) => {
            if (t.id === editedTopic.id) {
              return editedTopic;
            } else {
              return t;
            }
          })
        };

        if (editedTopic.quorumParticipants?.length === updatedMeeting.participants?.length) {
          updatedMeeting.participants = editedTopic.quorumParticipants;
        }

        if (editedTopic.meetingPresidentId) {
          updatedMeeting.meetingPresidentId = editedTopic.meetingPresidentId;
        }
        if (editedTopic.meetingSecretaryId) {
          updatedMeeting.meetingSecretaryId = editedTopic.meetingSecretaryId;
        }

        return updatedMeeting;
      }
    }
  });

  const { resources: agendaDocuments, ...agendaDocumentsState } = useResourceList<IDocument>({
    path: `/meetings/${meetingId}/topics/${currentTopicId}/documents`
  });
  const { resources: minutesDocuments, ...minutesDocumentsState } = useResourceList<IDocument>({
    path: `/meetings/${meetingId}/topics/${currentTopicId}/minutes-documents`
  });
  const { resource: committee } = useResourceShow({ path: '/committees', id: meeting.committeeId });

  /**
   * Find previous and next topic ID
   */
  const currentTopicIndex = _topicsIds?.indexOf(currentTopicId) || 0;
  useEffect(() => {
    if (currentTopicIndex !== _topicsIds.length - 1) {
      setNextTopicId(_topicsIds[currentTopicIndex + 1]);
    } else {
      setNextTopicId(undefined);
    }

    if (currentTopicIndex !== 0) {
      setPreviousTopicId(_topicsIds[currentTopicIndex - 1]);
    } else {
      setPreviousTopicId(undefined);
    }
  }, [currentTopicIndex, currentTopicId, JSON.stringify(_topicsIds)]);

  /**
   * Users and participant management
   */

  const isUserParticipantToMeeting = (u: IUserPublicProfile): boolean => {
    return Boolean(meeting?.participants?.find((p) => p.userId === u.id));
  };

  const isUserPresentToMeeting = (u: IUserPublicProfile): boolean => {
    return Boolean(meeting?.participants?.find((p) => p.userId === u.id && p.isPresent === true));
  };

  const participants = users.filter(isUserParticipantToMeeting).sort(alphabeticalUserSortFunction);
  const presentParticipants = participants.filter(isUserPresentToMeeting);

  /**
   * Handlers
   */
  const handleChangeCurrentTopic = useCallback(
    async (topicId: string): Promise<void> => {
      _history.push(`/meetings/${meetingId}/topics/${topicId}`);
      await Api.client.put(`/meetings/${meetingId}/topics/${topicId}/start`);
    },
    [meetingId]
  );

  const [searchParams, setSearchParams] = useQueryParams({ redirecting: StringParam });

  const handleMeetingPermanentlyFinished = useCallback(async (): Promise<void> => {
    await Api.client.put(`/meetings/${meetingId}/topics/${currentTopicId}/end`);

    await handleMeetingEnd({});

    setSearchParams({ redirecting: 'review' });

    await new Promise((resolve) => setTimeout(resolve, 1000));

    _history.push('/tasks/to-review');
  }, [handleMeetingUpdate, meetingId, currentTopicId, meeting.minutesEndedAt, user?.id]);

  const handleMeetingTemporaryExit = useCallback(async (): Promise<void> => {
    await Api.client.put(`/meetings/${meetingId}/topics/${currentTopicId}/end`);
    if (topic?.type === 'quorum') {
      const isAnyonePresent = topic.quorumParticipants?.some((p) => p.isPresent);
      if (!topic.quorumReachedAt && !isAnyonePresent && !topic.notes) {
        await handleMeetingUpdate({
          currentNoteTakerId: '',
          minutesStartedAtOriginal: '',
          minutesStartedBy: ''
        });
        return _history.push('/');
      }
    }
    await handleMeetingUpdate({ currentNoteTakerId: '' });
    _history.push('/');
  }, [handleMeetingUpdate, meetingId, currentTopicId, JSON.stringify(topic)]);

  const handleTopicUpdate = useCallback(
    async (data: object) => {
      const formData = data as {
        quorumParticipants?: { [k: string]: boolean } | { userId: string; isPresent?: boolean }[];
        quorumReachedAt?: boolean | string;
        notes?: string;
        visibleBy?: { [k: string]: boolean } | string[];
      };

      const quorumParticipantsReformat: {
        userId: string;
        isPresent: boolean;
      }[] = [];
      for (const [userId, isPresent] of Object.entries(formData.quorumParticipants || {})) {
        quorumParticipantsReformat.push({ userId, isPresent });
      }

      formData.quorumParticipants =
        quorumParticipantsReformat.length > 0 ? quorumParticipantsReformat : undefined;

      let visibleByArray: string[] | undefined = [];
      if (formData.visibleBy) {
        for (const [id, value] of Object.entries(formData.visibleBy)) {
          if (value) visibleByArray.push(id);
        }
      } else {
        visibleByArray = undefined;
      }

      formData.visibleBy = visibleByArray;

      return await updateTopic(formData);
    },
    [updateTopic]
  );

  return {
    t,
    locale,
    meeting,
    meetingId,
    currentTopicId,
    topic,
    users: users.sort(alphabeticalUserSortFunction),
    user,
    agendaDocuments,
    minutesDocuments,
    nextTopicId,
    previousTopicId,
    currentTopicIndex,
    participants,
    presentParticipants,
    handleChangeCurrentTopic,
    handleMeetingPermanentlyFinished,
    handleMeetingTemporaryExit,
    handleMeetingUpdate,
    handleTopicUpdate,
    isProcessing:
      meetingState.isProcessing ||
      topicState.isProcessing ||
      agendaDocumentsState.isProcessing ||
      minutesDocumentsState.isProcessing,
    errorMessage: meetingState.errorMessage || topicState.errorMessage,
    isCommitteeTakingResolutions: Boolean(committee?.isTakingResolutions)
  };
};
