import base from './base.json';

import components from './components.json';
import dialogs from './dialogs.json';
import hooks from './hooks.json';
import modals from './modals.json';
import sidebars from './sidebars.json';

import header from './header.json';

import onboarding from './onboarding.json';
import dashboard from './dashboard.json';
import minutes from './minutes.json';
import resolutions from './resolutions.json';
import inCamera from './inCamera.json';
import meetingEdit from './meetingEdit.json';
import meetingStatesInProgress from './meetingStatesInProgress.json';
import meetingStatesReview from './meetingStatesReview.json';
import resolutionStatesBase from './resolutionStatesBase.json';
import documentRecords from './documentRecords.json';
import documentRecord from './documentRecord.json';
import documentView from './documentView.json';
import account from './account.json';
import settings from './settings.json';
import userProfile from './userProfile.json';
import organization from './organization.json';
import organizationMember from './organizationMember.json';
import organizationCommittee from './organizationCommittee.json';
import notFound from './notFound.json';
import writtenResolution from './writtenResolution.json';
import pastMeetings from './pastMeetings.json';
import uploadedMeetings from './uploadedMeetings.json';
import documentAttachedToDocumentRecord from './documentAttachedToDocumentRecord.json';
import meetingView from './meetingView.json';
import uploadedMinutes from './uploadedMinutes.json';
import boardMemberView from './boardMemberView.json';
import uploadedResolutions from './uploadedResolutions.json';

import toReview from './toReview.json';
import toSign from './toSign.json';
import upcomingMeetings from './upcomingMeetings.json';

export default {
  ...base,
  ...components,
  ...dialogs,
  ...hooks,
  ...modals,
  ...sidebars,
  // core
  ...header,
  // pages
  ...onboarding,
  ...dashboard,
  ...toSign,
  ...minutes,
  ...resolutions,
  ...inCamera,
  ...meetingEdit,
  ...meetingStatesInProgress,
  ...meetingStatesReview,
  ...resolutionStatesBase,
  ...documentRecords,
  ...documentRecord,
  ...documentView,
  ...account,
  ...settings,
  ...userProfile,
  ...organization,
  ...organizationMember,
  ...organizationCommittee,
  ...notFound,
  ...writtenResolution,
  ...pastMeetings,
  ...uploadedMeetings,
  ...documentAttachedToDocumentRecord,
  ...meetingView,
  ...uploadedMinutes,
  ...boardMemberView,
  ...uploadedResolutions,
  ...toReview,
  ...toSign,
  ...upcomingMeetings
};
