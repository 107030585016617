import { IMinute } from 'types';
import { Stack, Typography, Box, Button, Paper } from '@mui/material';
import { useLocale } from '@koopajs/react';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { DocumentList } from '../DocumentList';
import NotesIcon from '@mui/icons-material/Notes';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { DateTimeStartEnd } from 'components/DateTimeStartEnd';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import React, { useCallback } from 'react';
import { useScreenSize } from 'utils/useScreenSize';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { RenderButtonLink } from 'components/RenderButtonLink';

interface IMinutesCardProps {
  minutes: IMinute;
}

// Minutes book cards

export const MinutesCard: React.FC<IMinutesCardProps> = (props) => {
  const { minutes } = props;

  const minutesType = useCommitteeName(minutes?.committeeId || '');

  const { t } = useLocale();

  const hasResolutions = Boolean(minutes?.resolutionsCount && minutes?.resolutionsCount > 0);
  const { isMobileScreen } = useScreenSize();

  const history = useHistory();

  const renderMinuteActionButton = useCallback(() => {
    return (
      <Button
        component={RouterLink}
        to={{
          pathname: `/minutes/${minutes.id}`,
          search: history.location?.search
        }}
        data-cy="minutes-card_view-minutes-button"
      >
        {t('common:view')}
      </Button>
    );
  }, [minutes.id, minutesType, history.location]);

  return (
    <Paper
      variant="outlined"
      sx={{
        padding: '24px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        my: 2
      }}
      data-cy="minutes-card"
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, gap: '14px' }}>
        {/* Title & Button */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'baseline'
          }}
        >
          <Typography
            sx={{ fontSize: '16px', minWidth: 0, display: 'flex', flexDirection: 'column', gap: '14px' }}
            variant="h3"
          >
            <Box sx={{ display: { xs: 'flex', sm: 'block' }, flexDirection: 'column' }}>
              <DateTimeStartEnd
                isoStringDate={minutes.startDateTime}
                dateFormat="DATE_HUGE"
                separator={isMobileScreen ? undefined : ' | '}
                isoStringStart={minutes.startDateTime}
                isoStringEnd={minutes.endDateTime}
              />
            </Box>
            <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{minutes?.title}</Box>
          </Typography>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>{renderMinuteActionButton()}</Box>
        </Box>
        {/* Details */}
        <Box sx={{ display: 'flex', alignItems: 'flex-end', flexWrap: 'wrap' }}>
          {/* Meeting type */}
          {minutesType && (
            <Typography variant="body2" sx={{ opacity: '0.6' }}>
              {minutesType}
              <span style={{ marginRight: '10px', marginLeft: '10px' }}>&#183;</span>
            </Typography>
          )}

          {/* Location */}
          <Typography variant="body2" sx={{ opacity: '0.6' }}>
            {minutes.location?.type
              ? t(`common:locationType.${minutes.location.type}`)
              : t('common:noLocation')}{' '}
            <span
              style={{
                marginRight: '10px',
                marginLeft: '10px',
                display: isMobileScreen ? 'none' : 'inline'
              }}
            >
              &#183;
            </span>
          </Typography>

          {/* Participants */}
          <Stack direction="row" sx={{ opacity: '0.6', flexBasis: { xs: '100%', sm: '33%' } }}>
            <Stack direction="row" alignItems="flex-end" spacing={1}>
              <PersonIcon />
              <Typography variant="body2">
                {t('common:numberPresent', {
                  count: minutes.participants?.filter((participant) => participant.isPresent).length || 0
                })}
              </Typography>
            </Stack>
            <span style={{ marginRight: '10px', marginLeft: '10px' }} />
            {minutes.absentParticipantsNumber > 0 && (
              <Stack direction="row" alignItems="flex-end" spacing={1}>
                <PersonOutlineOutlinedIcon />
                <Typography variant="body2">
                  {minutes.absentParticipantsNumber}{' '}
                  {t('common:absent', {
                    count: minutes.absentParticipantsNumber
                  })}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Box>
        {minutes.location?.address && (
          <Box sx={{ whiteSpace: { md: 'pre-wrap' }, display: 'flex', alignItems: 'end' }}>
            <LocationOnIcon fontSize="small" sx={{ mt: '2px', mr: 0.5, opacity: '0.6' }} />
            <Typography variant="body2" sx={{ opacity: '0.6' }} noWrap={true} width="350px">
              {minutes.location?.address}
            </Typography>
          </Box>
        )}

        {/* resolution and meeting buttons */}
        {(hasResolutions || minutes.meetingId) && (
          <Stack direction="row" gap="14px">
            {minutes.meetingId && (
              <RenderButtonLink
                to={{
                  pathname: `/minutes/past-meetings/${minutes.meetingId}`,
                  search: history.location?.search
                }}
                icon={<CalendarTodayIcon />}
                label={
                  <Typography component="span" variant="body2">
                    {t('common:meeting')}
                  </Typography>
                }
                variant="text"
              />
            )}
            {hasResolutions && (
              <RenderButtonLink
                to={`/resolutions?filters=${encodeURI(`attachedToMeetingTopic.minuteId:${minutes.id}`)}`}
                icon={<NotesIcon />}
                label={
                  <Typography component="span" variant="body2">
                    {minutes.resolutionsCount +
                      ' ' +
                      t('common:resolution', { count: minutes.resolutionsCount })}
                  </Typography>
                }
                variant="text"
              />
            )}
          </Stack>
        )}
        {/* Documents */}
        <DocumentList
          path={`/minutes/${minutes.id}/documents`}
          isAccordion={true}
          accordionOptions={{ documentsVisible: 0 }}
          showDivider
        />
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>{renderMinuteActionButton()}</Box>
    </Paper>
  );
};
