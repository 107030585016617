import { IMeeting, ITopic } from 'types';
import { Box, Typography, Paper, Divider } from '@mui/material';
import { Dialog } from '@koopajs/mui';
import { ResolutionOutcomeText } from 'components/ResolutionOutcome/ResolutionOutcomeText';
import { DocumentList } from '../DocumentList';
import { ResolutionOutcomeVotes } from 'components/ResolutionOutcome/ResolutionOutcomeVotes';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';
import { useLocale } from '@koopajs/react';
import { DateTimeStartEnd } from 'components/DateTimeStartEnd';
import { TimeStartEnd } from 'components/TimeStartEnd';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { useComponentVisibility } from '@koopajs/react';
import { useCallback } from 'react';
import { PanoramaDocumentWrapper } from '../PanoramaDocumentWrapper';

export const ViewResolutionPreview: React.FC = () => {
  const dialog = useComponentVisibility('resolutionPreviewDialog');
  const keyPrefix = 'Resolutions.DialogView';
  const { t } = useLocale();

  const { resolution, meeting } = dialog.getContext() as { resolution: ITopic; meeting: IMeeting };

  const { membersAgainst, membersAbstained, membersFor } = resolution?.resolutionOutcome || {};
  const showResolutionOutcomeVotes = membersFor?.length || membersAbstained?.length || membersAgainst?.length;

  const minutesType = useCommitteeName(meeting?.committeeId || '');

  const handleCloseDialog = useCallback(() => {
    dialog.setHidden();
  }, []);

  return (
    <Dialog.View
      isOpen={dialog.isVisible}
      onClose={handleCloseDialog}
      isCloseVisible={true}
      i18n={{ keyPrefix: keyPrefix }}
      dialogProps={{ className: 'rr-block', PaperProps: { 'data-cy': 'view-resolution-preview-dialog' } }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', my: 3 }}>
        <PanoramaDocumentWrapper>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Typography
              variant="overline"
              data-cy="view-resolution-dialog_resolution-number"
              sx={{ overflow: 'hidden', textOverflow: 'ellipsis', minWidth: '0px', whiteSpace: 'nowrap' }}
            >
              {t('Resolutions.resolutionExtract')}{' '}
              {resolution?.resolutionNumber && `#${resolution.resolutionNumber}`}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: { xs: 0, sm: 1 },
                mb: '4px',
                flexWrap: 'wrap'
              }}
            >
              <Typography variant="h6">
                <DateTimeStartEnd isoStringDate={meeting?.startDateTime} dateFormat="DATE_HUGE" />
              </Typography>{' '}
              <Typography variant="h6" sx={{ display: { xs: 'none', sm: 'inline-block' } }}>
                {' | '}
              </Typography>
              <Typography variant="h6"> {minutesType}</Typography>
            </Box>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: { xs: 0, sm: 1 },
                  flexWrap: 'wrap',
                  flexDirection: { xs: 'column', sm: 'row' }
                }}
              >
                <Typography
                  variant="body2"
                  data-cy="topic-minutes-detail-card_minutes-title"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    minWidth: '0px',
                    width: { xs: '100%', sm: 'auto' }
                  }}
                >
                  {meeting?.title}
                </Typography>
                <Typography variant="body2" sx={{ opacity: 0.6, display: { xs: 'none', sm: 'block' } }}>
                  &#183;
                </Typography>

                <Typography variant="body2" sx={{ opacity: 0.6 }}>
                  <TimeStartEnd isoStringStart={meeting?.startDateTime} isoStringEnd={meeting?.endDateTime} />
                </Typography>

                <Typography variant="body2" sx={{ opacity: 0.6, display: { xs: 'none', sm: 'block' } }}>
                  &#183;
                </Typography>

                <Typography variant="body2" sx={{ opacity: 0.6 }}>
                  {t(`common:locationType.${meeting?.location?.type}`)}
                </Typography>
              </Box>
              {meeting?.location?.address && (
                <Typography
                  variant="body2"
                  sx={{
                    opacity: 0.6,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    minWidth: '0px',
                    whiteSpace: 'nowrap',
                    width: { xs: '100%', sm: 'auto' }
                  }}
                >
                  {meeting?.location?.address}
                </Typography>
              )}
            </Box>
          </Box>
          <Divider />

          <Typography
            variant="body1"
            data-cy="view-resolution-dialog_resolution-title"
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis', minWidth: '0px' }}
          >
            {resolution?.title || t('common:topicTypes.' + resolution?.type)}
          </Typography>
          {resolution?.notes && (
            <>
              <Typography variant="body2" component="div">
                <RichTextReadOnly value={resolution.notes} />
              </Typography>
            </>
          )}

          <>
            <Typography variant="body1">
              <ResolutionOutcomeText topic={resolution} />
            </Typography>

            {showResolutionOutcomeVotes && (
              <Box>
                <ResolutionOutcomeVotes topic={resolution} hideIcon />
              </Box>
            )}
          </>

          {resolution?.minutesDocumentsIds && resolution?.minutesDocumentsIds?.length > 0 && (
            <Box>
              <Typography variant="caption" sx={{ mb: '4px' }}>
                {t('common:relatedDocumentationLabel')}
              </Typography>
              <DocumentList
                path={`/meetings/${resolution.meetingId}/topics/${resolution.id}/minutes-documents`}
              />
            </Box>
          )}
        </PanoramaDocumentWrapper>
      </Box>
    </Dialog.View>
  );
};
