import React from 'react';
import { useLocale } from '@koopajs/react';
import inCameraImageFR from '../../assets/emptyStates/in-camera_FR.png';
import inCameraImageEN from '../../assets/emptyStates/in-camera_EN.png';
import { EmptyStateTemplate } from './EmptyStateTemplate';
import { useCommitteePermissions } from 'data/permissions/useCommitteePermissions';

interface IEmptyStateProps {
  windowTitle?: string;
  button?: React.ReactNode;
}

export const EmptyStateInCamera: React.FC<IEmptyStateProps> = ({ windowTitle, button }) => {
  const { t, locale } = useLocale();
  const keyPrefix = 'InCamera.EmptyState';

  const { isUserMeetingCreator } = useCommitteePermissions();

  const tipKeys = [];
  if (isUserMeetingCreator) tipKeys.push('tipAddInAgenda');
  tipKeys.push('tipTraining');

  return (
    <EmptyStateTemplate
      windowTitle={windowTitle}
      pageTitle={t('InCamera.title')}
      emptyStateTitle={t(`${keyPrefix}.title`)}
      emptyStateDescription={t(`${keyPrefix}.description`)}
      image={locale.includes('en') ? inCameraImageEN : inCameraImageFR}
      tipKeys={tipKeys}
      button={button}
      keyPrefix={keyPrefix}
      t={t}
    />
  );
};
