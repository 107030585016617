import React, { useCallback } from 'react';
import { Dialog, ErrorMessage } from '@koopajs/mui';
import { Typography, Button, Container, Stack } from '@mui/material';
import { useLocale, User, useResourceDelete } from '@koopajs/react';
import { useConfirmDelete } from 'components/hooks/useConfirmDelete';
import { useAppSnackbar } from 'components/hooks/useAppSnackbar';

interface IDeleteUserPermission {
  userId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const DeleteUserPermission: React.FC<IDeleteUserPermission> = (props) => {
  const { userId = '', isOpen, onClose } = props;

  const { t } = useLocale();
  const keyPrefix = 'Modals.ModalDeleteUserPermission';

  const { triggerSnackbar } = useAppSnackbar();

  const {
    deleteResource: deleteUserPermission,
    errorMessage,
    isProcessing
  } = useResourceDelete({
    path: '/users',
    id: userId
  });

  const { hasUserTypedDeleteConfirmation, renderConfirmDeleteField, resetConfirmDeleteField } =
    useConfirmDelete({
      i18nKeyPrefix: keyPrefix + '.ConfirmDeleteField'
    });

  const handleCloseModal = useCallback(() => {
    onClose();
    resetConfirmDeleteField();
  }, []);

  const handleDeleteUserPermission = useCallback(async () => {
    const isSuccess = await deleteUserPermission();

    if (isSuccess) {
      handleCloseModal();

      triggerSnackbar({
        snackbarText: t(keyPrefix + '.snackbarDeleteSuccess'),
        variant: 'success'
      });
    } else {
      triggerSnackbar({
        snackbarText: t(keyPrefix + '.snackbarDeleteFail'),
        variant: 'error'
      });
    }
  }, [userId, deleteUserPermission]);

  return (
    <Dialog.View
      isOpen={isOpen}
      isLoading={isProcessing}
      onClose={handleCloseModal}
      forcedFullScreen={false}
      maxWidth="md"
    >
      <Container
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', gap: 3 }}
      >
        <Typography variant="h6">{t(keyPrefix + '.title')}</Typography>

        <ErrorMessage error={errorMessage} />

        <User id={userId}>
          {(profile) => {
            return (
              <Typography sx={{ whiteSpace: 'pre-line' }}>
                {t(keyPrefix + '.description', { username: profile?.username })}
              </Typography>
            );
          }}
        </User>

        {renderConfirmDeleteField()}

        <Stack gap={2} flexDirection="row" justifyContent="center" flexWrap="wrap">
          <Button sx={{ px: 7, mx: 1 }} variant="outlined" onClick={handleCloseModal}>
            {t('common:labelCancel')}
          </Button>
          <Button
            color="error"
            sx={{ px: 6.5, mx: 1 }}
            variant="contained"
            onClick={handleDeleteUserPermission}
            disabled={!hasUserTypedDeleteConfirmation}
          >
            {t(keyPrefix + '.labelDelete')}
          </Button>
        </Stack>
      </Container>
    </Dialog.View>
  );
};
