import React from 'react';
import { Box, Typography } from '@mui/material';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';

interface TipItemProps {
  tipKey: string;
  keyPrefix: string;
  t: (key: string) => string;
}

const TipItem: React.FC<TipItemProps> = ({ tipKey, keyPrefix, t }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 40,
        height: 40,
        borderRadius: '50%',
        backgroundColor: '#AFE8EE',
        flexShrink: 0
      }}
    >
      <PushPinOutlinedIcon sx={{ color: '#0B3C4F' }} />
    </Box>
    <Typography variant="body2">{t(`${keyPrefix}.${tipKey}`)}</Typography>
  </Box>
);

export default TipItem;
