import { uniq } from 'lodash';
import { IActiveCommitteeMembers, ICommittee, IMeeting } from 'types';

type Model = 'minutes' | 'resolutions';

interface SigneeRequirementGroups {
  required: string[];
  optional: string[];
}

// helper function to collect signers from meeting committee members
const collectUsersWithSigneePermissions = (
  activeCommitteeMembers: IActiveCommitteeMembers[]
): Record<Model, SigneeRequirementGroups> => {
  const usersWithSigneePermissionsByModel: Record<Model, SigneeRequirementGroups> = {
    minutes: { required: [], optional: [] },
    resolutions: { required: [], optional: [] }
  };

  for (const member of activeCommitteeMembers) {
    if (!member.roles) continue;

    for (const role of member.roles) {
      if (role.role === 'signMinutes') {
        const signerGroup = role.isOptional
          ? usersWithSigneePermissionsByModel.minutes.optional
          : usersWithSigneePermissionsByModel.minutes.required;
        signerGroup.push(member.userId);
      }
      if (role.role === 'signResolutions') {
        const signerGroup = role.isOptional
          ? usersWithSigneePermissionsByModel.resolutions.optional
          : usersWithSigneePermissionsByModel.resolutions.required;
        signerGroup.push(member.userId);
      }
    }
  }

  return usersWithSigneePermissionsByModel;
};

// helper function to add optional signers if needed based on signature requirements
const addOptionalSignersIfNeeded = (
  emailRecipients: string[],
  usersWithSigneePermissions: SigneeRequirementGroups,
  signaturesRequired: number
): void => {
  // calculate how many signatures are required
  const calculatedSignaturesRequired = Math.max(
    signaturesRequired,
    usersWithSigneePermissions.required.length
  );

  // total number of users who can sign
  const totalPotentialSigners =
    usersWithSigneePermissions.required.length + usersWithSigneePermissions.optional.length;

  // ensure we don't require more signatures than there are potential signers
  const adjustedSignaturesRequired = Math.min(calculatedSignaturesRequired, totalPotentialSigners);

  // if we don't have enough required signers, add optional signers
  if (adjustedSignaturesRequired > usersWithSigneePermissions.required.length) {
    emailRecipients.push(...usersWithSigneePermissions.optional);
  }
};

export const getSignatureEmailRecipients = (args: {
  meeting: IMeeting;
  committee: ICommittee;
  models: Model[];
}): string[] | undefined => {
  const { meeting, committee, models } = args;

  if (!meeting.activeCommitteeMembers || meeting.activeCommitteeMembers.length === 0 || !models) {
    return undefined;
  }

  const usersWithSigneePermissionsByModel: Record<Model, SigneeRequirementGroups> =
    collectUsersWithSigneePermissions(meeting.activeCommitteeMembers);

  const signaturesRequiredByModel = {
    minutes: committee.operationsRequired?.minuteSignatures?.amount || 0,
    resolutions: committee.operationsRequired?.resolutionSignatures?.amount || 0
  };

  // initialize with required signers (they can always sign)
  const emailRecipients = [
    ...(models.includes('minutes') ? usersWithSigneePermissionsByModel.minutes.required : []),
    ...(models.includes('resolutions') ? usersWithSigneePermissionsByModel.resolutions.required : [])
  ];

  // add optional signers if needed
  if (models.includes('minutes')) {
    addOptionalSignersIfNeeded(
      emailRecipients,
      usersWithSigneePermissionsByModel.minutes,
      signaturesRequiredByModel.minutes
    );
  }
  if (models.includes('resolutions')) {
    addOptionalSignersIfNeeded(
      emailRecipients,
      usersWithSigneePermissionsByModel.resolutions,
      signaturesRequiredByModel.resolutions
    );
  }

  return uniq(emailRecipients);
};
