import { IApprovedTopic, IMinute, IMeeting } from 'types';
import { Typography, Box } from '@mui/material';
import { useLocale, useResourceShow } from '@koopajs/react';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { DateTimeStartEnd } from '../DateTimeStartEnd';
import { TimeStartEnd } from '../TimeStartEnd';
import { UsersWithTitlesList } from '../UsersWithTitlesList';
import { getCommitteeMembersTitlesObj } from 'utils/getCommitteeMembersTitlesObj';

interface IInCameraMeetingHeaderCard {
  inCamera?: IApprovedTopic;
}

export const InCameraMeetingHeaderCard: React.FC<IInCameraMeetingHeaderCard> = (props) => {
  const { inCamera } = props;

  const { t } = useLocale();

  const isMinuteCreated = Boolean(inCamera?.minuteId);

  const committeeType = useCommitteeName(inCamera?.meetingCommitteeId as string);
  const keyPrefix = 'InCamera';

  const minutesId = isMinuteCreated ? inCamera?.minuteId : inCamera?.meetingId;
  const minutesPath = isMinuteCreated ? '/minutes' : '/meetings';

  const { resource: minutes } = useResourceShow<IMeeting | IMinute>({ path: minutesPath, id: minutesId });

  const committeeMembersTitlesObj = getCommitteeMembersTitlesObj(minutes?.activeCommitteeMembers);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography variant="overline">{t(keyPrefix + '.title')}</Typography>{' '}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 0, sm: 1 },
          mb: '4px',
          flexWrap: 'wrap'
        }}
      >
        <Typography variant="h6">
          <DateTimeStartEnd isoStringDate={inCamera?.meetingStartDateTime} dateFormat="DATE_HUGE" />
        </Typography>{' '}
        <Typography variant="h6" sx={{ display: { xs: 'none', sm: 'inline-block' } }}>
          {' | '}
        </Typography>
        <Typography variant="h6">{committeeType}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: { xs: 0, sm: 1 },
          flexWrap: 'wrap',
          flexDirection: { xs: 'column', sm: 'row' }
        }}
      >
        <Typography
          variant="body2"
          data-cy="approved-topic-header-card_meeting-title"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minWidth: '0px',
            width: { xs: '100%', sm: 'auto' }
          }}
        >
          {inCamera?.meetingTitle}
        </Typography>
        <Typography variant="body2" sx={{ opacity: 0.6, display: { xs: 'none', sm: 'block' } }}>
          &#183;
        </Typography>

        <Typography variant="body2" sx={{ opacity: 0.6 }}>
          <TimeStartEnd
            isoStringStart={inCamera?.meetingStartDateTime}
            isoStringEnd={inCamera?.meetingEndDateTime}
          />
        </Typography>

        <Typography variant="body2" sx={{ opacity: 0.6, display: { xs: 'none', sm: 'block' } }}>
          &#183;
        </Typography>

        <Typography variant="body2" sx={{ opacity: 0.6 }}>
          {t(`common:locationType.${inCamera?.meetingLocation?.type}`)}
        </Typography>
      </Box>
      {inCamera?.meetingLocation?.address && (
        <Typography
          variant="body2"
          sx={{
            opacity: 0.6,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minWidth: '0px',
            whiteSpace: 'nowrap',
            width: { xs: '100%', sm: 'auto' }
          }}
        >
          {inCamera?.meetingLocation?.address}
        </Typography>
      )}
      {inCamera?.visibleBy && inCamera.visibleBy?.length > 0 && (
        <Box sx={{ mt: '14px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="caption" color="text.secondary">
              {t(keyPrefix + '.authorizedPeople', { count: inCamera?.visibleBy?.length })}
            </Typography>
          </Box>
          <Box>
            <UsersWithTitlesList
              userIds={inCamera?.visibleBy.map((userId) => userId)}
              committeeMembersTitles={committeeMembersTitlesObj}
              showAvatar
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
