import React from 'react';
import { useLocale, usePermissions } from '@koopajs/react';
import minutesImageFR from '../../assets/emptyStates/minutes_FR.png';
import minutesImageEN from '../../assets/emptyStates/minutes_EN.png';
import { EmptyStateTemplate } from './EmptyStateTemplate';
import { useCommitteePermissions } from 'data/permissions/useCommitteePermissions';

interface IEmptyStateProps {
  windowTitle?: string;
  button?: React.ReactNode;
}

export const EmptyStateMinutes: React.FC<IEmptyStateProps> = ({ windowTitle, button }) => {
  const { t, locale } = useLocale();
  const keyPrefix = 'Minutes.EmptyState';

  const { isUserMeetingCreator } = useCommitteePermissions();
  const isUserWorkspaceOwner = usePermissions({ requiredPermissions: 'owner' });

  const tipKeys = [];
  if (isUserMeetingCreator) tipKeys.push('tipAdoptMinutes');
  if (isUserWorkspaceOwner) tipKeys.push('tipUpload');

  return (
    <EmptyStateTemplate
      windowTitle={windowTitle}
      pageTitle={t('Minutes.title')}
      emptyStateTitle={t(`${keyPrefix}.title`)}
      emptyStateDescription={t(`${keyPrefix}.description`)}
      image={locale.includes('en') ? minutesImageEN : minutesImageFR}
      tipKeys={tipKeys}
      button={button}
      keyPrefix={keyPrefix}
      t={t}
    />
  );
};
