import { useLocale } from '@koopajs/react';
import { User } from '@koopajs/react';
import { Box, Typography } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { DateTimeStartEnd } from 'components/DateTimeStartEnd';
import { SystemStyleObject } from '@mui/system';

interface IEventsTimeline {
  sx?: SystemStyleObject;
  timelineEvents: {
    title: string;
    timestamp?: string;
    userId?: string;
  }[];
}

export const EventsTimeline: React.FC<IEventsTimeline> = (props) => {
  const { timelineEvents, sx } = props;

  const { t } = useLocale();
  const keyPrefix = 'Components.EventsTimeline';

  const timelineEventsSorted = timelineEvents
    .filter((event) => event.timestamp)
    .sort((a, b) => {
      const dateA = a.timestamp ? new Date(a.timestamp).valueOf() : Infinity;
      const dateB = b.timestamp ? new Date(b.timestamp).valueOf() : Infinity;
      return dateB - dateA;
    });

  return (
    <Box sx={sx}>
      <Typography sx={{ mb: 1, fontSize: '16px' }} variant="h6">
        {t(keyPrefix + '.title')}
      </Typography>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0
          }
        }}
      >
        <>
          {timelineEventsSorted.map((event, index, array) => {
            const isLast = index === array.length - 1;

            const { title, timestamp, userId } = event;

            return (
              <TimelineItem key={JSON.stringify(event)}>
                <TimelineSeparator>
                  <TimelineDot variant="outlined" />
                  {!isLast && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <Typography>
                    {title}{' '}
                    <DateTimeStartEnd
                      isoStringDate={timestamp}
                      dateFormat="DATE_FULL"
                      isoStringStart={timestamp}
                    />{' '}
                    {userId && (
                      <>
                        {t('common:by')} <User id={userId}>{(profile) => <>{profile?.username}</>}</User>
                      </>
                    )}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </>
      </Timeline>
    </Box>
  );
};
