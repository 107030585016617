import { Dialog } from '@koopajs/mui';
import { useComponentVisibility, useLocale } from '@koopajs/react';
import { MinutesHeaderCard } from 'components/MinutesHeaderCard';
import { PanoramaDocumentWrapper } from 'components/PanoramaDocumentWrapper';
import { useCallback } from 'react';
import { IMeeting } from 'types';
import { Box, Divider } from '@mui/material';
import { TopicCardMinutes } from 'components/TopicCard/TopicCardMinutes';

export const ViewSealedMinutesPreview: React.FC = () => {
  const dialog = useComponentVisibility('minutesPreviewDialog');
  const keyPrefix = 'Dialogs.ViewSealedMinutesPreview';

  const { meeting } = dialog.getContext() as { meeting?: IMeeting };

  const notDeletedTopics = meeting?.topics?.filter((topic) => !topic.deletedAt);

  const handleCloseDialog = useCallback(() => {
    dialog.setHidden();
  }, []);

  return (
    <Dialog.View
      isOpen={dialog.isVisible}
      onClose={handleCloseDialog}
      isCloseVisible={true}
      i18n={{ keyPrefix: keyPrefix + '.DialogView' }}
      dialogProps={{ className: 'rr-block' }}
    >
      {meeting && (
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', my: 3 }}>
          <PanoramaDocumentWrapper>
            <MinutesHeaderCard minutes={meeting} />
            <Divider />

            {notDeletedTopics && (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {notDeletedTopics.map((topic, index, array) => {
                  return (
                    <Box key={topic.id}>
                      <TopicCardMinutes topic={topic} order={index + 1} minutes={meeting} />
                      {index !== array.length - 1 && <Divider sx={{ my: '14px' }} />}
                    </Box>
                  );
                })}
              </Box>
            )}
          </PanoramaDocumentWrapper>
        </Box>
      )}
    </Dialog.View>
  );
};
