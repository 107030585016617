import { BodyField } from './Fields/BodyField';
import { DocumentListInProgress } from './Fields/DocumentListInProgress';
import {
  Box,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Avatar
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IUseLiveMeetingResponse } from '../useLiveMeeting';
import { MeetingInProgressFormController } from './FormController';
import { Trans } from 'react-i18next';
import { AddResolutionButton } from '../Header/AddResolutionButton';
import { User } from '@koopajs/react';
import { TopicTitle } from '../Header/TopicTitle';
import { useState } from 'react';
import { AddDocument } from './Fields/AddDocument';

interface IInCameraFieldsProps {
  liveMeeting: IUseLiveMeetingResponse;
}

export const InCameraFields: React.FC<IInCameraFieldsProps> = (props) => {
  const { liveMeeting } = props;
  const { topic, t, user } = liveMeeting;
  const keyPrefix = 'MeetingStateInProgress.InCameraFields';

  const [isProcessingDocuments, setIsProcessingDocuments] = useState(false);

  const userHasAccessToThisInCameraTopic = topic?.visibleBy && user?.id && topic?.visibleBy.includes(user.id);

  return (
    <MeetingInProgressFormController liveMeeting={props.liveMeeting}>
      <TopicTitle
        liveMeeting={liveMeeting}
        addDocumentComponent={
          userHasAccessToThisInCameraTopic ? (
            <AddDocument
              liveMeeting={props.liveMeeting}
              isProcessing={isProcessingDocuments}
              setIsProcessing={setIsProcessingDocuments} //when adding a document, disable action for other documents (delete)
            />
          ) : undefined
        }
      />
      {userHasAccessToThisInCameraTopic ? (
        <>
          <Accordion
            elevation={0}
            sx={{
              border: '1px solid #0000001f',
              '&.MuiAccordion-root': {
                borderRadius: '4px'
              }
            }}
            data-cy="meeting-in-progress_in-camera-auth-accordion"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                '&.Mui-expanded': { borderBottom: '1px solid #0000001f' }
              }}
            >
              <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                {t(keyPrefix + '.authAccordionTitle', { count: topic?.visibleBy?.length })}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ mb: 2 }}>{t(keyPrefix + '.authAccordionVisibleByLabel')}</Typography>
              {topic?.visibleBy?.map((userId) => {
                return (
                  <User key={userId} id={userId as string}>
                    {(profile) => {
                      return (
                        <Chip
                          component="li"
                          sx={{ mr: 2, my: 0.5 }}
                          avatar={<Avatar alt={profile?.username} src={profile?.profilePicture} />}
                          label={profile?.username}
                          data-cy="meeting-in-progress_in-camera-auth-user-chip"
                        />
                      );
                    }}
                  </User>
                );
              })}
            </AccordionDetails>
          </Accordion>
          <DocumentListInProgress
            liveMeeting={props.liveMeeting}
            isProcessing={isProcessingDocuments}
            setIsProcessing={setIsProcessingDocuments} // when deleting a document, disable action for other documents (delete/add)
          />
          <BodyField />
          <Box>
            <AddResolutionButton liveMeeting={props.liveMeeting} />
          </Box>
        </>
      ) : (
        <Paper
          variant="outlined"
          sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          data-cy="meeting-in-progress_incamera-hidden-alert"
        >
          <ErrorOutlineIcon fontSize="large" sx={{ opacity: 0.6 }} />
          <Box sx={{ width: '100%', mt: 3, opacity: 0.6, textAlign: 'center' }}>
            {topic?.visibleBy ? (
              t(keyPrefix + '.alertContentHiddenNotSet')
            ) : (
              <Trans i18nKey={keyPrefix + '.alertContentHiddenNoAccess-html'} t={t} />
            )}
          </Box>
        </Paper>
      )}
    </MeetingInProgressFormController>
  );
};
