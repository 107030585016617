import { Box, Button, IconButton, InputAdornment, Alert } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { TextField } from 'components/temp/TextFieldTemp';
import { DateTimePicker } from 'components/temp/DateTimePickerTemp';
import { ICommittee } from 'types';
import { SelectTemp } from 'components/temp/SelectTemp';
import { DocumentList } from 'components/DocumentList';
import { DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';
import { useGateValue } from '@statsig/react-bindings';

interface IUploadedResolutionCardProps {
  committees: ICommittee[];
  uploadedResolutionId?: string;
  handleDeleteUploadedResolution?: () => void;
  isProcessing: boolean;
  isPdf: boolean;
}
export const UploadedResolutionCardEditable: React.FC<IUploadedResolutionCardProps> = (props) => {
  const { committees, uploadedResolutionId, handleDeleteUploadedResolution, isProcessing, isPdf } = props;
  const { t } = useLocale();
  const keyPrefix = 'UploadedResolutions.UploadedResolutionCard.Form';

  const resolutionOutcomeOptions = [
    { id: 'approved', label: t('common:TopicModel.resolutionOutcome.outcome.approved') },
    { id: 'approvedWithMods', label: t('common:TopicModel.resolutionOutcome.outcome.approvedWithMods') },
    { id: 'declined', label: t('common:TopicModel.resolutionOutcome.outcome.declined') }
  ];

  const isDocumentDownloadBlockedOnWorkspace = useGateValue('block_document_downloads');

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
      data-cy="uploaded-resolution-card-editable"
    >
      <Box sx={{ display: 'flex', gap: { xs: 0, md: 3 }, flexDirection: { xs: 'column', md: 'row' } }}>
        <TextField
          i18n={{ keyPrefix: keyPrefix + '.FieldResolutionNumber' }}
          name="resolutionNumber"
          validationRules={{ maxLength: 250 }}
          isOptional
          sx={{ width: { sm: '200px' }, flex: 'none' }}
          InputProps={{
            startAdornment: <InputAdornment position="start">#</InputAdornment>
          }}
        />
        <TextField
          name="title"
          validationRules={{ maxLength: 100 }}
          i18n={{ keyPrefix: keyPrefix + '.FieldTitle' }}
        />
      </Box>

      <Box sx={{ display: 'flex', gap: { xs: 0, md: 3 }, flexDirection: { xs: 'column', md: 'row' } }}>
        <SelectTemp
          name="committeeId"
          i18n={{ keyPrefix: keyPrefix + '.FieldCommitteeType' }}
          options={committees
            ?.sort((committeeA, committeeB) => {
              return committeeA.name > committeeB.name ? 1 : -1;
            })
            .map((committee) => ({
              id: committee.id,
              label: committee.name
            }))}
        />
        <SelectTemp
          name="resolutionOutcome.outcome"
          i18n={{ keyPrefix: keyPrefix + '.FieldResolutionOutcome' }}
          options={resolutionOutcomeOptions}
        />
      </Box>

      <DateTimePicker
        name="resolutionOutcome.recordedAt"
        i18n={{ keyPrefix: keyPrefix + '.FieldDate' }}
        type="date"
        inputProps={{ min: '1900-01-01', max: '2099-12-31' }}
        validationRules={{
          pattern: /^(19\d{2}|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/g
        }}
        sx={{
          '& .MuiInputBase-input': {
            backgroundColor: 'white'
          }
        }}
      />

      {!isPdf && isDocumentDownloadBlockedOnWorkspace && (
        <Alert severity="error">{t('common:downloadBlockedUploadAlertLabel')}</Alert>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {handleDeleteUploadedResolution && (
          <IconButton
            onClick={handleDeleteUploadedResolution}
            data-cy="uploaded-resolution-card-editable_delete-button"
            disabled={isProcessing}
            sx={{ mr: 2 }}
          >
            <DeleteOutlineIcon fontSize="small" />
          </IconButton>
        )}

        <Button
          type="submit"
          variant="contained"
          sx={{ alignSelf: 'flex-end' }}
          disabled={isProcessing}
          data-cy="uploaded-resolution-card-editable_save-button"
        >
          {t('common:labelSave')}
        </Button>
      </Box>

      {uploadedResolutionId && (
        <DocumentList
          path={`/uploaded-resolutions/${uploadedResolutionId}/documents`}
          isEditable={uploadedResolutionId ? true : false}
          isAccordion={true}
          accordionOptions={{ documentsVisible: 2 }}
          sxDocument={{ display: 'flex' }}
        />
      )}
    </Box>
  );
};
