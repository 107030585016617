import { IMeeting, ICommittee } from 'types';
import { useCallback, useState, useRef } from 'react';
import { useUserShow, useLocale, useResourceShow } from '@koopajs/react';
import { Box, Stack, Typography, Paper, Divider } from '@mui/material';
import { EventsTimeline } from '../EventsTimeline';
import _ from 'lodash';
import { ViewMinutesPrintVersion } from 'components/Dialogs/ViewMinutesPrintVersion';
import { MinutesHeaderCard } from 'components/MinutesHeaderCard';
import { generateMeetingTimeline } from 'utils/generateMeetingTimeline';
import { TopicCardMinutesEditable } from 'components/TopicCard/TopicCardMinutesEditable';
import { PanoramaDocumentWrapper } from 'components/PanoramaDocumentWrapper';

export const i18nTransComponents: { [k: string]: React.ReactElement } = {
  span: <span style={{ color: 'grey' }} />
};

interface IMeetingReviewForDialog {
  meeting: IMeeting;
  isBeingReviewedInsideMeeting?: boolean;
  isViewMode?: boolean;
}

export const MeetingReviewForDialog: React.FC<IMeetingReviewForDialog> = (props) => {
  const { meeting, isBeingReviewedInsideMeeting, isViewMode } = props;
  let topics = meeting?.topics;

  const { user: currentUser } = useUserShow();

  const { resource: committee } = useResourceShow<ICommittee>({
    path: '/committees',
    id: meeting.committeeId
  });

  const componentRef = useRef(null);

  const { t } = useLocale();

  const keyPrefix = 'MeetingStateReview';

  const [topicIdEditable, setTopicIdEditable] = useState('');

  const isUserReviewer =
    currentUser?.id && meeting.reviewers?.some((reviewer) => reviewer.userId === currentUser.id);

  const userHasEditAccess = Boolean(
    !meeting.boardApprovedAt && !isViewMode && (isBeingReviewedInsideMeeting || isUserReviewer)
  );

  const uniqueUserReviewsCount = meeting.reviewers?.filter((reviewers) => reviewers.latestReview).length;

  const handleSetTopicEditable = useCallback((topicId: string) => {
    return () => setTopicIdEditable(topicId);
  }, []);

  const handleUnsetTopicEditable = useCallback(() => {
    setTopicIdEditable('');
  }, []);

  const timelineEvents = generateMeetingTimeline(meeting);

  if (!isBeingReviewedInsideMeeting) {
    topics = topics?.filter((topic) => !topic.deletedAt);
  }

  return (
    <Box sx={{ padding: '24px' }} className="rr-block">
      <Box sx={{ display: 'none' }}>
        <ViewMinutesPrintVersion minutes={meeting} topics={topics} ref={componentRef} />
      </Box>
      <Box>
        {isBeingReviewedInsideMeeting && (
          <Box sx={{ mb: 2 }}>
            <Stack direction={{ xs: 'column-reverse', sm: 'row' }} alignItems={{ sm: 'center' }}>
              {meeting?.reviewers && meeting?.reviewers?.length > 0 && (
                <Typography
                  variant="body2"
                  sx={{ textAlign: 'right', opacity: 0.6, mb: { xs: 1, sm: 0 }, ml: { sm: 'auto' } }}
                >
                  {t(keyPrefix + '.reviewCountOfTotal', {
                    reviewsMade: uniqueUserReviewsCount,
                    reviewsPossible: meeting.reviewers.length
                  })}
                </Typography>
              )}
            </Stack>
          </Box>
        )}

        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 5, my: 3 }}>
          <PanoramaDocumentWrapper>
            <MinutesHeaderCard minutes={meeting} userHasEditAccess={userHasEditAccess} />
            <Divider sx={{ my: '14px' }} />
            <Stack spacing={2}>
              {topics?.map((topic, index) => {
                return (
                  <Box key={topic.id}>
                    <TopicCardMinutesEditable
                      topic={topic}
                      handleSetTopicEditable={handleSetTopicEditable}
                      order={index + 1}
                      isEditable={topic.id === topicIdEditable}
                      onSubmitSuccess={handleUnsetTopicEditable}
                      userHasEditAccess={userHasEditAccess}
                      meeting={meeting}
                      committee={committee}
                      isBeingReviewedInsideMeeting={isBeingReviewedInsideMeeting}
                    />
                    {topics && index !== topics.length - 1 && <Divider sx={{ my: '14px' }} />}
                  </Box>
                );
              })}
            </Stack>
          </PanoramaDocumentWrapper>
          <EventsTimeline timelineEvents={timelineEvents} sx={{ alignSelf: 'flex-start' }} />
        </Box>
      </Box>
    </Box>
  );
};
