import { useRef, useCallback } from 'react';
import { IResolution } from 'types';
import { Box } from '@mui/material';
import { Dialog } from '@koopajs/mui';
import { RenderPrintButton } from '../../RenderPrintButton';
import { ViewResolutionPrintVersion } from '../ViewResolutionPrintVersion';
import { useComponentVisibility, useResourceShow } from '@koopajs/react';
import { ResolutionContent } from './ResolutionContent';

export const ViewResolutionVisibilityDialog: React.FC = () => {
  const dialog = useComponentVisibility('resolutionDialog');

  const { resolutionId } = dialog.getContext() as { resolutionId: string };

  const {
    resource: resolution,
    isProcessing,
    errorMessage
  } = useResourceShow<IResolution>({
    path: '/resolutions',
    id: resolutionId
  });

  const keyPrefix = 'Resolutions.DialogView';
  const printComponentRef = useRef(null);

  const handleCloseDialog = useCallback(() => {
    dialog.setHidden();
  }, []);

  if (!resolution) return null;

  return (
    <Dialog.View
      isOpen={dialog.isVisible}
      onClose={handleCloseDialog}
      isCloseVisible={true}
      i18n={{ keyPrefix: keyPrefix }}
      footerActions={
        <RenderPrintButton keyPrefix={keyPrefix} componentRef={printComponentRef} size="small" />
      }
      dialogProps={{ className: 'rr-block', PaperProps: { 'data-cy': 'view-resolution-dialog' } }}
    >
      <Box sx={{ display: 'none' }}>
        <ViewResolutionPrintVersion resolution={resolution} ref={printComponentRef} />
      </Box>

      <ResolutionContent resolution={resolution} isProcessing={isProcessing} />
    </Dialog.View>
  );
};
