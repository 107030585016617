import { useLocale } from '@koopajs/react';
import { Stack, Typography, Box } from '@mui/material';
import { IMeeting, ITopic } from 'types';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';
import { theme } from 'components/Core/theme';
import { PastMeetingsToApproveList } from './PastMinutesApprovalField/PastMeetingsToApproveList';
import { DateTime } from 'luxon';
import { User } from '@koopajs/mui';
import { DisabledDocumentList } from '../BoardMemberView/BoardMemberViewSidebar/PastMeetingApproval/DisabledDocumentList';
import { TopicChipConditions } from 'components/TopicChipConditions';

import { TimestampChip } from '../TimestampChip';
interface ITopicCardMeetingPrintProps {
  topic: ITopic;
  order: number;
  meeting: IMeeting;
  showApprovedPastMeetingsToApprove?: boolean;
}
export const TopicCardMeetingPrint: React.FC<ITopicCardMeetingPrintProps> = (props) => {
  const { topic, order, showApprovedPastMeetingsToApprove } = props;

  const { t } = useLocale();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'baseline' }}>
      <Box sx={{ ml: 0, minWidth: '71px' }}>
        {' '}
        <TimestampChip
          variant="outlined"
          timestampIso={
            topic.calculatedTopicStartTime
              ? DateTime.fromSeconds(topic.calculatedTopicStartTime).toISO()
              : undefined
          }
        />
      </Box>
      <Stack direction="column" gap={1} flex={1} sx={{ minWidth: 0 }}>
        <Stack direction="row" gap={3}>
          <Typography
            sx={{
              fontWeight: 'bold',
              mr: 'auto',
              maxWidth: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
            variant="body2"
          >
            {order}. <>{topic.title ? topic.title : t(`common:topicTypes.${topic.type}`)}</>
          </Typography>
          <Stack direction="row" gap={3} flexShrink={0} alignItems="center">
            {topic.assignedTo && (
              <User
                variant="text-only"
                id={topic?.assignedTo || ''}
                sxText={{ ...theme.typography.caption }}
              />
            )}
            <Box
              sx={{
                minWidth: '92px' // information (longest chip) = 92px
              }}
            >
              <TopicChipConditions topicType={topic.type} isOutlined />
            </Box>
          </Stack>
        </Stack>

        {/* BODY FIELD */}
        {[
          'quorum',
          'nominations',
          'agendaApproval',
          'pastMinutesApproval',
          'ceoReport',
          'information',
          'discussion',
          'resolution',
          'miscellaneous',
          'adjournment'
        ].includes(topic.type) && (
          <>
            {topic.description && (
              <RichTextReadOnly
                value={topic.description}
                sxEditorContainer={{
                  ...theme.typography.body2
                }}
              />
            )}
          </>
        )}
        <DisabledDocumentList path={`/meetings/${topic.meetingId}/topics/${topic.id}/documents`} />

        {topic.type === 'pastMinutesApproval' && (
          <PastMeetingsToApproveList
            meetingId={topic.meetingId}
            topicId={topic.id}
            topic={topic}
            hideLabel
            showApprovedMeetings={showApprovedPastMeetingsToApprove ? true : false}
            isPrintView
          />
        )}
      </Stack>
    </Box>
  );
};
