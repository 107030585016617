import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useLocale } from '@koopajs/react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContext } from '@koopajs/react';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { useMultipleDocumentsInMemoryDropzone } from 'components/hooks/useMultipleDocumentsInMemoryDropzone';

interface IUploadResolutionButtonProps {
  isProcessing?: boolean;
  variant?: 'contained' | 'outlined';
  buttonLabel?: string;
}

export const UploadResolutionButton: React.FC<IUploadResolutionButtonProps> = (props) => {
  const { isProcessing: isReloadingResolutions, variant, buttonLabel } = props;
  const { setComponentContext } = useAppContext();

  const { t } = useLocale();
  const history = useHistory();

  const { dropzone, isProcessing, files } = useMultipleDocumentsInMemoryDropzone({
    isStoringFileInMemory: true,
    isMultiple: true
  });

  useEffect(() => {
    if (files) {
      history.push({ pathname: `/uploaded-resolutions` });
      setComponentContext('uploadedResolutions', { files });
    }
  }, [JSON.stringify(files)]);

  return (
    <Box {...dropzone.getRootProps()} data-cy="upload-resolution-button_container">
      <input {...dropzone.getInputProps()} data-cy="upload-resolution-button_input" />

      <LoadingButton
        onClick={dropzone.open}
        startIcon={<MoreTimeIcon />}
        loading={isProcessing}
        loadingPosition="start"
        disabled={isReloadingResolutions}
        variant={variant}
        data-cy="upload-resolution-button"
      >
        <span>{buttonLabel ? buttonLabel : t('Resolutions.addPastResolutionsButtonLabel')}</span>
      </LoadingButton>
    </Box>
  );
};
