import { TextField } from 'components/temp/TextFieldTemp';
import { Box, InputAdornment, Link } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { FormContext, useLocale } from '@koopajs/react';
import { useContext, useCallback } from 'react';

interface ITopicCardTitleField {
  topicType: string;
  isOptional?: boolean;
  order: number;
  sx: SystemStyleObject;
  showResetButton?: boolean;
}

export const TopicCardTitleField: React.FC<ITopicCardTitleField> = (props) => {
  const { isOptional, order, sx, showResetButton, topicType } = props;
  const { t } = useLocale();
  const keyPrefix = 'Components.TopicCard.FieldTitle';

  const { form } = useContext(FormContext);

  const handleReset = useCallback(() => {
    const translatedTitle = t(`common:topicTypes.${topicType}`);
    if (form) form.setValue('title', translatedTitle);
  }, [topicType]);

  return (
    <Box sx={{ mb: 1, ...sx }}>
      <TextField
        i18n={{ keyPrefix }}
        name="title"
        validationRules={{ maxLength: 150 }}
        InputProps={{
          startAdornment: <InputAdornment position="start">{order}.</InputAdornment>
        }}
        isOptional={isOptional}
        sx={{ mb: 0 }}
      />
      {showResetButton && (
        <Link
          sx={{
            textDecoration: 'none',
            color: 'link.main'
          }}
          component="button"
          type="button"
          onClick={handleReset}
          variant="caption"
        >
          {t(keyPrefix + '.resetToOriginal')}
        </Link>
      )}
    </Box>
  );
};
