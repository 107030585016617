import { List } from '@mui/material';
import { Typography, Box, ListItemButton } from '@mui/material';
import { AccessTime } from '@mui/icons-material';
import { Ref, useLocale } from '@koopajs/react';
import { IMeetingRef } from 'types';
import { formatDate } from 'utils/DateTime/formatDate';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { theme } from 'components/Core/theme';
import { useComponentVisibility } from '@koopajs/react';

interface IApprovedMinutesListProps {
  approvedMinutes?: { isApproved: boolean; meetingId: string }[];
}

export const ApprovedMinutesList: React.FC<IApprovedMinutesListProps> = (props) => {
  const { approvedMinutes } = props;

  const minutesDialog = useComponentVisibility('minutesDialog');

  const { locale } = useLocale();

  const filteredApprovedMinutes = approvedMinutes?.filter((m) => m.isApproved);

  const handleOpenMinutesDialog = (minutesId: string): void => {
    minutesDialog.setVisibleWithContext({ minutesId });
  };

  return (
    <>
      {filteredApprovedMinutes && filteredApprovedMinutes.length > 0 && (
        <Box>
          <List
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              mt: '0px!important',
              py: 0,
              '@media print': {
                listStyle: 'disc',
                pl: 3
              }
            }}
          >
            {filteredApprovedMinutes.map((m) => {
              const meetingId = m.meetingId;

              return (
                <Ref content={`ref:meeting:${meetingId}`} key={meetingId}>
                  {(item) => {
                    const meeting = item as IMeetingRef;
                    const minuteId = meeting?.minuteId;
                    const committeeType = useCommitteeName(meeting?.committeeId || '');

                    if (!minuteId) return null;

                    return (
                      <ListItemButton
                        onClick={() => handleOpenMinutesDialog(minuteId)}
                        disableGutters
                        sx={{
                          color: 'link.main',
                          display: 'flex',
                          alignItems: 'center',
                          maxWidth: '100%',
                          p: 0.5,
                          '@media print': {
                            display: 'list-item',
                            color: 'black',
                            '&:last-child': {
                              pb: 0
                            }
                          }
                        }}
                      >
                        <AccessTime
                          sx={{
                            mr: '8px',
                            '@media print': {
                              display: 'none'
                            }
                          }}
                        />

                        <>
                          <Typography
                            sx={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              '@media print': {
                                ...theme.typography.body2
                              }
                            }}
                          >
                            {meeting?.startDateTime &&
                              formatDate({
                                isoString: meeting.startDateTime,
                                format: 'DATE_FULL',
                                locale
                              })}
                            {' | '}
                            {committeeType}
                            {' | '}
                            {meeting?.label}
                          </Typography>
                        </>
                      </ListItemButton>
                    );
                  }}
                </Ref>
              );
            })}
          </List>
        </Box>
      )}
    </>
  );
};
