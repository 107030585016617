import type React from 'react';
import { Box } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import { useLocale } from '@koopajs/react';

export const NavigationResources: React.FC = () => {
  const { t } = useLocale();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          px: 2,
          pt: 0.5,
          pb: 2
        }}
      >
        {/* Help Link */}
        <a
          href="https://usepanorama.atlassian.net/servicedesk/customer/portals"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: 'none',
            width: '45%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              py: 1,
              borderRadius: '8px',
              '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.04)'
              }
            }}
          >
            {/* Rounded rectangle container for the icon */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '32px',
                height: '32px',
                mb: 0.5
              }}
            >
              <HelpOutlineIcon
                sx={{
                  fontSize: '1.2rem',
                  color: 'text.secondary'
                }}
              />
            </Box>
            <Box
              component="span"
              sx={{
                fontSize: '0.65rem',
                color: 'text.secondary',
                textTransform: 'uppercase',
                letterSpacing: '0.5px',
                fontWeight: 500
              }}
            >
              {t('common:navigation.help')}
            </Box>
          </Box>
        </a>

        {/* Feedback Link */}
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSfMNodYahcQ4205SsfCgdhLdIgl2Yb1-_DcfWiLiimHb6qZHQ/viewform?usp=sf_link"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: 'none',
            width: '45%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              py: 1,
              borderRadius: '8px', // Add rounded corners to the hover effect
              '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.04)'
              }
            }}
          >
            {/* Rounded rectangle container for the icon */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '32px',
                height: '32px',
                borderRadius: '6px',
                bgcolor: 'transparent',
                mb: 0.5
              }}
            >
              <FeedbackOutlinedIcon
                sx={{
                  fontSize: '1.2rem',
                  color: 'text.secondary'
                }}
              />
            </Box>
            <Box
              component="span"
              sx={{
                fontSize: '0.65rem',
                color: 'text.secondary',
                textTransform: 'uppercase',
                letterSpacing: '0.5px',
                fontWeight: 500
              }}
            >
              {t('common:navigation.feedback')}
            </Box>
          </Box>
        </a>
      </Box>
    </>
  );
};
