import type React from 'react';
import type { ComponentType } from 'react';
import { Link } from 'react-router-dom';

interface INavigationLinkProps extends React.PropsWithChildren {
  path: string;
  cyTestId?: string;
  icon: ComponentType<{ className?: string; fontSize?: 'small' }>;
  isActive: boolean;
  subMenu?: {
    path: string;
    id: string;
    text: string;
    isActive: boolean;
  }[];
  showBadge?: boolean;
  setHidden: () => void;
}

export const NavigationLink: React.FC<INavigationLinkProps> = (props) => {
  const { children, path, subMenu, showBadge, setHidden, cyTestId, isActive } = props;

  return (
    <>
      <Link
        to={path}
        onClick={setHidden}
        data-cy={cyTestId}
        className={`group flex justify-start items-center gap-4 py-2 mb-[1px] px-3 no-underline text-panoblue-950 bg-accent/50 whitespace-nowrap rounded-md text-base font-medium transition-colors  hover:bg-slate-100 ${
          isActive ? 'bg-slate-100 text-panoblue-950' : ''
        }`}
      >
        <props.icon
          className={` group-hover:scale-100  group-hover:text-panoblue-950 transition-colors ${
            isActive ? 'text-panoblue-950' : 'scale-90 text-slate-500'
          }`}
          fontSize="small"
        />

        {children}
        {showBadge && <span className="ml-auto h-2 w-2 rounded-full bg-red-700" />}
      </Link>

      {subMenu && isActive && (
        <div>
          {subMenu.map((item: { path: string; id: string; text: string; isActive: boolean }) => (
            <Link
              key={item.path}
              to={item.path}
              onClick={setHidden}
              data-cy={`navlinks_submenu-item_${item.id}-button`}
              className={` block py-2 px-6  ml-6 text-sm my-1 no-underline text-panoblue-950 whitespace-nowrap rounded-md  transition-colors  hover:bg-slate-100 ${
                item.isActive ? 'font-semibold' : 'font-light'
              }`}
            >
              {item.text}
            </Link>
          ))}
        </div>
      )}
    </>
  );
};
