import { IMeeting } from 'types';
import { Stack } from '@mui/material';
import { useParamsKey, useResourceShow, useUserShow } from '@koopajs/react';
import { useMeetingStatus } from 'components/hooks/useMeetingStatus';
import { Redirect, useHistory } from 'react-router-dom';
import { MeetingLoadingOrError } from 'components/Meetings/MeetingLoadingOrError';
import { LiveMeeting } from 'components/Meetings/InProgress/LiveMeeting';
import { useQueryParams, StringParam } from 'use-query-params';
import { DelayComponentRender } from 'components/DelayComponentRender';

export const MeetingInProgress: React.FC = (props) => {
  const meetingId = useParamsKey('meetingId');
  const {
    resource: meeting,
    isProcessing,
    errorMessage
  } = useResourceShow<IMeeting>({
    path: '/meetings',
    id: meetingId
  });

  const history = useHistory();

  const { user: currentUser } = useUserShow();
  const { hasMeetingStarted, hasMeetingEnded, isUserCurrentNoteTaker } = useMeetingStatus({
    meeting,
    user: currentUser
  });

  const [searchParams] = useQueryParams({ redirecting: StringParam });

  const doesUserHaveAccessToPage = hasMeetingStarted && !hasMeetingEnded && isUserCurrentNoteTaker;

  if (meeting && !doesUserHaveAccessToPage && !searchParams.redirecting) {
    history.push(`/meetings/${meetingId}`);
  }

  if (!meeting) {
    return <MeetingLoadingOrError isProcessing={isProcessing} errorMessage={errorMessage} />;
  } else if (!doesUserHaveAccessToPage) {
    // loading component while we wait for the redirect to the view page, or redirect to dashboard if we got stuck on the redirecting page
    return <DelayComponentRender delay={3000} component={<Redirect to={`/tasks/to-review`} />} />;
  }
  return <LiveMeeting meeting={meeting} />;
};
