import { IMeeting } from 'types';
import { Stack, Typography, Divider, Box, Link as MUILink, Skeleton, Button, Paper } from '@mui/material';
import { DateTime } from 'luxon';
import { User } from '@koopajs/mui';
import { useLocale, useUserShow } from '@koopajs/react';
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import { MeetingStateChip } from 'components/Meetings/MeetingStateChip';
import { DateTimeStartEnd } from 'components/DateTimeStartEnd';
import { useScreenSize } from 'utils/useScreenSize';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import { useMeetingStatus } from 'components/hooks/useMeetingStatus';
import { Link as RouterLink } from 'react-router-dom';
import { WriteMinutesButton } from './MeetingCardButtons/WriteMinutesButton';
import GestureIcon from '@mui/icons-material/Gesture';

interface IMeetingCard {
  meeting: IMeeting;
}

export const MeetingCard: React.FC<IMeetingCard> = (props) => {
  const { meeting } = props;

  const { t } = useLocale();
  const keyPrefix = 'Dashboard.MeetingCard';
  const { user } = useUserShow();

  const {
    canUserContinueMeeting,
    hasMeetingStarted,
    isUserMeetingNoteTakerRole,
    meetingCommittee,
    canUserCurrentlyEditMeeting,
    isUserMeetingCreatorRole,
    isMeetingAgendaSent
  } = useMeetingStatus({
    meeting,
    user
  });

  const { isMobileScreen, isScreenAboveMd } = useScreenSize();

  return (
    <Paper
      sx={{
        padding: '24px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
      variant="outlined"
      data-cy="dashboard_meeting-card"
    >
      <Box>
        {/* HEADER */}
        <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ md: 'center' }}
            sx={{ fontSize: '16px', mb: { xs: 2, md: 0 } }}
          >
            {/* meeting state chip mobile (+ mobile view) */}
            <Stack flexDirection="row" justifyContent="space-between" sx={{ display: { md: 'none' } }}>
              <MeetingStateChip meeting={meeting} sx={{ mb: 2 }} />
              <Box sx={{ display: { md: 'none' } }}>
                <Button
                  aria-label={t('common:viewMeeting')}
                  component={RouterLink}
                  to={{
                    pathname: `/meetings/${meeting.id}`,
                    state: { activePath: '/upcoming-meetings' }
                  }}
                  sx={{ color: 'primary.main' }}
                >
                  {t('common:view')}
                </Button>
              </Box>
            </Stack>
            {/* Date & Time */}

            <Box>
              <DateTimeStartEnd
                isoStringDate={
                  meeting.startDateTime ||
                  (meeting?.startDate
                    ? DateTime.fromFormat(`${meeting.startDate}`, 'yyyy-MM-dd').toString()
                    : undefined)
                }
                separator={isMobileScreen ? undefined : ' | '}
                dateFormat="DATE_HUGE"
                isoStringStart={
                  meeting.startDateTime ||
                  (meeting.startTime &&
                    DateTime.fromFormat(meeting?.startTime as string, 'HH:mm', {
                      zone: 'UTC'
                    }).toString())
                }
                isoStringEnd={meeting.endDateTime}
                showNoDateNoTime={true}
              />
            </Box>

            {/* Chip desktop */}
            {isScreenAboveMd && <MeetingStateChip meeting={meeting} sx={{ alignSelf: 'center', ml: 2 }} />}
          </Stack>
          {/* VIEW ICON / EDIT BUTTON DESKTOP */}
          {isScreenAboveMd && (
            <Box sx={{ display: 'flex' }}>
              <Button
                sx={{ color: 'primary.main' }}
                component={RouterLink}
                to={{
                  pathname: `/meetings/${meeting.id}`,
                  state: { activePath: '/upcoming-meetings' }
                }}
                data-cy="dashboard_meeting-card_view-button"
              >
                {t('common:view')}
              </Button>
              {canUserCurrentlyEditMeeting && (
                <Button
                  component={RouterLink}
                  to={{
                    pathname: `/meetings/${meeting.id}/edit`,
                    state: { activePath: '/upcoming-meetings' }
                  }}
                  data-cy="dashboard_meeting-card_edit-button"
                  variant="outlined"
                  sx={{ ml: 2 }}
                >
                  {t('common:labelEdit')}
                </Button>
              )}
            </Box>
          )}
        </Stack>
        <Divider sx={{ mt: 2 }} />
      </Box>

      <Stack direction={{ xs: 'column', md: 'row' }}>
        <Box sx={{ width: '100%', minWidth: 0 }}>
          {/* Title */}
          <Typography
            sx={{ my: 2, fontSize: '16px', overflow: 'hidden', textOverflow: 'ellipsis' }}
            variant="body1"
            component="h3"
            data-cy="dashboard_meeting-card_meeting-title"
          >
            {meeting.title}
          </Typography>

          {/* Committee, location, participants */}
          <Typography
            variant="body2"
            sx={{
              opacity: '0.6',
              mb: 1,
              display: 'flex',
              flexWrap: 'wrap',
              minWidth: '0px',
              overflow: 'hidden',
              '&& > *': {
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }
            }}
          >
            {meetingCommittee && (
              <>
                <span>{meetingCommittee}</span>
                <span style={{ marginRight: '10px', marginLeft: '10px' }}>&#183;</span>
              </>
            )}

            <span>
              {meeting.location?.type
                ? t(`common:locationType.${meeting.location.type}`)
                : t('common:noLocation')}
            </span>

            <span
              style={{
                marginRight: '10px',
                marginLeft: '10px',
                display: isMobileScreen ? 'none' : 'inline'
              }}
            >
              &#183;
            </span>
            <span style={{ flexBasis: isMobileScreen ? '100%' : '', marginLeft: 0 }}>
              {t(`${keyPrefix}.participantsInvited`, { count: meeting.participants?.length || 0 })}
            </span>
          </Typography>
          {meeting.location?.url && (
            <Box sx={{ mb: 1 }}>
              <MUILink
                href={meeting.location?.url}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textDecoration: 'none',
                  color: 'link.main'
                }}
                target="_blank"
                rel="noopener"
              >
                <OpenInNewIcon fontSize="small" sx={{ mr: 0.5 }} />
                <Typography variant="body2">{t(keyPrefix + '.meetingLink')}</Typography>
              </MUILink>
            </Box>
          )}
          {meeting.location?.address && (
            <Box sx={{ whiteSpace: 'pre-wrap', display: 'flex', alignItems: 'end', mb: 1 }}>
              <LocationOnIcon fontSize="small" sx={{ mt: '2px', mr: 0.5, opacity: '0.6' }} />
              <Typography variant="body2" sx={{ opacity: '0.6' }} noWrap={true} width="350px">
                {meeting.location?.address}
              </Typography>
            </Box>
          )}
        </Box>

        {/* ANNOTATE, WRITE MINUTES / JOIN MEETING  BUTTONS - mobile and desktop  */}
        <Box
          sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 1, justifyContent: 'flex-end' }}
        >
          {/* Message box: Someone is taking notes */}
          {hasMeetingStarted &&
            meeting.currentNoteTakerId &&
            isUserMeetingNoteTakerRole &&
            !canUserContinueMeeting && (
              <Paper
                sx={{
                  padding: '14px',
                  borderRadius: '4px',
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: '450px',
                  mt: 1,
                  alignSelf: { xs: 'flex-start', md: 'flex-end' }
                }}
                variant="outlined"
                data-cy="dashboard_meeting-card_notetaker-banner"
              >
                <Stack direction="row" spacing={1}>
                  <PersonIcon color="action" fontSize="small" />

                  <Typography variant="caption" sx={{ color: '#777777', fontSize: '14px' }}>
                    <User
                      id={meeting.currentNoteTakerId}
                      // eslint-disable-next-line react/jsx-no-bind
                      render={(profile) => (
                        <>
                          {profile?.username || (
                            <Skeleton variant="text" sx={{ display: 'inline-block', width: '100px' }} />
                          )}
                        </>
                      )}
                    />{' '}
                    {t(keyPrefix + '.isTakingNotes')}
                  </Typography>
                </Stack>
                <Typography variant="caption" sx={{ mt: 1, color: '#777777', fontSize: '14px' }}>
                  {t(keyPrefix + '.AskTheUserToQuitMeeting')}
                </Typography>
              </Paper>
            )}

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              gap: { xs: 1, sm: 2 },
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: { xs: 'flex-start', md: 'flex-end' },
              alignItems: { xs: 'flex-start', md: 'center' },
              mt: { xs: 1, md: 0 }
            }}
          >
            {/*
           - Show the "Join Meeting" button if the user is not a note-taker.
           - On mobile, show the "Join Meeting" button only if the user does not have the "createMeeting" or "takingNotes" role */}
            {meeting.location?.url && !canUserContinueMeeting && (
              <Button
                variant="outlined"
                startIcon={<OpenInNewIcon />}
                href={meeting.location?.url}
                target="_blank"
                rel="noopener"
                sx={{
                  display: { xs: isUserMeetingCreatorRole ? 'none' : 'flex', md: 'flex' }
                }}
              >
                {t('Components.StartMeetingBanner.labelButtonJoinMeeting')}
              </Button>
            )}
            {/* EDIT BUTTON MOBILE */}
            {/* - Show the "Edit" button on mobile only if the user doesn't have the "takingNotes" role*/}
            {canUserCurrentlyEditMeeting && (
              <Box sx={{ display: { xs: canUserContinueMeeting ? 'none' : 'block', md: 'none' } }}>
                <Button
                  variant="outlined"
                  component={RouterLink}
                  to={{
                    pathname: `/meetings/${meeting.id}/edit`,
                    state: { activePath: '/upcoming-meetings' }
                  }}
                  data-cy="dashboard_meeting-card_edit-button"
                >
                  {t('common:labelEdit')}
                </Button>
              </Box>
            )}
            {isMeetingAgendaSent && (
              <Button
                component={RouterLink}
                startIcon={<GestureIcon />}
                variant={canUserContinueMeeting ? 'outlined' : 'contained'}
                data-cy="dashboard_meeting-card_annotate-button"
                to={`/meeting-annotate/${meeting.id}`}
              >
                {t('Dashboard.MeetingCard.labelAnnotate')}
              </Button>
            )}
            {canUserContinueMeeting && <WriteMinutesButton meeting={meeting} variant="contained" />}
          </Box>
        </Box>
      </Stack>
    </Paper>
  );
};
