import { Paper } from '@mui/material';

interface IPaperWrapperProps {
  children: React.ReactNode;
}

export const PanoramaDocumentWrapper: React.FC<IPaperWrapperProps> = (props) => {
  const { children } = props;

  if (!children) return null;

  return (
    <Paper
      elevation={6}
      sx={{
        p: { xs: '18px', lg: '60px' },
        maxWidth: '912px',
        display: 'flex',
        flexDirection: 'column',
        gap: '14px',
        minWidth: 0,
        width: '100%'
      }}
    >
      {children}
    </Paper>
  );
};
