import React, { useCallback, useRef, useEffect } from 'react';
import { Dialog } from '@koopajs/mui';
import { useHistory } from 'react-router-dom';
import { useResourceShow } from '@koopajs/react';
import { IMeeting } from 'types';
import { RenderPrintButton } from 'components/RenderPrintButton';
import { useSnackbar } from 'notistack';
import { IconButton, Box } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { extractErrorMessage } from 'utils/extractErrorMessage';
import { PastMeetingContent } from './PastMeetingContent';
import { useComponentVisibility } from '@koopajs/react';
import { MeetingPrintVersion } from 'components/Meetings/MeetingPrintVersion';
import { useMeetingStatus } from 'components/hooks/useMeetingStatus';

// this component is used when an id is not needed in the URL

export const ViewPastMeetingVisibilityDialog: React.FC = () => {
  const dialog = useComponentVisibility('pastMeetingDialog');
  const { pastMeetingId } = dialog.getContext() as { pastMeetingId: string };
  const history = useHistory();

  const {
    resource: pastMeeting,
    isProcessing,
    errorMessage
  } = useResourceShow<IMeeting>({
    path: '/meetings',
    id: pastMeetingId
  });

  const { meetingDuration } = useMeetingStatus({ meeting: pastMeeting });

  const keyPrefix = 'Dialogs.PastMeetingDialog';
  const extractedErrorMessage = extractErrorMessage(errorMessage);

  const handleCloseDialog = useCallback(() => {
    dialog.setHidden();
  }, []);

  const componentRef = useRef(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (errorMessage === "Error 403: You don't have access to this meeting") {
      enqueueSnackbar(extractedErrorMessage, {
        variant: 'error',
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });
      history.push('/past-meetings');
    }
  }, [errorMessage, enqueueSnackbar, closeSnackbar, pastMeetingId]);

  return (
    <Dialog.View
      isOpen={dialog.isVisible}
      onClose={handleCloseDialog}
      isCloseVisible={true}
      i18n={{ keyPrefix: keyPrefix + '.DialogView' }}
      footerActions={
        <RenderPrintButton
          keyPrefix={keyPrefix + '.DialogView'}
          componentRef={componentRef}
          documentTitle={pastMeeting?.title}
          size="small"
        />
      }
      dialogProps={{
        className: 'rr-block',
        PaperProps: { 'data-cy': 'view-past-meeting-visibility-dialog' }
      }}
    >
      <Box sx={{ display: 'none' }}>
        <MeetingPrintVersion
          meeting={pastMeeting}
          showApprovedPastMeetingsToApprove
          ref={componentRef}
          meetingDuration={meetingDuration}
        />
      </Box>
      <PastMeetingContent pastMeeting={pastMeeting} isProcessing={isProcessing} />
    </Dialog.View>
  );
};
