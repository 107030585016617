import { useMemo, useState } from 'react';
import { useLocale, useResourceList, useUserShow } from '@koopajs/react';
import { Typography, Tabs, Tab, Stack } from '@mui/material';
import { IResolution, ICommittee, IMinute } from 'types';
import { NoMeetingMessage } from 'components/Dashboard/NoMeetingMessage';
import { Helmet } from 'react-helmet';
import { ToSignList } from 'components/Dashboard/ToSign/ToSignList';
import { EmptyStateToSign } from 'components/EmptyStates/EmptyStateToSign';
import { PageContainer } from 'components/temp/PageContainer';
import { Loading } from '@koopajs/mui';

export default function Page(): JSX.Element {
  const { t } = useLocale();
  const { user } = useUserShow();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const { resources: minutesToSign, state: meetingsState } = useResourceList<IMinute>({
    path: '/minutes',
    searchParams: {
      size: 50,
      filters: ['NOT _exists_:signaturesCompletedAt', 'NOT $model:"uploaded-minute"']
    }
  });

  const { resources: resolutionsToSign, state: resolutionsState } = useResourceList<IResolution>({
    path: '/resolutions',
    searchParams: {
      size: 50,
      filters: [
        'NOT _exists_:signaturesCompletedAt',
        'NOT state.keyword:draft',
        'NOT $model:"uploaded-resolution"'
      ]
    }
  });

  const sortedItemsToSign = useMemo(() => {
    const obj: {
      currentUser: (IResolution | IMinute)[];
      otherUsers: (IResolution | IMinute)[];
    } = {
      currentUser: [],
      otherUsers: []
    };

    const itemsToSign = [
      ...minutesToSign.map((m): IMinute => ({ ...m, model: 'Minute' })),
      ...resolutionsToSign.map((r): IResolution => ({ ...r, model: 'Resolution' }))
    ];
    itemsToSign.forEach((item) => {
      // eslint-disable-next-line no-unused-expressions
      item.membersLeftToSign?.some((m) => m.userId === user?.id)
        ? obj.currentUser.push(item)
        : obj.otherUsers.push(item);
    });

    return obj;
  }, [JSON.stringify(minutesToSign), JSON.stringify(resolutionsToSign)]);

  const { resources: committees } = useResourceList<ICommittee>({ path: '/committees', useCache: true });

  const committeeObj: { [k: string]: string } = {};
  committees.map((committee) => {
    committeeObj[committee.id] = committee.name;
  });

  const selectedItemsToSign =
    selectedTabIndex === 0 ? sortedItemsToSign.currentUser : sortedItemsToSign.otherUsers;

  if (meetingsState === 'loading' || resolutionsState === 'loading') {
    return (
      <Stack sx={{ position: 'relative', width: '100%', height: '100%' }}>
        <Loading sx={{ backgroundColor: 'transparent', position: 'absolute' }} />
      </Stack>
    );
  }
  if (sortedItemsToSign.currentUser.length === 0 && sortedItemsToSign.otherUsers.length === 0) {
    return <EmptyStateToSign />;
  }

  return (
    <>
      <Helmet>
        <title>{`${t('common:navigation.toSign')} - Panorama`}</title>
      </Helmet>

      <PageContainer sxChildren={{ px: { xs: '24px', lg: '48px' }, py: '24px' }} className="rr-block">
        <Typography variant="h1" sx={{ fontSize: '24px' }}>
          {t('Dashboard.titleToSign')}
        </Typography>

        <Tabs
          value={selectedTabIndex}
          onChange={(event, selectedIndex) => setSelectedTabIndex(selectedIndex as number)}
          sx={{ display: 'inline-flex', mt: 2, mb: 1 }}
        >
          <Tab label={t('Dashboard.tabMySignatures')} data-cy={`dashboard_my-signatures-tab`} />
          <Tab label={t('Dashboard.tabPendingSignatures')} data-cy={`dashboard_pending-signatures-tab`} />
        </Tabs>

        {selectedItemsToSign.length > 0 ? (
          <ToSignList itemsToSign={selectedItemsToSign} isSignable={Boolean(selectedTabIndex === 0)} />
        ) : (
          <NoMeetingMessage text={t('Dashboard.noItemsToSign')} />
        )}
      </PageContainer>
    </>
  );
}
