import React from 'react';
import { IMinute } from 'types';
import { Box, Divider } from '@mui/material';
import { TopicCardMinutes } from 'components/TopicCard/TopicCardMinutes';
import { EventsTimeline } from 'components/EventsTimeline';
import { SignatureList } from 'components/Signature/SignatureList';
import { MinutesHeaderCard } from 'components/MinutesHeaderCard';
import { generateMeetingTimeline } from 'utils/generateMeetingTimeline';
import { getSignaturesWithTitles } from 'utils/getSignaturesWithTitles';
import { Loading } from '@koopajs/mui';
import { PanoramaDocumentWrapper } from '../../PanoramaDocumentWrapper';

interface IMinutesContent {
  minutes?: IMinute;
  isProcessing: boolean;
}

export const MinutesContent: React.FC<IMinutesContent> = (props) => {
  const { minutes, isProcessing } = props;

  const timelineEvents = generateMeetingTimeline(minutes);

  const signaturesWithTitles = getSignaturesWithTitles(minutes);

  return (
    <>
      {isProcessing ? (
        <Box sx={{ my: 3 }}>
          <Loading sx={{ backgroundColor: 'transparent' }} />
        </Box>
      ) : (
        minutes && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 5, my: 3 }}>
              <PanoramaDocumentWrapper>
                <MinutesHeaderCard minutes={minutes} />
                <Divider />

                <Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {minutes.approvedTopics?.map((topic, index, array) => {
                      return (
                        <Box key={topic.id}>
                          <TopicCardMinutes topic={topic} order={index + 1} minutes={minutes} />
                          {index !== array.length - 1 && <Divider sx={{ my: '14px' }} />}
                        </Box>
                      );
                    })}
                  </Box>
                </Box>

                {signaturesWithTitles && (
                  <Box>
                    <Divider sx={{ my: '14px' }} />
                    <SignatureList signatures={signaturesWithTitles} />
                  </Box>
                )}
              </PanoramaDocumentWrapper>

              <EventsTimeline timelineEvents={timelineEvents} sx={{ alignSelf: 'flex-start' }} />
            </Box>
          </>
        )
      )}
    </>
  );
};
