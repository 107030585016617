import { useCallback, useState, useRef } from 'react';
import { ButtonProps, MenuItem, Button, Menu, Stack } from '@mui/material';
import { useLocale } from '@koopajs/react';

import { CreateMeetingDialog } from './Dialogs/CreateMeetingDialog';
import { CreateWrittenResolutionDialog } from './Dialogs/CreateWrittenResolutionDialog';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Add as AddIcon } from '@mui/icons-material';
import { useCommitteePermissions } from 'data/permissions/useCommitteePermissions';
interface ICreateProps {
  sxButton?: SxProps<Theme>;
  otherButtonProps?: ButtonProps;
}

export const CreateButton: React.FC<ICreateProps> = (props) => {
  const { sxButton, otherButtonProps } = props;
  const [dialogOpen, setDialogOpen] = useState<'createMeeting' | 'createWrittenResolution' | ''>('');

  const { isUserMeetingCreator, isUserResolutionCreator } = useCommitteePermissions();

  const { t } = useLocale();

  const closeDialog = useCallback(() => {
    setDialogOpen('');
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleClickCreateButton = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSelectOption = useCallback((item: 'createMeeting' | 'createWrittenResolution') => {
    return () => {
      setDialogOpen(item);
      handleCloseMenu();
    };
  }, []);

  const createButtonRef = useRef<HTMLButtonElement>(null);

  if (!isUserMeetingCreator && !isUserResolutionCreator) {
    return null;
  }

  return (
    <>
      {isUserMeetingCreator && (
        <CreateMeetingDialog isOpen={dialogOpen === 'createMeeting'} onClose={closeDialog} />
      )}
      {isUserResolutionCreator && (
        <CreateWrittenResolutionDialog
          isOpen={dialogOpen === 'createWrittenResolution'}
          onClose={closeDialog}
        />
      )}

      <Button
        aria-controls={menuOpen ? 'create-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        variant="contained"
        onClick={handleClickCreateButton}
        data-cy="create-button"
        ref={createButtonRef}
        disableElevation
        sx={{ ...sxButton, p: 0 }}
        {...otherButtonProps}
      >
        <Stack
          flexDirection="row"
          gap="6px"
          sx={{ flex: 1, p: '8px 12px', textAlign: 'left', alignItems: 'center' }}
        >
          <AddIcon fontSize="small" />
          <span className="leading-none text-base">{t('Components.CreateButton.labelCreate')}</span>
        </Stack>
        <Stack sx={{ borderLeft: '1px solid rgba(255, 255, 255, 0.2)', p: '8px 10px' }}>
          <ArrowDropDownIcon />
        </Stack>
      </Button>

      <Menu
        id="create-menu"
        MenuListProps={{
          'aria-labelledby': 'create-button'
        }}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleCloseMenu}
      >
        {isUserMeetingCreator && (
          <MenuItem
            onClick={handleSelectOption('createMeeting')}
            disableRipple
            data-cy="create-button_create-meeting"
          >
            {t('Components.CreateButton.labelCreateMeeting')}
          </MenuItem>
        )}
        {isUserResolutionCreator && (
          <MenuItem
            onClick={handleSelectOption('createWrittenResolution')}
            disableRipple
            data-cy="create-button_create-resolution"
          >
            {t('Components.CreateButton.labelCreateResolution')}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
