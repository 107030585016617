import React, { useCallback, useRef, useEffect } from 'react';
import { Dialog } from '@koopajs/mui';
import { useHistory } from 'react-router-dom';
import { useResourceShow, useComponentVisibility } from '@koopajs/react';
import { IMinute, IApprovedTopic } from 'types';
import { Box } from '@mui/material';
import { RenderPrintButton } from 'components/RenderPrintButton';
import { ViewMinutesPrintVersion } from 'components/Dialogs/ViewMinutesPrintVersion';
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { extractErrorMessage } from 'utils/extractErrorMessage';
import { MinutesContent } from './MinutesContent';

// this component is used when an id is not needed in the URL

export const ViewMinutesVisibilityDialog: React.FC = () => {
  const dialog = useComponentVisibility('minutesDialog');
  const { minutesId } = dialog.getContext() as { minutesId: string };

  const history = useHistory();

  const {
    resource: minutes,
    isProcessing,
    errorMessage
  } = useResourceShow<IMinute>({
    path: '/minutes',
    id: minutesId
  });

  const extractedErrorMessage = extractErrorMessage(errorMessage);
  const keyPrefix = 'Dialogs.ViewMinutes.DialogView';

  const handleCloseDialog = useCallback(() => {
    dialog.setHidden();
  }, []);

  const componentRef = useRef(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (errorMessage === "Error 403: You don't have access to these minutes") {
      enqueueSnackbar(extractedErrorMessage, {
        variant: 'error',
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });
      history.push('/minutes');
    }
  }, [errorMessage, enqueueSnackbar, closeSnackbar, minutesId]);

  return (
    <Dialog.View
      isOpen={dialog.isVisible}
      onClose={handleCloseDialog}
      isCloseVisible={true}
      i18n={{ keyPrefix }}
      footerActions={
        <RenderPrintButton
          keyPrefix={keyPrefix}
          componentRef={componentRef}
          documentTitle={minutes?.title}
          size="small"
        />
      }
      dialogProps={{ className: 'rr-block' }}
    >
      <Box sx={{ display: 'none' }}>
        <ViewMinutesPrintVersion
          minutes={minutes}
          topics={minutes?.approvedTopics as IApprovedTopic[]}
          ref={componentRef}
        />
      </Box>
      <MinutesContent minutes={minutes} isProcessing={isProcessing} />
    </Dialog.View>
  );
};
