import { Ref, useLocale, useResourceList } from '@koopajs/react';
import { Chip, Box, Button, Typography } from '@mui/material';
import { useSearchParams } from '@koopajs/react';
import React, { useCallback } from 'react';
import { dateFilters } from 'utils/dateRangeFilters';
import { User } from '@koopajs/react';
import { ICommittee } from 'types';
import { DateTime } from 'luxon';

export interface IFilterChipsProps {
  renderChipContent?: (key: string, value: string) => React.ReactNode;
  isProcessing?: boolean;
}

const dateFacets: string[] = [
  'startDateTime',
  'meetingStartDateTime',
  'attachedToMeetingTopic.meetingStartDateTime',
  'resolutionOutcome.recordedAt',
  'timestamps.startedAt',
  'lastDocumentActiveSince'
];
const userFacets: string[] = ['$ownedBy.keyword', 'visibleBy.keyword'];
const committeeTypeFacets: string[] = ['meetingCommitteeId.keyword', 'committeeId.keyword'];

export const FilterChips: React.FC<IFilterChipsProps> = (props) => {
  const { searchParams, removeFilter, setSearchParams } = useSearchParams();
  const filters = searchParams.filters?.filter((f) => !f.startsWith('id') && !f.startsWith('topicId'));

  const { resources: committees } = useResourceList<ICommittee>({ path: '/committees', useCache: true });

  const { t, locale } = useLocale();

  const generateLabelFromKey = (filter: string): React.ReactNode => {
    const [type, ...value] = filter.split(':');
    const cleanFilterValue = value.join(':').replace(/"/g, '');

    let keyPrefix: string | undefined;
    if (type === 'type.keyword') keyPrefix = 'common:topicTypes';
    if (type === 'resolutionOutcome.outcome.keyword')
      keyPrefix = 'common:TopicModel.resolutionOutcome.outcome';
    if (type === 'lastDocumentFileType.keyword') keyPrefix = 'common:fileTypes';
    const finalFilterValue = keyPrefix
      ? t(`${keyPrefix}.${cleanFilterValue}`) || cleanFilterValue
      : cleanFilterValue;

    if (props.renderChipContent) {
      return props.renderChipContent(type, finalFilterValue);
    }

    if (['minuteId', 'attachedToMeetingTopic.minuteId'].includes(type)) {
      return <Ref content={`ref:minute:${finalFilterValue}`} key={finalFilterValue} />;
    }

    if (committeeTypeFacets.includes(type)) {
      return committees.find((c) => c.id === cleanFilterValue)?.name;
    }

    if (userFacets.includes(type)) {
      return (
        <User id={cleanFilterValue}>
          {(profile) =>
            profile ? (
              <>
                <Typography variant="body2">{profile?.username}</Typography>
              </>
            ) : (
              <Typography />
            )
          }
        </User>
      );
    }

    if (dateFacets.includes(type)) {
      const matchedFilter = dateFilters.find((item) => {
        return item.generateFilter() === cleanFilterValue.replace('[', '').replace(']', '') ? true : false;
      });

      let customRangeDate = t(`common:dateRange.customRange`);
      if (!matchedFilter) {
        // extract dates from filter
        const input = Array.isArray(filters) ? filters.join(' ') : '';
        const regex = /\d+/g;
        const matches = input?.match(regex);

        if (matches && matches.length >= 2) {
          const startMilliseconds = parseInt(matches[0], 10);
          const endMilliseconds = parseInt(matches[1], 10);

          const startDate = DateTime.fromMillis(startMilliseconds)
            .setLocale(locale)
            .toLocaleString(DateTime.DATE_SHORT);
          const endDate = DateTime.fromMillis(endMilliseconds)
            .setLocale(locale)
            .toLocaleString(DateTime.DATE_SHORT);

          customRangeDate = `${startDate} ${t('common:labelTo')} ${endDate}`;
        }
      }

      const key = matchedFilter ? t(`common:dateRange.${matchedFilter.key}`) : customRangeDate;

      return <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{key}</Box>;
    }

    return <Ref content={finalFilterValue} />;
  };

  const generateHandleDelete = (filter: string): (() => void) => {
    return () => {
      removeFilter(filter);
    };
  };

  const handleResetFilters = useCallback((): void => {
    setSearchParams({
      filters: []
    });
  }, [setSearchParams]);

  if (!filters || filters.length === 0) {
    return null;
  }

  return (
    <Box>
      {filters?.map((filter: string) => (
        <Chip
          sx={{ mr: 1 }}
          key={filter}
          label={generateLabelFromKey(filter)}
          onDelete={generateHandleDelete(filter)}
          disabled={props.isProcessing}
          variant="outlined"
        />
      ))}
      {filters && filters.length > 1 && (
        <Button variant="text" onClick={handleResetFilters} disabled={props.isProcessing} color="inherit">
          {t('common:labelReset')}
        </Button>
      )}
    </Box>
  );
};
