import { useLocale, FormContext } from '@koopajs/react';
import { useContext } from 'react';
import { TextField } from 'components/temp/TextFieldTemp';
import { Form } from '@koopajs/mui';
import { DateTimePicker } from 'components/temp/DateTimePickerTemp';
import { useDocumentReviewIntervalOptions } from 'utils/DocumentRecords/useDocumentReviewIntervalOptions';
import { useDocumentCategorySelectOptions } from 'utils/DocumentRecords/useDocumentCategorySelectOptions';
import { Stack, ListItemText, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { transformFormDataReviewInterval } from 'utils/transformDocumentRecordDataForm';
import { formatDate } from 'utils/DateTime/formatDate';

interface IDocumentRecordFormFieldsProps {
  committeesOptions: {
    id: string;
    label: string;
  }[];
  committeeFieldState?: 'disabled' | 'hidden';
  categoryFieldState?: 'disabled' | 'hidden';
}

export const DocumentRecordFormFields: React.FC<IDocumentRecordFormFieldsProps> = (props) => {
  const { committeesOptions, committeeFieldState, categoryFieldState } = props;

  const { t, locale } = useLocale();
  const keyPrefix = 'DocumentRecord.FormFields';

  const context = useContext(FormContext);

  const reviewIntervalField: string = context.form?.watch('reviewInterval');
  const lastDocumentActiveSinceField: string = context.form?.watch('lastDocumentActiveSince');

  const documentReviewFieldOptions = useDocumentReviewIntervalOptions();
  const categoryFieldOptions = useDocumentCategorySelectOptions();

  const renderToUpdate = (): React.ReactNode => {
    if (reviewIntervalField === 'none' || !reviewIntervalField) {
      return null;
    } else if (reviewIntervalField === 'custom') {
      return (
        <DateTimePicker
          name="toReviewAt"
          i18n={{ keyPrefix: keyPrefix + '.FieldToReviewAt' }}
          type="date"
          inputProps={{ min: '1900-01-01', max: '2099-12-31' }}
          sx={{
            flex: '1 1 300px',
            '& .MuiInputBase-input': {
              backgroundColor: 'white'
            }
          }}
        />
      );
      // if review interval has a value that isn't custom or none, and active since is set
    } else if (lastDocumentActiveSinceField) {
      const reviewIntervalTransformed = transformFormDataReviewInterval(reviewIntervalField);
      const toReviewByDate =
        reviewIntervalTransformed && lastDocumentActiveSinceField
          ? DateTime.fromISO(lastDocumentActiveSinceField + `T00:00:00.000Z`)
              .toUTC()
              .plus(reviewIntervalTransformed)
              .toString()
          : '';
      return (
        <ListItemText
          primary={t(keyPrefix + '.FieldToReviewAt.label')}
          primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
          sx={{
            flex: '1 1 300px',
            mt: 2,
            mb: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
          secondary={
            <Typography
              sx={{
                display: 'inline',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                minWidth: '0px'
              }}
              component="span"
              color="text.primary"
            >
              {formatDate({ isoString: toReviewByDate, locale, isUTC: true })}
            </Typography>
          }
        />
      );
    }

    return null;
  };

  return (
    <>
      <TextField
        name="lastDocumentTitle"
        i18n={{ keyPrefix: keyPrefix + '.FieldTitle' }}
        validationRules={{
          maxLength: 100
        }}
        className="rr-block"
      />
      <Stack direction="row" sx={{ flexWrap: 'wrap' }} gap="0 24px">
        {committeeFieldState !== 'hidden' && (
          <Form.Select
            name="committeeId"
            i18n={{ keyPrefix: keyPrefix + '.FieldCommitteeType' }}
            options={committeesOptions}
            isDisabled={committeeFieldState === 'disabled'}
            sx={{ flex: '1 1 300px' }}
          />
        )}
        {categoryFieldState !== 'hidden' && (
          <Form.Select
            name="category"
            i18n={{ keyPrefix: keyPrefix + '.FieldCategory' }}
            options={categoryFieldOptions}
            isDisabled={categoryFieldState === 'disabled'}
            sx={{ flex: '1 1 300px' }}
          />
        )}
      </Stack>
      <DateTimePicker
        name="lastDocumentActiveSince"
        i18n={{ keyPrefix: keyPrefix + '.FieldDate' }}
        type="date"
        inputProps={{ min: '1900-01-01', max: '2099-12-31' }}
        sx={{
          flex: '1 1 300px',
          '& .MuiInputBase-input': {
            backgroundColor: 'white'
          }
        }}
      />
      <Stack direction="row" sx={{ flexWrap: 'wrap' }} gap="0 24px">
        <Form.Select
          name="reviewInterval"
          i18n={{ keyPrefix: keyPrefix + '.FieldReviewInterval' }}
          options={documentReviewFieldOptions}
          isOptional={true}
          sx={{ flex: '1 1 300px' }}
        />
        {renderToUpdate()}
      </Stack>
    </>
  );
};
