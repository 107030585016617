import ClearIcon from '@mui/icons-material/Clear';
import { Drawer, IconButton, Stack } from '@mui/material';
import { CreateButton } from 'components/CreateButton';
import { Logo } from '../Logo';

import { useComponentVisibility, useLocale, useResourceList, useUserShow } from '@koopajs/react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined';
import NotesIcon from '@mui/icons-material/Notes';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Box } from '@mui/material';
import { useFeatureGate } from '@statsig/react-bindings';
import { useCheckIfHidingNavAndSidebar } from 'components/hooks/useCheckIfHidingNavAndSidebar';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import type { IMeeting, IMinute, IResolution } from 'types';
import { useScreenSize } from 'utils/useScreenSize';
import { NavigationLink } from './NavigationLink';
import { NavigationSection } from './NavigationSection';
import { NavigationResources } from './NavigationResources';
import Divider from '@mui/material/Divider';

export const Navigation: React.FC = () => {
  const navMenu = useComponentVisibility('navMenu');
  const { isScreenAboveLg } = useScreenSize();
  const isMainNavAndSidebarHidden = useCheckIfHidingNavAndSidebar();
  const location = useLocation();
  const { t } = useLocale();
  const { user } = useUserShow();
  const [activePath, setActivePath] = useState<string>('');

  useEffect(() => {
    const stateActivePath = (location.state as { activePath?: string })?.activePath;
    if (stateActivePath) {
      setActivePath(stateActivePath);
    } else {
      setActivePath(location.pathname || '');
    }
  }, [location.pathname, location?.state]);

  const { resources: meetingsPendingReview } = useResourceList<IMeeting>({
    path: '/meetings-to-review',
    searchParams: {
      size: 50
    }
  });

  const userHasMeetingsToReview = meetingsPendingReview.some(
    (m: { reviewers?: { userId: string; latestReview?: unknown }[] }) =>
      m.reviewers?.some((r) => r.userId === user?.id && !r.latestReview)
  );

  const { resources: minutesToSign } = useResourceList<IMinute>({
    path: `/minutes?filters[]=${[
      'NOT _exists_:signaturesCompletedAt',
      `membersLeftToSign.userId:"${user?.id}"`
    ]
      .map((s) => encodeURIComponent(s))
      .join(',')}`
  });

  const { resources: resolutionsToSign } = useResourceList<IResolution>({
    path: `/resolutions?filters[]=${[
      'NOT _exists_:signaturesCompletedAt',
      `membersLeftToSign.userId:"${user?.id}"`
    ]
      .map((s) => encodeURIComponent(s))
      .join(',')}`
  });

  const { resources: resolutionsToReview } = useResourceList<IResolution>({
    path: `/resolutions?filters[]=${[
      'state.keyword:draft',
      `reviewers.userId:"${user?.id}"`,
      `NOT reviews.userId:"${user?.id}"`
    ]
      .map((s) => encodeURIComponent(s))
      .join(',')}`
  });

  const { value: isDocumentRecordsEnabled } = useFeatureGate('document-records-preview');

  return (
    <Drawer
      anchor={'left'}
      open={navMenu.isVisible}
      onClose={navMenu.setHidden}
      PaperProps={{
        sx: {
          width: { xs: '100%', lg: '230px' },
          position: { lg: 'absolute' }
        }
      }}
      sx={{
        width: '230px',
        height: '100%',
        position: { lg: 'relative' },
        display: isMainNavAndSidebarHidden ? 'none' : undefined
      }}
      variant={isScreenAboveLg ? 'permanent' : 'temporary'}
    >
      <Box data-cy="nav-drawer-box">
        <Box
          sx={{
            display: { xs: 'flex', lg: 'none' },
            height: '64px',
            minHeight: '64px',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid lightGrey',
            px: 2
          }}
        >
          <Link to="/" onClick={navMenu.setHidden} style={{ textDecoration: 'none' }}>
            <Logo />
          </Link>
          <IconButton aria-label={t('common:close')} onClick={navMenu.setHidden} data-cy="mobile-nav-close">
            <ClearIcon fontSize="small" />
          </IconButton>
        </Box>

        <nav>
          <NavigationSection>
            <CreateButton sxButton={{ width: '100%', marginTop: 2, textTransform: 'none' }} />
          </NavigationSection>
          <NavigationSection>
            <NavigationLink
              path="/upcoming-meetings"
              icon={EventOutlinedIcon}
              cyTestId="navlinks_menu-item_index-button"
              isActive={activePath.startsWith('/upcoming-meetings')}
              setHidden={navMenu.setHidden}
            >
              {t('common:navigation.upcomingMeetings')}
            </NavigationLink>
          </NavigationSection>
          <NavigationSection title={t('common:navigation.headingTasks')}>
            <NavigationLink
              path="/tasks/to-review"
              cyTestId="navlinks_menu-item_pending-review-button"
              icon={MarkChatReadOutlinedIcon}
              showBadge={userHasMeetingsToReview || resolutionsToReview?.length > 0}
              isActive={activePath.startsWith('/tasks/to-review')}
              setHidden={navMenu.setHidden}
            >
              {t('common:navigation.toAdopt')}
            </NavigationLink>

            <NavigationLink
              path="/tasks/to-sign"
              cyTestId="navlinks_menu-item_to-sign-button"
              icon={DriveFileRenameOutlineOutlinedIcon}
              showBadge={resolutionsToSign?.length > 0 || minutesToSign?.length > 0}
              isActive={activePath.startsWith('/tasks/to-sign')}
              setHidden={navMenu.setHidden}
            >
              {t('common:navigation.toSign')}
            </NavigationLink>
          </NavigationSection>
          <NavigationSection title={t('common:navigation.headingResources')}>
            <NavigationLink
              path="/past-meetings"
              cyTestId="navlinks_menu-item_past-meetings-button"
              icon={CalendarTodayIcon}
              isActive={activePath.startsWith('/past-meetings')}
              setHidden={navMenu.setHidden}
            >
              {t('common:navigation.pastMeetings')}
            </NavigationLink>
            <NavigationLink
              path="/minutes"
              cyTestId="navlinks_menu-item_minutes-button"
              icon={AccessTimeIcon}
              isActive={activePath.startsWith('/minutes')}
              setHidden={navMenu.setHidden}
            >
              {t('common:navigation.minutes')}
            </NavigationLink>
            <NavigationLink
              path="/resolutions"
              cyTestId="navlinks_menu-item_resolutions-button"
              icon={NotesIcon}
              isActive={activePath.startsWith('/resolutions')}
              setHidden={navMenu.setHidden}
            >
              {t('common:navigation.resolutions')}
            </NavigationLink>
            {isDocumentRecordsEnabled && (
              <NavigationLink
                path="/document-records"
                cyTestId="navlinks_menu-item_document-records-button"
                icon={FolderOpenOutlinedIcon}
                isActive={activePath.startsWith('/document-records')}
                setHidden={navMenu.setHidden}
              >
                {t('common:navigation.documents')}
              </NavigationLink>
            )}
            <NavigationLink
              path="/in-camera"
              cyTestId="navlinks_menu-item_in-camera-button"
              icon={LockOutlinedIcon}
              isActive={activePath.startsWith('/in-camera')}
              setHidden={navMenu.setHidden}
            >
              {t('common:navigation.inCamera')}
            </NavigationLink>
            <NavigationLink
              cyTestId="navlinks_menu-item_organization-button"
              icon={PeopleAltOutlinedIcon}
              path="/organization"
              subMenu={[
                {
                  path: '/organization',
                  id: 'organization-groups',
                  text: t('common:navigation.organizationGroups'),
                  isActive:
                    activePath === '/organization' || /^\/organization(\/[a-zA-Z0-9]{26})?$/.test(activePath)
                },
                {
                  path: '/organization/members',
                  id: 'organization-members',
                  text: t('common:navigation.organizationMembers'),
                  isActive: activePath.startsWith('/organization/members')
                }
              ]}
              isActive={activePath.startsWith('/organization')}
              setHidden={navMenu.setHidden}
            >
              {t('common:navigation.organization')}
            </NavigationLink>
            <Divider sx={{ my: 1, mt: 2 }} />
            <NavigationResources />
          </NavigationSection>
        </nav>
      </Box>
    </Drawer>
  );
};
