import { Button, Link } from '@mui/material';
import { useLocale, useResourceCreate } from '@koopajs/react';
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { IResolution } from 'types';

interface IValidateResolutionButton {
  resolutionId: string;
  isRevalidate?: boolean;
}

export const ValidateResolutionButton: React.FC<IValidateResolutionButton> = (props) => {
  const { resolutionId, isRevalidate } = props;

  const { t } = useLocale();

  const history = useHistory();

  const { createResource } = useResourceCreate({
    path: `/resolutions/${resolutionId}/review`,
    customReducer: {
      path: { resourceType: `/resolutions`, resourceId: resolutionId },
      mapping: (prevObj: unknown, newObj: unknown) => {
        const updatedResolution = newObj as IResolution;

        return updatedResolution;
      }
    }
  });

  const handleReviewResolution = useCallback(async () => {
    const res = await createResource({});

    // Temporary slow down the application a little bit to
    // make sure the back-end syncs all the information
    await new Promise((resolve) => setTimeout(resolve, 1000));

    if (res) {
      history.push('/tasks/to-review');
    }
  }, []);

  return (
    <Button
      component={Link}
      variant="outlined"
      onClick={handleReviewResolution}
      data-cy="resolution-review_validate-button"
    >
      {isRevalidate ? t('common:labelRevalidate') : t('common:labelValidate')}
    </Button>
  );
};
