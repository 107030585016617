import { IResolution } from 'types';
import { Typography, Box, Stack, Button, Paper } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { DocumentList } from '../DocumentList';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { CardDetailsList } from 'components/CardDetailsList';
import { SystemStyleObject } from '@mui/system';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';
import { RenderButtonLink } from 'components/RenderButtonLink';
import { formatDate } from 'utils/DateTime/formatDate';

interface IResolutionCard {
  resolution: IResolution;
}

export const ResolutionsCard: React.FC<IResolutionCard> = (props) => {
  const { resolution } = props;

  const { t, locale } = useLocale();
  const history = useHistory();

  const renderViewResolutionButton = (props?: { sx?: SystemStyleObject }): React.ReactElement => {
    const { sx } = props || {};
    return (
      <Button
        component={RouterLink}
        sx={{ ...sx }}
        to={{ pathname: `/resolutions/${resolution.id}`, search: history.location?.search }}
        data-cy="resolutions-card_view-button"
      >
        {t('common:view')}
      </Button>
    );
  };

  const minuteId = resolution.attachedToMeetingTopic?.minuteId;

  return (
    <Paper
      variant="outlined"
      data-cy="resolutions-card"
      data-committee-id={resolution.committeeId}
      sx={{
        padding: '24px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        my: 2
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography
          variant="overline"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            minWidth: 0
          }}
        >
          {resolution.attachedToMeetingTopic
            ? t('Resolutions.resolutionExtract')
            : t('common:writtenResolution')}
          {resolution.resolutionNumber && <Box component="span"> #{resolution.resolutionNumber}</Box>}
        </Typography>

        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          {renderViewResolutionButton({ sx: { whiteSpace: 'nowrap' } })}
        </Box>
      </Box>
      <Typography
        variant="body1"
        data-cy="resolutions-card_resolution-title"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          minWidth: '0px',
          whiteSpace: 'nowrap'
        }}
      >
        {resolution.title
          ? resolution.title
          : resolution.attachedToMeetingTopic?.type
          ? t('common:topicTypes.' + resolution.attachedToMeetingTopic.type)
          : null}
      </Typography>
      {/* Body */}
      {resolution.notes && (
        <Box
          sx={{
            my: '14px',
            whiteSpace: 'pre-wrap',
            display: { xs: 'none', md: 'block' }
          }}
        >
          <Typography variant="body2" component="div" data-cy="resolutions-card_notes">
            <RichTextReadOnly value={resolution.notes} />
          </Typography>
        </Box>
      )}
      <CardDetailsList
        items={[
          // minutes button
          resolution.attachedToMeetingTopic &&
            (minuteId ? (
              <RenderButtonLink
                key="minutesButton"
                to={{
                  pathname: `/resolutions/minutes/${minuteId}`,
                  search: history.location?.search
                }}
                label={
                  <Typography component="span" variant="body2">
                    {t('common:boardMinutes')}
                  </Typography>
                }
                icon={<AccessTimeIcon />}
                variant="text"
              />
            ) : (
              <RenderButtonLink
                to={''}
                label={
                  <Typography component="span" variant="body2" color="text.secondary">
                    {t('Resolutions.ResolutionCard.viewMinutesLabelDisabled')}
                  </Typography>
                }
                icon={<AccessTimeIcon />}
                disabled
                variant="text"
              />
            )),
          // resolution outcome
          resolution.resolutionOutcome?.outcome && (
            <Typography key="adoptedAt" variant="body2" color="text.secondary">
              {t(`common:TopicModel.resolutionOutcome.outcome.${resolution.resolutionOutcome.outcome}`)}

              {resolution.resolutionOutcome.recordedAt && (
                <>
                  <span> {t('common:on')} </span>
                  {formatDate({ isoString: resolution.resolutionOutcome.recordedAt, locale })}
                </>
              )}
            </Typography>
          )
        ]}
      />
      {/* Documents */}
      {resolution.documentsIds && resolution.documentsIds.length > 0 && (
        <DocumentList
          key={resolution.id}
          path={`/resolutions/${resolution.id}/documents`}
          isAccordion={true}
          accordionOptions={{ documentsVisible: 0 }}
          showDivider
        />
      )}
      {<Box sx={{ display: { md: 'none' } }}>{renderViewResolutionButton({ sx: { p: 0, mt: 3 } })}</Box>}
    </Paper>
  );
};
