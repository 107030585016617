import React from 'react';
import { useLocale } from '@koopajs/react';
import pendingReviewImageFR from '../../assets/emptyStates/pending-review_FR.png';
import pendingReviewImageEN from '../../assets/emptyStates/pending-review_EN.png';
import { EmptyStateTemplate } from './EmptyStateTemplate';
import { useCommitteePermissions } from 'data/permissions/useCommitteePermissions';

interface IEmptyStateProps {
  windowTitle?: string;
  button?: React.ReactNode;
}

export const EmptyStateToReview: React.FC<IEmptyStateProps> = ({ windowTitle, button }) => {
  const { t, locale } = useLocale();
  const keyPrefix = 'ToReview.EmptyState';

  const { isUserNoteTaker, isUserResolutionCreator } = useCommitteePermissions();

  const tipKeys = [];
  if (isUserNoteTaker) tipKeys.push('tipWriteMinutes');
  if (isUserResolutionCreator) tipKeys.push('tipCreateResolution');

  return (
    <EmptyStateTemplate
      windowTitle={windowTitle}
      pageTitle={t('ToReview.title')}
      emptyStateTitle={t(`${keyPrefix}.title`)}
      emptyStateDescription={t(`${keyPrefix}.description`)}
      image={locale.includes('en') ? pendingReviewImageEN : pendingReviewImageFR}
      button={button}
      tipKeys={tipKeys}
      keyPrefix={keyPrefix}
      t={t}
    />
  );
};
