import { useLocale, useResourceList, useUserShow } from '@koopajs/react';
import { Typography, Stack } from '@mui/material';
import { IResolution, ICommittee, IMeeting } from 'types';
import { MeetingPendingReviewCard } from 'components/Dashboard/MeetingPendingReviewCard';
import { ResolutionPendingReviewCard } from 'components/Dashboard/ResolutionPendingReviewCard';
import { Helmet } from 'react-helmet';
import { EmptyStateToReview } from 'components/EmptyStates/EmptyStateToReview';
import { PageContainer } from 'components/temp/PageContainer';
import { Loading } from '@koopajs/mui';

export default function Page(): JSX.Element {
  const { user } = useUserShow();
  const { t } = useLocale();

  const { resources: meetingsPendingReview, state: meetingsState } = useResourceList<IMeeting>({
    path: '/meetings-to-review',
    searchParams: {
      size: 50
    }
  });

  const { resources: resolutionsPendingReview, state: resolutionsState } = useResourceList<IResolution>({
    path: '/resolutions/to-review',
    searchParams: {
      size: 50
    }
  });

  const { resources: committees } = useResourceList<ICommittee>({ path: '/committees', useCache: true });

  const committeeObj: { [k: string]: string } = {};
  committees.map((committee) => {
    committeeObj[committee.id] = committee.name;
  });

  if (meetingsState === 'loading' || resolutionsState === 'loading') {
    return (
      <Stack sx={{ position: 'relative', width: '100%', height: '100%' }}>
        <Loading sx={{ backgroundColor: 'transparent', position: 'absolute' }} />
      </Stack>
    );
  }

  if (meetingsPendingReview.length === 0 && resolutionsPendingReview.length === 0)
    return <EmptyStateToReview />;

  return (
    <>
      <Helmet>
        <title>{`${t('common:navigation.toAdopt')} - Panorama`}</title>
      </Helmet>

      <PageContainer sxChildren={{ px: { xs: '24px', lg: '48px' }, py: '24px' }} className="rr-block">
        <Typography variant="h1" sx={{ fontSize: '24px' }}>
          {t('Dashboard.titleToReview')}
        </Typography>
        <Stack gap="16px" sx={{ marginTop: '24px' }}>
          {resolutionsPendingReview?.map((resolution) => (
            <ResolutionPendingReviewCard key={resolution.id} resolution={resolution} user={user} />
          ))}
          {meetingsPendingReview?.map((meeting: IMeeting) => (
            <MeetingPendingReviewCard key={meeting.id} meeting={meeting} user={user} />
          ))}
        </Stack>
      </PageContainer>
    </>
  );
}
