import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useResourceList, useLocale } from '@koopajs/react';
import { AccessValidator } from '@koopajs/mui';
import {
  Paper,
  IconButton,
  Stack,
  Box,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { InvitationRows } from './InvitationsRows';
import { IUserPermission, IWorkspaceInvitation } from '../../../types';
import { DeleteUserPermission } from 'components/Modals/DeleteUserPermission';
import { alphabeticalUserSortFunction } from 'utils/alphabeticalUserSortFunction';
import { Api, TrackEvent } from '@koopajs/app';
import { useAppSnackbar } from 'components/hooks/useAppSnackbar';

const PermissionOptions: { [k: string]: string } = {
  owner: 'owner',
  admin: 'admin',
  member: 'team-member',
  basic: 'external'
};

export const SettingsPermissions: React.FC = () => {
  const { t } = useLocale();
  const keyPrefix = 'Settings.UsersAndPermissions';
  const { triggerSnackbar } = useAppSnackbar();

  const invitations = useResourceList<IWorkspaceInvitation>({
    path: '/invitations',
    searchParams: { size: 50 }
  });
  const users = useResourceList<IUserPermission>({
    path: '/users',
    searchParams: { size: 50 }
  });

  const [userIdToDelete, setUserIdToDelete] = useState('');

  const [openRowId, setOpenRowId] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  //edit permissions

  const handleOpenUserMenu = useCallback(
    (userId: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpenRowId(userId);
    },
    []
  );

  const handleCloseUserMenu = useCallback(() => {
    setAnchorEl(null);
    setOpenRowId(null);
  }, []);

  const handleOpenDeleteModal = useCallback((id: string) => {
    return () => {
      setUserIdToDelete(id);
      handleCloseUserMenu();
    };
  }, []);
  const handleCloseDeleteModal = useCallback(() => {
    setUserIdToDelete('');
  }, []);

  const handleResendTemporaryPassword = useCallback((userId: string) => {
    return async () => {
      let res = null;
      try {
        res = await Api.client.post(`/resend-temporary-password/${userId}`);
      } catch (e) {
        console.error('error', e);
      }

      handleCloseUserMenu();

      if (res) {
        triggerSnackbar({
          snackbarText: t(keyPrefix + '.snackbarTemporaryPasswordSentSuccess'),
          variant: 'success'
        });

        TrackEvent('temporary-password-sent');
      } else {
        triggerSnackbar({
          snackbarText: t(keyPrefix + '.snackbarTemporaryPasswordSentFail'),
          variant: 'error'
        });

        TrackEvent('temporary-password-send-fail');
      }

      return res;
    };
  }, []);

  return (
    <>
      <DeleteUserPermission
        userId={userIdToDelete}
        isOpen={Boolean(userIdToDelete)}
        onClose={handleCloseDeleteModal}
      />
      <Helmet>
        <title>{`${t('common:settings')} - Panorama`}</title>
      </Helmet>
      <Stack direction="row" justifyContent="space-between" sx={{ mx: 1, my: 2 }}>
        <Typography variant="body1">{`${t(keyPrefix + '.users')}`}</Typography>
        {/* <Button startIcon={<AddIcon />} size="small" variant="outlined" onClick={showInvitationDialog}>
          {`${t(keyPrefix + '.labelInviteUser')}`}
        </Button> */}
      </Stack>
      <Paper variant="outlined">
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{`${t(keyPrefix + '.name')}`}</TableCell>
                <TableCell align="right" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                  {`${t(keyPrefix + '.status')}`}
                </TableCell>
                <TableCell align="right">{`${t(keyPrefix + '.permission')}`}</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {users.resources.sort(alphabeticalUserSortFunction).map((user) => (
                <>
                  <TableRow key={user.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Avatar src={user.profilePicture as string} />
                        <Stack direction="column">
                          <Box>{user.username as string}</Box>
                          <Box>{user.email as string}</Box>
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell align="right" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                      {user.hasUserLoggedIn ? t(keyPrefix + '.active') : t(keyPrefix + '.pending')}
                    </TableCell>
                    {/* //TODO: in future we can have more than 1 permission */}
                    <TableCell align="right">
                      {user?.permissions &&
                        user?.permissions?.map((p: string) => t(keyPrefix + `.${PermissionOptions[p]}`))}
                    </TableCell>
                    <TableCell align="right">
                      <AccessValidator permissions="permissions:update">
                        {(props) => (
                          <>
                            {props.hasPermissions && (
                              <>
                                <IconButton onClick={handleOpenUserMenu(user.id)}>
                                  <MoreVertIcon />
                                </IconButton>
                                <Menu
                                  anchorEl={anchorEl}
                                  open={openRowId === user.id}
                                  onClose={handleCloseUserMenu}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                  }}
                                >
                                  <MenuItem
                                    onClick={handleOpenDeleteModal(user.id)}
                                    sx={{ color: 'error.main' }}
                                  >
                                    {t('common:labelDelete')}
                                  </MenuItem>
                                  {!user.hasUserLoggedIn && user.id && (
                                    <MenuItem onClick={handleResendTemporaryPassword(user.id)}>
                                      {t(keyPrefix + '.labelResendTemporaryPassword')}
                                    </MenuItem>
                                  )}
                                </Menu>
                              </>
                            )}
                          </>
                        )}
                      </AccessValidator>
                    </TableCell>
                  </TableRow>
                </>
              ))}

              {invitations.resources && invitations.resources.length > 0 && (
                <>
                  {invitations.resources.map((invitation) => {
                    if (!invitation.acceptedAt) {
                      return <InvitationRows invitation={invitation} key={invitation.id} />;
                    }
                  })}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
