import { useRef, useCallback } from 'react';
import { IResolution } from 'types';
import { Box } from '@mui/material';
import { Dialog } from '@koopajs/mui';
import { RenderPrintButton } from '../../RenderPrintButton';
import { ViewResolutionPrintVersion } from '../ViewResolutionPrintVersion';
import { useHistory } from 'react-router-dom';
import { ResolutionContent } from './ResolutionContent';

interface IViewResolutionProps {
  resolution?: IResolution;
  isResolutionProcessing?: boolean;
}

export const ViewResolution: React.FC<IViewResolutionProps> = (props) => {
  const { resolution, isResolutionProcessing } = props;
  const keyPrefix = 'Resolutions.DialogView';
  const history = useHistory();
  const printComponentRef = useRef(null);

  const handleOnClose = useCallback(() => {
    history.push({ pathname: `/resolutions`, search: history.location?.search });
  }, []);

  if (!resolution) return null;

  return (
    <Dialog.View
      isOpen={Boolean(resolution)}
      onClose={handleOnClose}
      isCloseVisible={true}
      i18n={{ keyPrefix: keyPrefix }}
      footerActions={
        <RenderPrintButton keyPrefix={keyPrefix} componentRef={printComponentRef} size="small" />
      }
      dialogProps={{ className: 'rr-block', PaperProps: { 'data-cy': 'view-resolution-dialog' } }}
    >
      <Box sx={{ display: 'none' }}>
        <ViewResolutionPrintVersion resolution={resolution} ref={printComponentRef} />
      </Box>

      <ResolutionContent resolution={resolution} isProcessing={isResolutionProcessing} />
    </Dialog.View>
  );
};
