import React, { useCallback, useRef, useEffect } from 'react';
import { Dialog } from '@koopajs/mui';
import { useHistory } from 'react-router-dom';
import { useResourceShow } from '@koopajs/react';
import { IMeeting } from 'types';
import { RenderPrintButton } from 'components/RenderPrintButton';
import { useSnackbar } from 'notistack';
import { IconButton, Box } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { extractErrorMessage } from 'utils/extractErrorMessage';
import { PastMeetingContent } from './PastMeetingContent';
import { MeetingPrintVersion } from 'components/Meetings/MeetingPrintVersion';
import { useMeetingStatus } from 'components/hooks/useMeetingStatus';

interface IViewPastMeeting {
  pastMeetingId?: string;
  pathOnClose: string;
  routerStateOnClose?: { activePath: string };
}

export const ViewPastMeeting: React.FC<IViewPastMeeting> = (props) => {
  const { pastMeetingId, pathOnClose, routerStateOnClose } = props;

  const history = useHistory();

  const {
    resource: pastMeeting,
    isProcessing,
    errorMessage
  } = useResourceShow<IMeeting>({
    path: '/meetings',
    id: pastMeetingId
  });
  const { meetingDuration } = useMeetingStatus({ meeting: pastMeeting });

  const keyPrefix = 'Dialogs.PastMeetingDialog';
  const extractedErrorMessage = extractErrorMessage(errorMessage);

  const handleCloseDialog = useCallback(() => {
    history.push({ pathname: pathOnClose, search: history.location?.search }, routerStateOnClose);
  }, [history, pathOnClose]);

  const componentRef = useRef(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (errorMessage === "Error 403: You don't have access to this meeting") {
      enqueueSnackbar(extractedErrorMessage, {
        variant: 'error',
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });
      history.push('/past-meetings');
    }
  }, [errorMessage, enqueueSnackbar, closeSnackbar, pastMeetingId]);

  return (
    <Dialog.View
      isOpen={Boolean(pastMeetingId && pastMeeting)}
      onClose={handleCloseDialog}
      isCloseVisible={true}
      i18n={{ keyPrefix: keyPrefix + '.DialogView' }}
      footerActions={
        <RenderPrintButton
          keyPrefix={keyPrefix + '.DialogView'}
          componentRef={componentRef}
          documentTitle={pastMeeting?.title}
          size="small"
        />
      }
      dialogProps={{ className: 'rr-block', PaperProps: { 'data-cy': 'view-past-meeting-dialog' } }}
    >
      <Box sx={{ display: 'none' }}>
        <MeetingPrintVersion
          meeting={pastMeeting}
          showApprovedPastMeetingsToApprove
          ref={componentRef}
          meetingDuration={meetingDuration}
        />
      </Box>
      <PastMeetingContent pastMeeting={pastMeeting} isProcessing={isProcessing} />
    </Dialog.View>
  );
};
