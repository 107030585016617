import { useLocale, useResourceShow } from '@koopajs/react';
import { Stack, Typography, Skeleton, Box } from '@mui/material';
import { CheckCircle, Edit, AccessTime as AccessTimeIcon } from '@mui/icons-material';
import { IUseLiveMeetingResponse } from '../../useLiveMeeting';
import { IMeeting } from 'types';
import { Link as RouterLink } from 'react-router-dom';
import { isCustomResNumFormat } from '../../../../../utils/isCustomResNumFormat';
import { WarningAmber as WarningAmberIcon } from '@mui/icons-material';
import { useCallback } from 'react';
import { RenderButtonLink } from 'components/RenderButtonLink';
import { formatDate } from 'utils/DateTime/formatDate';
import { useCommitteeName } from 'components/hooks/useCommitteeName';

interface IApproveMinutesItem {
  liveMeeting: IUseLiveMeetingResponse;
  minutesId: string;
  isSidebarSection?: boolean;
}

export const ApproveMinutesItem: React.FC<IApproveMinutesItem> = (props) => {
  const { minutesId, liveMeeting, isSidebarSection } = props;

  const { currentTopicId, meetingId, topic } = liveMeeting;

  const { t, locale } = useLocale();
  const keyPrefix = 'MeetingStateInProgress.PastMinutesApproval';

  const { resource: minutes } = useResourceShow<IMeeting>({ path: '/meetings', id: minutesId });

  const committeeType = useCommitteeName(minutes?.committeeId as string);

  const countMissingResNum = useCallback((): number => {
    return minutes?.topics?.filter((t) => !t.resolutionNumber && t.isResolution)?.length || 0;
  }, [JSON.stringify(minutes?.topics)]);

  const isCustomResNum = isCustomResNumFormat(minutes?.committeeId as string);

  const isApproving = topic?.pastMeetingsToApprove?.some((obj) => {
    return obj.meetingId === minutesId && obj.isApproved;
  });

  if (minutes?.boardApprovedAt) {
    return null;
  }

  if (isSidebarSection) {
    return minutes ? (
      <Box>
        <RenderButtonLink
          to={`/meetings/${meetingId}/topics/${currentTopicId}/past-minutes/${minutesId}`}
          label={
            <Typography variant="body1">
              {formatDate({
                isoString: minutes?.startDateTime as string,
                locale,
                format: 'DATE_FULL'
              })}
              {' | '}
              {committeeType}
            </Typography>
          }
          icon={<AccessTimeIcon />}
          size={isSidebarSection ? 'small' : 'large'}
          variant="text"
        />
      </Box>
    ) : (
      <Skeleton variant="rounded" width="100%" height={40} />
    );
  }

  return (
    <>
      {minutes ? (
        <Stack
          component={RouterLink}
          to={`/meetings/${meetingId}/topics/${currentTopicId}/past-minutes/${minutesId}`}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            color: 'inherit',
            textDecoration: 'inherit',
            p: '14px 24px',
            border: '1px solid #0000001f',
            borderRadius: '4px'
          }}
          data-cy="meeting-in-progress_past-minutes-approval-item"
          data-minute-title={minutes?.title}
        >
          <Stack direction={{ lg: 'row' }}>
            <Box>
              {formatDate({
                isoString: minutes?.startDateTime as string,
                locale,
                format: 'DATE_FULL'
              })}
              {' | '}
              {committeeType}
            </Box>
            {!isApproving && isCustomResNum && countMissingResNum() > 0 && (
              <Typography
                variant="body2"
                sx={{ opacity: '0.6', display: 'flex', ml: { lg: 3 }, mt: { xs: 1, lg: 0 } }}
              >
                <WarningAmberIcon fontSize="small" sx={{ mr: 1 }} />
                {t(keyPrefix + '.missingResolutionNumber', { count: countMissingResNum() })}
              </Typography>
            )}
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1.5}>
            {isApproving ? (
              <>
                <CheckCircle color="success" />
                <Typography>{t('common:approved')}</Typography>
              </>
            ) : (
              <Edit sx={{ opacity: 0.54 }} />
            )}
          </Stack>
        </Stack>
      ) : (
        <Skeleton variant="rounded" width="100%" height={40} />
      )}
    </>
  );
};
