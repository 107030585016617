import { useState, useCallback } from 'react';
import {
  Button,
  Stack,
  Typography,
  IconButton,
  Box,
  Tooltip,
  Link,
  Switch,
  FormControlLabel
} from '@mui/material';
import {
  Edit as EditIcon,
  WarningAmber as WarningAmberIcon,
  DeleteOutline as DeleteOutlineIcon,
  RestoreFromTrashOutlined as RestoreOutlinedIcon
} from '@mui/icons-material';
import { useLocale, useResourceUpdate, useComponentVisibility } from '@koopajs/react';
import { NominationsField } from './NominationsField';
import { ResolutionOutcomeText } from 'components/ResolutionOutcome/ResolutionOutcomeText';
import { ResolutionOutcomeVotes } from 'components/ResolutionOutcome/ResolutionOutcomeVotes';
import { ITopicCardProps, ICommittee, ITopic } from 'types';
import { useTopic } from 'components/hooks/useTopic';
import { formatTime } from 'utils/DateTime/formatTime';
import { FormControllerTemp } from 'components/temp/FormControllerTemp';
import { DocumentList } from 'components/DocumentList';
import RichTextMultiLine from 'components/temp/RichTextMultiLine';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';
import { TextField } from 'components/temp/TextFieldTemp';
import { DateTime } from 'luxon';
import { checkIsGenericTopicType, AllTopicType, checkIsDecisionTopicType } from 'utils/topicTypeArrays';
import { transformMinutesTimestamp } from 'utils/transformMinutesTimestamp';
import _ from 'lodash';
import { DateTimePickerWithButton } from 'components/temp/DateTimePickerWithButton';
import { ErrorMessage } from '@koopajs/mui';
import { theme } from 'components/Core/theme';
import { useFeatureGate } from '@statsig/react-bindings';
import { EditVoteOutcome } from './EditVoteOutcome';
import { IUserPublicProfile } from '@koopajs/app';
import { RecordVoteDialogOptions } from 'components/Dialogs/RecordVote/RecordVoteDialogOptions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { formatDate } from 'utils/DateTime/formatDate';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { ApprovedMinutesList } from 'components/Minutes/ApprovedMinutesList';
import { TopicCardTitleField } from './TitleField';

export interface ITopicCardMinutesProps extends ITopicCardProps {
  isBeingReviewedInsideMeeting?: boolean;
  isBrandNewTopic?: boolean;
  users?: IUserPublicProfile[];
  committee?: ICommittee;
  participants?: IUserPublicProfile[];
  deletedAt?: string;
}

interface IFormData {
  visibleBy: { [k: string]: string };
  pastMeetingsToApprove: { [k: string]: string };
  notes: string;
  minutesStartedAtOverwrittenTime: string;
  minutesEndedAtOverwrittenTime: string;
  quorumReachedAtOverwrittenTime: string;
  quorumReachedAtOverwritten: string;
  type: AllTopicType;
  resolutionOutcome?: ITopic['resolutionOutcome'];
}

// component used on the review meeting page - edit
export const TopicCardMinutesEditable: React.FC<ITopicCardMinutesProps> = (props) => {
  const {
    topic,
    order,
    isEditable,
    handleSetTopicEditable,
    userHasEditAccess,
    onSubmitSuccess,
    meeting,

    sx,
    isBrandNewTopic,
    users,
    committee,
    participants
  } = props;

  const { title, assignedTo, durationSeconds, type, notes, visibleBy, resolutionNumber } = topic;
  const { t, locale } = useLocale();
  const keyPrefix = 'Components.TopicCard';

  const isCustomResNum = committee?.resolutionNumberFormat === 'custom';
  const committeeType = useCommitteeName(meeting?.committeeId || '');

  const isGenericTopicType = checkIsGenericTopicType(topic.type);
  const isDecisionTopicType = checkIsDecisionTopicType(topic.type);
  const isResolutionTopicType = topic.type === 'resolution';

  const isResolutionWithOutcome =
    isDecisionTopicType && topic?.resolutionOutcome?.outcome && topic?.isResolution;

  const canUserGetResolutionExtract =
    !isEditable && isResolutionWithOutcome && !topic.lockedAt && userHasEditAccess && isResolutionTopicType;

  const { value: isFullTopicEditEnabled } = useFeatureGate('minutes_review_topics');

  // PAST MEETING, RESOLUTION PREVIEW, RESOLUTION EXTRACT, SEND RESOLUTION EXTRACT DIALOGS
  const pastMeetingDialog = useComponentVisibility('pastMeetingDialog');
  const resolutionExtractDialog = useComponentVisibility('resolutionDialog');
  const resolutionExtractPreviewDialog = useComponentVisibility('resolutionPreviewDialog');
  const sendResolutionExtractDialog = useComponentVisibility('sendResolutionExtract');

  const handleOpenPastMeetingDialog = (): void => {
    pastMeetingDialog.setVisibleWithContext({
      pastMeetingId: topic.meetingId
    });
  };

  const handleOpenResolutionExtract = (): void => {
    resolutionExtractDialog.setVisibleWithContext({
      resolutionId: topic.resolutionId
    });
  };

  const handleOpenResolutionExtractPreview = (): void => {
    resolutionExtractPreviewDialog.setVisibleWithContext({
      resolution: topic,
      meeting
    });
  };

  const handleSendResolutionExtractDialog = useCallback(() => {
    sendResolutionExtractDialog.setVisibleWithContext({
      topic,
      meeting,
      defaultValues: {
        subject: t('Dialogs.DialogSendResolutionExtract.DialogFormSend.subjectPrefix', {
          title: topic?.title || t(`common:topicTypes.${topic.type}`)
        })
      },
      isCustomResNum
    });
  }, [JSON.stringify(topic), isCustomResNum, meeting?.id]);

  const { onSubmit, updateTopic } = useTopic({
    topicId: topic.id,
    meetingId: topic.meetingId,
    isMeetingStarted: true
  });

  const { updateResource: updateMeeting, errorMessage } = useResourceUpdate({
    path: '/meetings',
    id: meeting?.id as string
  });

  // this is for a decision topic that is brand new only
  const [isUnanimousSelected, setIsUnanimousSelected] = useState(true);

  const [isDecisionTopicSwitchChecked, setIsDecisionTopicSwitchChecked] = useState(false);
  const handleIsDecisionTopicSwitch = useCallback((event: React.SyntheticEvent, checked: boolean): void => {
    setIsDecisionTopicSwitchChecked(checked);
  }, []);

  const handleDeleteTopic = useCallback(async () => {
    const res = await updateTopic({ deletedAt: new Date().toISOString() });

    if (!res) return;

    if (onSubmitSuccess) {
      onSubmitSuccess();
    }
  }, [updateTopic, onSubmitSuccess]);

  const handleRestoreTopic = useCallback(async () => {
    const res = await updateTopic({ deletedAt: '' });

    if (!res) return;

    if (onSubmitSuccess) {
      onSubmitSuccess();
    }
  }, [updateTopic, onSubmitSuccess]);

  const handleOnSubmit = useCallback(
    async (formData: object) => {
      const formDataTyped = formData as IFormData;

      // switch to resolution if user checked the switch and the topic is a brand new generic topic
      const isGenericTopicType = checkIsGenericTopicType(formDataTyped.type || topic.type);
      if (isDecisionTopicSwitchChecked && isBrandNewTopic && isGenericTopicType) {
        formDataTyped.type = 'resolution';
        if (formDataTyped.resolutionOutcome) {
          // handle ask for vote switch
          if (isUnanimousSelected) {
            formDataTyped.resolutionOutcome.isUnanimous = true;
            delete formDataTyped.resolutionOutcome.membersFor;
            delete formDataTyped.resolutionOutcome.membersAgainst;
            delete formDataTyped.resolutionOutcome.membersAbstained;
          } else {
            formDataTyped.resolutionOutcome.isUnanimous = false;
          }

          // set recordedAt to the end of the meeting
          if (meeting?.minutesEndedAt) formDataTyped.resolutionOutcome.recordedAt = meeting.minutesEndedAt;
        }
      }

      const isDecisionTopicType = checkIsDecisionTopicType(formDataTyped.type || topic.type);

      const topicUpdates = _.omit(formDataTyped, [
        'minutesStartedAtOverwrittenTime',
        'minutesEndedAtOverwrittenTime',
        // removes resolution attributes if user switched away from resolution when creating a brand new generic topic
        ...(!isDecisionTopicType ? ['isResolution', 'resolutionOutcome', 'resolutionNumber'] : [])
      ]);

      let updateMeetingResponse = true;
      if (topic.type === 'quorum' || topic.type === 'adjournment') {
        const meetingUpdates = transformMinutesTimestamp({
          minutesStartedAtOverwrittenTime: formDataTyped.minutesStartedAtOverwrittenTime,
          minutesStartedAt: meeting?.minutesStartedAt as string,
          minutesEndedAtOverwrittenTime: formDataTyped.minutesEndedAtOverwrittenTime,
          minutesEndedAt: meeting?.minutesEndedAt as string
        });
        updateMeetingResponse = await updateMeeting(meetingUpdates);
      }
      const updateTopicResponse = await onSubmit(topicUpdates);

      if (updateMeetingResponse && updateTopicResponse && onSubmitSuccess) {
        onSubmitSuccess(); // close edit mode
      }

      return Boolean(updateTopicResponse && updateMeetingResponse);
    },
    [
      updateMeeting,
      onSubmit,
      meeting?.id,
      onSubmitSuccess,
      topic.id,
      topic.type,
      isBrandNewTopic,
      isDecisionTopicSwitchChecked,
      isUnanimousSelected
    ]
  );

  const { membersAgainst, membersAbstained, membersFor } = topic?.resolutionOutcome || {};
  const showResolutionOutcomeVotes = Boolean(
    membersFor?.length || membersAbstained?.length || membersAgainst?.length
  );

  const visibleByObject = visibleBy?.reduce((acc: { [k: string]: boolean }, id, i) => {
    acc[id] = true;
    return acc;
  }, {});

  const defaultValues = {
    title: title ? title : isGenericTopicType ? undefined : t('common:topicTypes.' + topic.type),
    resolutionNumber,
    assignedTo,
    durationSeconds,
    type,
    notes,
    visibleBy: visibleByObject,
    ...(type === 'quorum'
      ? {
          minutesStartedAtOverwrittenTime: meeting?.minutesStartedAt
            ? DateTime.fromISO(meeting?.minutesStartedAt).toFormat('HH:mm')
            : '',
          quorumReachedAtOverwrittenTime: topic.quorumReachedAt
            ? DateTime.fromISO(topic.quorumReachedAt).toFormat('HH:mm')
            : ''
        }
      : {}),
    ...(type === 'adjournment'
      ? {
          minutesEndedAtOverwrittenTime: meeting?.minutesEndedAt
            ? DateTime.fromISO(meeting?.minutesEndedAt).toFormat('HH:mm')
            : ''
        }
      : {})
  };

  let adjournmentAt = meeting?.minutesEndedAt;
  if (topic.type === 'adjournment' && topic.version === 1) {
    if (topic.resolutionOutcome?.recordedAt && topic.resolutionOutcome?.outcome === 'approved') {
      adjournmentAt = topic.resolutionOutcome?.recordedAt;
    } else {
      adjournmentAt = '';
    }
  }
  const notDeletableTopicTypes: AllTopicType[] = [
    'quorum',
    'nominations',
    'agendaApproval',
    'pastMinutesApproval',
    'inCamera'
  ];
  const isDeletableTopic = isFullTopicEditEnabled && !notDeletableTopicTypes.includes(topic.type);

  const renderEditSaveDeleteButton = () => {
    if (!userHasEditAccess || topic.type === 'inCamera' || topic.lockedAt) return null;

    return (
      <Stack direction="row">
        <>
          {isEditable ? (
            <>
              {isDeletableTopic && (
                <Tooltip title={t('common:labelDelete')}>
                  <IconButton onClick={handleDeleteTopic} data-cy="topic-card-minutes_delete-button">
                    <DeleteOutlineIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Button
                type="submit"
                variant="contained"
                sx={{ ml: 2 }}
                data-cy="topic-card-minutes_save-button"
              >
                {t('common:labelSave')}
              </Button>
            </>
          ) : (
            <>
              <Tooltip title={t('common:labelEdit')}>
                <IconButton
                  onClick={handleSetTopicEditable && handleSetTopicEditable(topic.id)}
                  data-cy="topic-card-minutes_edit-button"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </>
      </Stack>
    );
  };

  if (topic.deletedAt) {
    return (
      <Box
        sx={{ minWidth: 0, ...sx }}
        data-cy="topic-card-minutes"
        className={`topic-card-minutes_${topic.type}`}
      >
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', color: 'action.disabled', height: '100%' }}
        >
          <Stack justifyContent={isResolutionWithOutcome ? undefined : 'center'} sx={{ minWidth: 0 }}>
            {isResolutionWithOutcome && (
              <Typography
                variant="overline"
                data-cy="topic-card-minutes_resolution-number"
                sx={{ display: 'block' }}
              >
                {t('common:resolution_one')}
                {topic.resolutionNumber ? ` #${topic.resolutionNumber}` : ''}
              </Typography>
            )}
            <Typography
              sx={{
                width: '100%',
                fontWeight: 'bold',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
              data-cy="topic-card-minutes_title"
            >
              <>
                {order}. {topic.title ? topic.title : t(`common:topicTypes.${topic.type}`)}
              </>
            </Typography>
          </Stack>

          <Box>
            <Button onClick={handleRestoreTopic} data-cy="topic-card-minutes_restore-button" size="small">
              <RestoreOutlinedIcon sx={{ mr: 1 }} />
              {t('common:labelRestore')}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{ minWidth: 0, ...sx }}
        data-cy="topic-card-minutes"
        className={`topic-card-minutes_${topic.type}${isEditable ? ' is-editable' : ''}`}
      >
        <FormControllerTemp
          onSubmit={handleOnSubmit}
          defaultValues={defaultValues}
          onSubmitSuccessResetType="FORM_PAYLOAD"
          sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <ErrorMessage error={errorMessage} />

          {/* RESOLUTION - Not displayed on print view */}
          {isResolutionWithOutcome && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: { xs: 'column-reverse', sm: 'row' }
              }}
            >
              <Box sx={{ alignSelf: 'flex-start' }}>
                <Typography
                  variant="overline"
                  data-cy="topic-card-minutes_resolution-number"
                  sx={{ display: 'block' }}
                >
                  {t('common:resolution_one')}
                  {topic.resolutionNumber ? ` #${topic.resolutionNumber}` : ''}
                </Typography>
                {/* ADDING CUSTOM RESOLUTION NUMBER */}
                {isCustomResNum &&
                  userHasEditAccess &&
                  !topic.lockedAt &&
                  (isEditable ? (
                    <TextField
                      i18n={{ keyPrefix: keyPrefix + '.ResolutionNumberField' }}
                      name="resolutionNumber"
                      validationRules={{ maxLength: 250 }}
                      isOptional
                    />
                  ) : (
                    !topic.resolutionNumber && (
                      <Link
                        sx={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                          color: 'link.main',
                          textTransform: 'none',
                          fontSize: '1rem',
                          fontWeight: '400',
                          textDecoration: 'none',
                          mb: 1
                        }}
                        onClick={handleSetTopicEditable && handleSetTopicEditable(topic.id)}
                        component="button"
                        data-cy="topic-card-minutes_add-resolution-number-button"
                      >
                        <WarningAmberIcon sx={{ mr: 1 }} />
                        {t(keyPrefix + '.customResolutionNumberWarning')}
                      </Link>
                    )
                  ))}
              </Box>
              {/* EDIT, DELETE, EXTRACT, PREVIEW BUTTONS - resolution topic */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  alignSelf: { xs: 'flex-end' },
                  flexWrap: 'wrap',
                  justifyContent: 'flex-end'
                }}
              >
                {!isEditable && (
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {canUserGetResolutionExtract && (
                      <Button
                        size="small"
                        onClick={handleSendResolutionExtractDialog}
                        data-cy="topic-card-minutes_get-extract-button"
                      >
                        {t(keyPrefix + '.getTheResolutionExtractLabel')}
                      </Button>
                    )}

                    {isResolutionTopicType && (
                      <IconButton
                        size="small"
                        data-cy=""
                        aria-label={t(keyPrefix + '.viewResolutionExtractPreviewLabel')}
                        onClick={
                          topic.lockedAt && topic.resolutionId
                            ? handleOpenResolutionExtract
                            : handleOpenResolutionExtractPreview
                        }
                      >
                        <VisibilityIcon />
                      </IconButton>
                    )}
                  </Box>
                )}

                {renderEditSaveDeleteButton()}
              </Box>
            </Box>
          )}
          <Stack direction="column" sx={{ flex: 1 }}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              sx={{ flex: 1 }}
            >
              <Stack flexGrow={1} sx={{ minWidth: 0 }}>
                {/* ORDER & TITLE */}
                {isEditable ? (
                  <TopicCardTitleField
                    sx={{ width: '100%', mt: { md: 0 } }}
                    order={order}
                    isOptional={!isGenericTopicType}
                    showResetButton={!isGenericTopicType}
                    topicType={topic.type}
                  />
                ) : (
                  <Typography
                    sx={{
                      width: '100%',
                      fontWeight: 'bold',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                    data-cy="topic-card-minutes_title"
                  >
                    <>
                      {order}. {topic.title ? topic.title : t(`common:topicTypes.${topic.type}`)}
                    </>
                  </Typography>
                )}
              </Stack>

              {/* resolution buttons are above in code */}
              {!topic.isResolution && (
                <Box sx={{ alignSelf: 'flex-start' }}>{renderEditSaveDeleteButton()}</Box>
              )}
            </Stack>
            {/* QUORUM  */}
            {topic.type === 'quorum' && (
              <Box>
                {/* Edit minutesStartedAt */}
                {meeting?.minutesStartedAt && isEditable ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'baseline',
                      gap: { sm: 3 },
                      flexDirection: { xs: 'column', sm: 'row' },
                      mb: { xs: 1, sm: 0 }
                    }}
                  >
                    <Typography sx={{ width: '165px' }}>
                      {t('MeetingStateReview.minutesStartedAt')}
                    </Typography>
                    <DateTimePickerWithButton
                      dateTimePickerProps={{
                        name: 'minutesStartedAtOverwrittenTime',
                        i18n: { keyPrefix: keyPrefix + '.DateTimePickerMinutesStartedAtField' },
                        type: 'time',
                        validationRules: {
                          pattern: /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/g
                        },
                        sx: { width: '200px', mb: 0 }
                      }}
                      originalTimeValue={DateTime.fromISO(meeting?.minutesStartedAtOriginal || '').toFormat(
                        'HH:mm'
                      )}
                    />
                  </Box>
                ) : (
                  <Typography data-cy="topic-card-minutes_minutes-started-at">
                    {t('MeetingStateReview.meetingStarted', {
                      time: formatTime({ isoString: meeting?.minutesStartedAt || '', locale })
                    })}
                  </Typography>
                )}

                {topic.quorumReachedAt ? (
                  isEditable ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'baseline',
                        gap: { sm: 3 },
                        flexDirection: { xs: 'column', sm: 'row' },
                        mb: { xs: 1, sm: 0 }
                      }}
                    >
                      <Typography sx={{ width: '165px' }}>
                        {t('MeetingStateReview.quorumReachedAt')}
                      </Typography>
                      <DateTimePickerWithButton
                        dateTimePickerProps={{
                          name: 'quorumReachedAtOverwrittenTime',
                          i18n: { keyPrefix: keyPrefix + '.DateTimePickerQuorumReachedAtField' },
                          type: 'time',
                          validationRules: {
                            pattern: /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/g
                          },
                          sx: { width: '200px', mb: 0 }
                        }}
                        originalTimeValue={DateTime.fromISO(topic.quorumReachedAtOriginal || '').toFormat(
                          'HH:mm'
                        )}
                      />
                    </Box>
                  ) : (
                    <Typography data-cy="topic-card-minutes_quorum-reached-at">
                      {t('MeetingStateReview.quorumReached', {
                        time: formatTime({ isoString: topic?.quorumReachedAt, locale })
                      })}
                    </Typography>
                  )
                ) : null}
              </Box>
            )}
            {/* NOMINATIONS */}
            {topic.type === 'nominations' && <NominationsField topic={topic} />}
            {/* AGENDA ADOPTION */}
            {topic.type === 'agendaApproval' && (
              <Box>
                <Button
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    textTransform: 'none',
                    color: 'link.main',
                    maxWidth: '100%'
                  }}
                  onClick={handleOpenPastMeetingDialog}
                >
                  <CalendarTodayIcon />
                  <Typography
                    variant="body1"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                    component="span"
                  >
                    {formatDate({
                      isoString: meeting?.startDateTime as string,
                      locale,
                      format: 'DATE_FULL'
                    })}
                    {' | '}
                    {committeeType}

                    {' | '}

                    {meeting?.title}
                  </Typography>
                </Button>{' '}
              </Box>
            )}
            {/* PAST MINUTES THAT HAVE BEEN APPROVED */}
            {topic.type === 'pastMinutesApproval' && (
              <ApprovedMinutesList approvedMinutes={topic.pastMeetingsToApprove} />
            )}

            {/* ADJOURNMENT */}
            {topic.type === 'adjournment' && (
              <Box>
                {meeting?.minutesEndedAt && isEditable ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'baseline',
                      gap: { sm: 3 },
                      flexDirection: { xs: 'column', sm: 'row' },
                      mb: { xs: 1, sm: 0 }
                    }}
                  >
                    <Typography sx={{ width: '165px' }}>{t('MeetingStateReview.minutesEndedAt')}</Typography>
                    <DateTimePickerWithButton
                      dateTimePickerProps={{
                        name: 'minutesEndedAtOverwrittenTime',
                        i18n: { keyPrefix: keyPrefix + '.DateTimePickerMinutesEndedAtField' },
                        type: 'time',
                        validationRules: {
                          pattern: /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/g
                        },
                        sx: { width: '200px', mb: 0 }
                      }}
                      originalTimeValue={DateTime.fromISO(meeting?.minutesEndedAtOriginal || '').toFormat(
                        'HH:mm'
                      )}
                    />
                  </Box>
                ) : adjournmentAt ? (
                  <Typography data-cy="topic-card-minutes_adjournment-at">
                    {t('MeetingStateReview.adjournmentAt', {
                      time: formatTime({
                        isoString: adjournmentAt,
                        locale
                      })
                    })}
                  </Typography>
                ) : null}
              </Box>
            )}

            {/* BRAND NEW TOPIC - RESOLUTION SWITCH & VOTE OUTCOME */}
            {isBrandNewTopic && isGenericTopicType && isEditable && participants && users && (
              <>
                <FormControlLabel
                  control={<Switch data-cy="topic-card-minutes_resolution-topic-switch" />}
                  label={t(keyPrefix + '.isBrandNewResolutionLabel')}
                  checked={isDecisionTopicSwitchChecked}
                  onChange={handleIsDecisionTopicSwitch}
                />
                {isDecisionTopicSwitchChecked && (
                  <RecordVoteDialogOptions
                    participants={participants}
                    topic={topic}
                    isCommitteeTakingResolutions={Boolean(committee?.isTakingResolutions)}
                    isUnanimousSelected={isUnanimousSelected}
                    setIsUnanimousSelected={setIsUnanimousSelected}
                    users={users}
                    isResolutionBookSwitchChecked={true}
                  />
                )}
              </>
            )}
            {/* BODY FIELD */}
            {[
              'quorum',
              'nominations',
              'agendaApproval',
              'pastMinutesApproval',
              'ceoReport',
              'information',
              'discussion',
              'resolution',
              'miscellaneous',
              'adjournment'
            ].includes(topic.type) && (
              <>
                {isEditable ? (
                  <Box sx={{ my: 1 }}>
                    <RichTextMultiLine
                      name="notes"
                      validationRules={{ maxLength: 20000 }}
                      isOptional={true}
                      height="unset"
                      i18n={{ keyPrefix: 'MeetingStateInProgress.RichTextMultiLineNotes' }}
                    />
                  </Box>
                ) : (
                  topic.notes && <RichTextReadOnly value={topic.notes} style="border-width: 0;" />
                )}
              </>
            )}

            {/* RESOLUTION OUTCOME */}
            {isDecisionTopicType &&
              topic?.resolutionOutcome?.outcome &&
              (isEditable && users ? (
                <EditVoteOutcome
                  topic={topic}
                  meeting={meeting}
                  users={users}
                  isCommitteeTakingResolutions={Boolean(committee?.isTakingResolutions)}
                />
              ) : (
                <Box sx={{ marginTop: '12px', marginBottom: '12px' }}>
                  <Stack spacing={3}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        border: isEditable ? `1px solid ${theme.palette.customGrey.light}` : '',
                        borderRadius: '4px',
                        alignItems: 'center',
                        padding: isEditable ? '8px 14px' : ''
                      }}
                    >
                      <ResolutionOutcomeText topic={topic} />
                    </Box>
                    {showResolutionOutcomeVotes && <ResolutionOutcomeVotes topic={topic} hideIcon />}
                  </Stack>
                </Box>
              ))}
            {/* DOCUMENTS LIST */}
            {topic.type !== 'inCamera' && (
              //REVIEW - no approved topic
              <>
                <DocumentList
                  isEditable={isEditable}
                  path={`/meetings/${topic.meetingId}/topics/${topic.id}/minutes-documents`}
                />
              </>
            )}
          </Stack>
        </FormControllerTemp>
      </Box>
    </>
  );
};
