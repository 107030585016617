import { Stack, Button } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { checkIsUnmovableTopicType } from 'utils/topicTypeArrays';
import { MoveButton } from 'components/Meetings/MeetingTopicCardEditable/MoveButton';
import { ITopicCardMinutesProps } from './TopicCardMinutesEditable';
import { TopicCardMinutesEditable } from './TopicCardMinutesEditable';
import { useFeatureGate } from '@statsig/react-bindings';

interface ITopicCardMinutesMoveableProps extends ITopicCardMinutesProps {
  meetingId: string;
  nextTopicId?: string;
  previousTopicId?: string;
}

export const TopicCardMinutesMoveable: React.FC<ITopicCardMinutesMoveableProps> = (props) => {
  const {
    topic,
    order,
    isEditable,
    handleSetTopicEditable,
    userHasEditAccess,
    onSubmitSuccess,
    meeting,
    isBeingReviewedInsideMeeting,
    meetingId,
    nextTopicId,
    previousTopicId,
    isBrandNewTopic,
    users,
    committee,
    participants
  } = props;

  const { value: isFullTopicEditEnabled } = useFeatureGate('minutes_review_topics');

  const isUnmovableTopic = checkIsUnmovableTopicType(topic.type);

  const buttonSx: SystemStyleObject = {
    minWidth: 0,
    padding: { xs: 0, md: '6px 8px' }
  };

  // dummy (invisible) button so spacing is the same
  const renderDummyButton = (): React.ReactNode => {
    return (
      <Button sx={{ visibility: 'hidden', ...buttonSx, py: '0 !important', height: '0px' }}>
        <span style={{ height: '24px', width: '24px' }} />
      </Button>
    );
  };

  return (
    <Stack direction="row" data-cy="topic-card-minutes_moveable-container">
      {userHasEditAccess && isFullTopicEditEnabled && (
        <Stack justifyContent="center" sx={{ pr: { xs: 0.5, md: 0 }, mr: 3 }}>
          {/* move up */}
          {previousTopicId && !isUnmovableTopic ? (
            <MoveButton
              topicToMoveId={topic.id}
              previousOrNextTopicId={previousTopicId}
              direction="previous"
              meetingId={meetingId}
              sx={buttonSx}
            />
          ) : (
            renderDummyButton()
          )}
          {/* move down */}
          {nextTopicId && !isUnmovableTopic ? (
            <MoveButton
              topicToMoveId={topic.id}
              previousOrNextTopicId={nextTopicId}
              direction="next"
              meetingId={meetingId}
              sx={buttonSx}
            />
          ) : (
            renderDummyButton()
          )}
        </Stack>
      )}

      <TopicCardMinutesEditable
        topic={topic}
        order={order}
        isEditable={isEditable}
        handleSetTopicEditable={handleSetTopicEditable}
        userHasEditAccess={userHasEditAccess}
        onSubmitSuccess={onSubmitSuccess}
        meeting={meeting}
        isBeingReviewedInsideMeeting={isBeingReviewedInsideMeeting}
        users={users}
        participants={participants}
        committee={committee}
        sx={{ flex: 1, p: 0 }}
        isBrandNewTopic={isBrandNewTopic}
      />
    </Stack>
  );
};
