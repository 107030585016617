import { useLocale } from '@koopajs/react';
import { useState } from 'react';
import { SxProps, Theme, TextField } from '@mui/material';

export const useConfirmDelete = (props: {
  i18nKeyPrefix: string;
}): {
  hasUserTypedDeleteConfirmation: boolean;
  renderConfirmDeleteField: (args?: { sx?: SxProps<Theme> }) => JSX.Element;
  resetConfirmDeleteField: () => void;
} => {
  const { i18nKeyPrefix } = props;

  const [confirmDeleteFieldText, setConfirmDeleteFieldText] = useState('');

  const resetConfirmDeleteField = (): void => {
    setConfirmDeleteFieldText('');
  };

  const { t } = useLocale();

  const keyPrefix = 'hooks.useConfirmDelete';

  const renderConfirmDeleteField = (args?: { sx?: SxProps<Theme> }): JSX.Element => {
    const { sx = {} } = args || {};

    return (
      <TextField
        label={t(i18nKeyPrefix + '.label')}
        value={confirmDeleteFieldText}
        helperText={t(i18nKeyPrefix + '.helperText')}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setConfirmDeleteFieldText(event.target.value);
        }}
        sx={{ maxWidth: '386px', ...sx }}
        data-cy="confirm-delete-field"
      />
    );
  };

  return {
    hasUserTypedDeleteConfirmation: confirmDeleteFieldText === t(keyPrefix + '.confirmationText'),
    renderConfirmDeleteField,
    resetConfirmDeleteField
  };
};
