import type { IMeeting } from 'types';
import { useResourceList } from '@koopajs/react';

export const useUpcomingMeetings = () => {
  const { resources: meetings, state: meetingsState } = useResourceList<IMeeting>({
    path: '/meetings',
    searchParams: {
      size: 50,
      filters: ['NOT _exists_:boardApprovedAt', 'NOT $model:"uploaded-meeting"']
    }
  });

  return {
    meetings,
    isLoading: meetingsState === 'loading'
  };
};
