import { Dialog, ErrorMessage } from '@koopajs/mui';
import { useComponentVisibility, useResourceCreate, useFileUpload } from '@koopajs/react';
import { Button, Box, Typography, Alert } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { useCallback } from 'react';
import prettyBytes from 'pretty-bytes';
import { RenderDocumentIcon } from 'components/RenderDocumentIcon';
import { IDocumentRecord } from 'types';
import { transformDocumentRecordDataForm } from 'utils/transformDocumentRecordDataForm';
import { TextField } from 'components/temp/TextFieldTemp';
import { DateTimePicker } from 'components/temp/DateTimePickerTemp';
import { fileUploadSnackbar } from 'utils/DocumentRecords/fileUploadSnackbar';
import { useSnackbar } from 'notistack';
import { useGateValue } from '@statsig/react-bindings';

export const AddDocumentToHistoryDialog: React.FC = () => {
  const { t } = useLocale();

  const keyPrefix = 'Dialogs.AddDocumentToHistory';
  const fileUpload = useFileUpload();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const addDocumentToHistoryDialog = useComponentVisibility('addDocumentToHistoryDialog');
  const { blobUrl, file, documentRecord } = addDocumentToHistoryDialog.getContext() as {
    blobUrl: string;
    file: File;
    defaultValues: { title: string | undefined };
    documentRecord?: IDocumentRecord;
  };

  const { createResource, errorMessage } = useResourceCreate({
    path: `/document-records/${documentRecord?.id}/historical-documents`
  });

  const isPdf = file?.type === 'application/pdf';
  const isDocumentDownloadBlockedOnWorkspace = useGateValue('block_document_downloads');

  const handleSubmit = useCallback(
    async (data: object): Promise<boolean> => {
      const fileUri = await fileUpload.upload(file);

      const payload = transformDocumentRecordDataForm({ data, file, fileUri });

      const isSuccess = await createResource(payload);

      if (isSuccess) {
        addDocumentToHistoryDialog.setHidden();
      }

      fileUploadSnackbar({
        isSuccess,
        file,
        trackEvent: 'document-record-historical',
        enqueueSnackbar,
        closeSnackbar,
        successMessage: t('common:snackbarFileUploadSuccess'),
        failMessage: t('common:snackbarFileUploadFail')
      });
      return isSuccess;
    },
    [createResource]
  );

  return (
    <>
      <Dialog.Form
        dialogKey="addDocumentToHistoryDialog"
        onSubmit={handleSubmit}
        i18n={{ keyPrefix: keyPrefix + '.DialogForm' }}
        maxWidth="md"
        leftAction={<Button onClick={addDocumentToHistoryDialog.setHidden}>{t('common:labelCancel')}</Button>}
        dialogProps={{ PaperProps: { 'data-cy': 'add-document-to-history-dialog' } }}
      >
        <ErrorMessage error={errorMessage} />
        <Box
          sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, gap: { xs: 1, lg: 3 }, mt: 2 }}
        >
          {blobUrl && (
            <iframe
              title={t('common:fileViewer')}
              data-cy="add-document-to-history-dialog_file-iframe"
              src={blobUrl}
              style={{ width: '100%', height: '500px' }}
              className="rr-block"
            />
          )}
          <Box sx={{ width: '100%', minWidth: '0px' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                mb: 2
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  overflow: 'hidden'
                }}
              >
                <RenderDocumentIcon fileType={file?.type} sx={{ mr: 1 }} />
                <Typography
                  variant="body2"
                  display="inline"
                  sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  data-cy={'add-document-to-history-dialog_file-name'}
                  className="rr-mask"
                >
                  {file?.name}
                </Typography>
              </Box>

              <span>&#183;</span>
              <Typography variant="body2" sx={{ opacity: 0.6, flex: 'none' }}>
                {file?.size && prettyBytes(file.size)}
              </Typography>
            </Box>
            <TextField
              name="title"
              i18n={{ keyPrefix: keyPrefix + '.FieldTitle' }}
              validationRules={{
                maxLength: 100
              }}
              className="rr-block"
            />
            <DateTimePicker
              name="activeSince"
              i18n={{ keyPrefix: keyPrefix + '.FieldDate' }}
              type="date"
              inputProps={{ min: '1900-01-01', max: '2099-12-31' }}
              validationRules={{
                pattern: /^(19\d{2}|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/g
              }}
              sx={{
                '& .MuiInputBase-input': {
                  backgroundColor: 'white'
                }
              }}
            />
          </Box>
        </Box>

        {!isPdf && isDocumentDownloadBlockedOnWorkspace && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {t('common:downloadBlockedUploadAlertLabel')}
          </Alert>
        )}
      </Dialog.Form>
    </>
  );
};
