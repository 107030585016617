import React from 'react';
import { SettingsGeneral } from './pages/general';
import { SettingsPermissions } from './pages/permissions';
import { useLocale, usePermissions } from '@koopajs/react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { MultiPageContainerTemp } from '../../components/temp/MultiPageContainerTemp';

export const Settings: React.FC = () => {
  const keyPrefix = 'Settings';
  const { t } = useLocale();

  const isUserWorkspaceOwner = usePermissions({ requiredPermissions: 'owner' });

  return (
    <MultiPageContainerTemp
      title="Settings"
      i18n={{ keyPrefix }}
      homepagePath="/settings"
      {...(isUserWorkspaceOwner
        ? {
            rightButton: (
              <Button
                data-cy="organization_add-user-button"
                sx={{ display: 'flex' }}
                variant="outlined"
                startIcon={<AddIcon />}
                target="_blank"
                rel="noopener"
                component="a"
                href="https://usepanorama.atlassian.net/servicedesk/customer/portal/1/group/1/create/9"
              >
                {t('common:addUserButtonLabel')}
              </Button>
            )
          }
        : {})}
      sx={{ px: { xs: '24px', lg: '48px' }, py: '24px', overflow: 'auto' }}
      pages={[
        {
          title: t(keyPrefix + '.general'),
          path: 'general',
          component: SettingsGeneral
        },
        {
          title: t(keyPrefix + '.usersAndPermissions'),
          path: 'permissions',
          component: SettingsPermissions
        }
      ]}
    />
  );
};
