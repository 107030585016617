import React from 'react';
import { Loading } from '@koopajs/mui';
import { IMeeting, ITopic } from 'types';
import { Box, Divider, Paper, Typography, Stack } from '@mui/material';
import { EventsTimeline } from 'components/EventsTimeline';
import { generateMeetingTimeline } from 'utils/generateMeetingTimeline';
import { useLocale } from '@koopajs/react';
import { calculateMeetingDuration } from 'utils/calculateMeetingDuration';
import { TimerOutlined as TimerOutlinedIcon } from '@mui/icons-material';
import { MeetingViewTopicCard } from 'components/Meetings/View/MeetingViewTopicCard';
import { MeetingViewHeaderCard } from 'components/Meetings/View/MeetingViewHeaderCard';
import { calculateTopicsStartTime } from 'utils/calculateTopicsStartTime';
import { PanoramaDocumentWrapper } from '../../PanoramaDocumentWrapper';

interface IPastMeetingContentProps {
  pastMeeting?: IMeeting;
  isProcessing: boolean;
}

export const PastMeetingContent: React.FC<IPastMeetingContentProps> = (props) => {
  const { pastMeeting, isProcessing } = props;

  const { t } = useLocale();
  const keyPrefix = 'Dialogs.PastMeetingDialog';

  const timelineEvents = generateMeetingTimeline(pastMeeting);

  const meetingDuration = calculateMeetingDuration(t, pastMeeting?.topics);
  const topicsWithStartTime = pastMeeting && calculateTopicsStartTime(pastMeeting);

  return (
    <>
      {isProcessing ? (
        <Box sx={{ my: 3 }}>
          <Loading sx={{ backgroundColor: 'transparent' }} />
        </Box>
      ) : (
        pastMeeting && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 5, my: 3 }}>
              <PanoramaDocumentWrapper>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <MeetingViewHeaderCard meeting={pastMeeting} />

                  <Divider />

                  <Typography variant="overline">{t(keyPrefix + '.agendaLabel')}</Typography>

                  {topicsWithStartTime
                    ?.filter((t) => !t.deletedAt)
                    .map((topic, index, array) => {
                      return (
                        <>
                          <MeetingViewTopicCard
                            topic={topic}
                            showApprovedPastMeetingsToApprove
                            order={index + 1}
                          />
                        </>
                      );
                    })}

                  <Divider />

                  <Stack alignItems="flex-end">
                    <Typography
                      variant="body1"
                      sx={{ display: 'flex', alignItems: { xs: 'flex-start', sm: 'center' } }}
                    >
                      <TimerOutlinedIcon color="action" sx={{ mr: 1 }} />
                      {t(keyPrefix + '.estimatedDurationLabel', { time: meetingDuration })}
                    </Typography>
                  </Stack>
                </Box>
              </PanoramaDocumentWrapper>{' '}
              <EventsTimeline timelineEvents={timelineEvents} sx={{ alignSelf: 'flex-start' }} />
            </Box>
          </>
        )
      )}
    </>
  );
};
