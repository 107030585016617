import { DateTime } from 'luxon';
import { ICommittee, ICommitteeMember } from 'types';

import { useResourceList, useUserShow } from '@koopajs/react';
import { useMemo } from 'react';

export const useCommitteePermissions = (): {
  isUserMeetingCreator: boolean;
  isUserResolutionCreator: boolean;
  isUserNoteTaker: boolean;
  isUserResolutionSigner: boolean;
  isUserMinutesSigner: boolean;
} => {
  const { user } = useUserShow();
  const today = DateTime.now().toISODate();

  const { resources: committees } = useResourceList<ICommittee>({
    path: '/committees',
    useCache: true
  });

  const { resources: currentUserCommitteeMembersWithActiveMandate } = useResourceList<ICommitteeMember>({
    path: '/committee-members',
    searchParams: {
      filters: [
        `userId:"${user?.id}"`,
        `startAt:[* TO ${today}]`,
        `(endAt:[${today} TO *] OR (NOT _exists_:endAt))`
      ],
      size: 50
    }
  });

  const committeesTakingResolutionsIds = useMemo(
    () => committees.filter((c) => c.isTakingResolutions).map((c) => c.id),
    [committees]
  );

  const isUserMeetingCreator = useMemo(
    () =>
      currentUserCommitteeMembersWithActiveMandate.some((cm) =>
        cm.roles?.some((role) => role.role === 'createMeetings')
      ),
    [currentUserCommitteeMembersWithActiveMandate]
  );

  const isUserResolutionCreator = useMemo(
    () =>
      currentUserCommitteeMembersWithActiveMandate
        .filter((cm) => cm.roles?.some((role) => role.role === 'createResolutionProjects'))
        .some((cm) => committeesTakingResolutionsIds.includes(cm.committeeId)),
    [currentUserCommitteeMembersWithActiveMandate, committeesTakingResolutionsIds]
  );

  const isUserNoteTaker = useMemo(
    () =>
      currentUserCommitteeMembersWithActiveMandate.some((cm) =>
        cm.roles?.some((role) => role.role === 'takingNotes')
      ),
    [currentUserCommitteeMembersWithActiveMandate]
  );

  const isUserResolutionSigner = useMemo(
    () =>
      currentUserCommitteeMembersWithActiveMandate.some((cm) =>
        cm.roles?.some((role) => role.role === 'signResolutions')
      ),
    [currentUserCommitteeMembersWithActiveMandate]
  );

  const isUserMinutesSigner = useMemo(
    () =>
      currentUserCommitteeMembersWithActiveMandate.some((cm) =>
        cm.roles?.some((role) => role.role === 'signMinutes')
      ),
    [currentUserCommitteeMembersWithActiveMandate]
  );

  return {
    isUserMeetingCreator,
    isUserResolutionCreator,
    isUserNoteTaker,
    isUserResolutionSigner,
    isUserMinutesSigner
  };
};
