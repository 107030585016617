import { IResolution } from 'types';
import { Box, Typography, Paper, Divider } from '@mui/material';
import { Loading } from '@koopajs/mui';
import { ResolutionOutcomeText } from 'components/ResolutionOutcome/ResolutionOutcomeText';
import { DocumentList } from '../../DocumentList';
import { SignatureList } from 'components/Signature/SignatureList';
import { ResolutionOutcomeVotes } from 'components/ResolutionOutcome/ResolutionOutcomeVotes';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';
import { useLocale } from '@koopajs/react';
import { isEmpty } from 'lodash';
import { ResolutionMinutesDetailsCard } from 'components/ResolutionMinutesDetailsCard';
import { WrittenResolutionDialogDetailsCard } from 'components/Resolutions/WrittenResolutionDialogDetailsCard';
import { generateResolutionTimeline } from 'utils/generateResolutionTimeline';
import { EventsTimeline } from 'components/EventsTimeline';
import { getSignaturesWithTitles } from 'utils/getSignaturesWithTitles';
import { PanoramaDocumentWrapper } from '../../PanoramaDocumentWrapper';

interface IViewResolutionProps {
  resolution?: IResolution;
  isProcessing?: boolean;
}

export const ResolutionContent: React.FC<IViewResolutionProps> = (props) => {
  const { resolution, isProcessing } = props;
  const keyPrefix = 'Resolutions.DialogView';
  const { t } = useLocale();

  const isAttachedToMeetingTopic = Boolean(resolution?.attachedToMeetingTopic);
  const isAttachedToEmail = Boolean(resolution?.attachedToEmail);

  const { membersAgainst, membersAbstained, membersFor } = resolution?.resolutionOutcome || {};
  const showResolutionOutcomeVotes = membersFor?.length || membersAbstained?.length || membersAgainst?.length;

  const timelineEvents = generateResolutionTimeline(resolution);

  const signaturesWithTitles = getSignaturesWithTitles(resolution);

  if (!resolution) return null;

  return (
    <>
      {isProcessing ? (
        <Box sx={{ my: 3 }}>
          <Loading sx={{ backgroundColor: 'transparent' }} />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', my: 3, gap: 5 }}>
          <PanoramaDocumentWrapper>
            {isAttachedToMeetingTopic && (
              <ResolutionMinutesDetailsCard
                resolution={resolution}
                title={t('Resolutions.resolutionExtract')}
              />
            )}
            {resolution.attachedToEmail && <WrittenResolutionDialogDetailsCard resolution={resolution} />}
            <Divider />

            <Typography
              variant="body1"
              data-cy="view-resolution-dialog_resolution-title"
              sx={{ overflow: 'hidden', textOverflow: 'ellipsis', minWidth: '0px' }}
            >
              {resolution.title
                ? resolution.title
                : resolution.attachedToMeetingTopic?.type
                ? t('common:topicTypes.' + resolution.attachedToMeetingTopic.type)
                : null}
            </Typography>
            {resolution?.notes && (
              <>
                <Typography variant="body2" component="div">
                  <RichTextReadOnly value={resolution.notes} />
                </Typography>
              </>
            )}
            {isAttachedToMeetingTopic &&
              resolution?.resolutionOutcome &&
              !isEmpty(resolution.resolutionOutcome) && (
                <>
                  <Typography variant="body1">
                    <ResolutionOutcomeText topic={resolution} />
                  </Typography>

                  {showResolutionOutcomeVotes && (
                    <Box>
                      <ResolutionOutcomeVotes topic={resolution} hideIcon />
                    </Box>
                  )}
                </>
              )}
            {resolution?.documentsIds && resolution?.documentsIds?.length > 0 && (
              <Box>
                <Typography variant="caption" sx={{ mb: '4px', opacity: 0.6 }}>
                  {t('common:relatedDocumentationLabel')}
                </Typography>
                <DocumentList path={`/resolutions/${resolution.id}/documents`} />
              </Box>
            )}
            {isAttachedToEmail && resolution.resolutionOutcome?.outcome === 'declined' && (
              <Typography data-cy="view-resolution-dialog_written-resolution-declined">
                {t(keyPrefix + '.writtenResolutionSignatureDescriptionDeclined')}
              </Typography>
            )}
            {signaturesWithTitles && (
              <>
                <Divider />
                <SignatureList
                  signatures={signaturesWithTitles}
                  description={
                    isAttachedToEmail &&
                    resolution.resolutionOutcome?.outcome === 'approved' && (
                      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        {t(keyPrefix + '.writtenResolutionSignatureDescriptionApproved')}
                      </Box>
                    )
                  }
                />
              </>
            )}
          </PanoramaDocumentWrapper>
          <EventsTimeline timelineEvents={timelineEvents} sx={{ alignSelf: 'flex-start' }} />
        </Box>
      )}
    </>
  );
};
