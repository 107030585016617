import { Alert, Link as MuiLink, Box } from '@mui/material';
import { useLocale } from '@koopajs/react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { theme } from './Core/theme';
import { alpha } from '@mui/material/styles';

export const AnnouncementBanner: React.FC = () => {
  const { t } = useLocale();

  const url = t('Header.aiSurvey.bannerCtaUrl');

  return (
    <Alert
      sx={{
        borderRadius: '0',
        //borderWidth: '0 0 1px 0',
        //borderStyle: 'solid',
        //borderColor: 'tertiary.main',
        '& .MuiAlert-message, .MuiAlert-icon': { p: 0 },
        justifyContent: 'center',
        textAlign: 'center',
        backgroundImage: `linear-gradient(90deg, ${alpha(theme.palette.automation.purple, 0.2)} 0%, ${alpha(
          theme.palette.automation.blue,
          0.2
        )} 100%)`,
        py: '4px',
        alignItems: 'center'
      }}
      icon={
        <Box>
          <svg width={0} height={0}>
            <defs>
              <linearGradient
                id={'starsIconGradientMobile'}
                x1="12.0038"
                y1="3"
                x2="12.0038"
                y2="21"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0%" stopColor={theme.palette.automation.purple} />
                <stop offset="100%" stopColor={theme.palette.automation.blue} />
              </linearGradient>
            </defs>
          </svg>
          <AutoAwesomeIcon sx={{ fill: `url(#${'starsIconGradientMobile'})`, fontSize: '18px' }} />
        </Box>
      }
    >
      <MuiLink
        href={url}
        sx={{
          alignItems: 'center',
          textDecoration: 'none',
          color: 'link.main',
          letterSpacing: '-0.5px'
        }}
        target="_blank"
        rel="noreferrer"
      >
        {t('Header.aiSurvey.bannerCtaMessage')}
        <OpenInNewIcon fontSize="small" sx={{ fontSize: '18px', verticalAlign: 'text-bottom', ml: 0.5 }} />
      </MuiLink>
    </Alert>
  );
};
