import { Box, List, ListItem, Typography } from '@mui/material';
import { ITopic } from 'types';
import { useLocale } from '@koopajs/react';
import { User } from '@koopajs/react';

interface INominationsFieldProps {
  topic: ITopic;
}

export const NominationsField: React.FC<INominationsFieldProps> = (props) => {
  const { topic } = props;

  const { t } = useLocale();
  const keyPrefix = 'Components.TopicCard.NominationsField';

  return (
    <>
      {(topic.meetingPresidentId || topic.meetingSecretaryId) && (
        <List
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            p: 0,
            mt: 0,
            '@media print': {
              p: 0
            }
          }}
        >
          {topic.meetingPresidentId && (
            <ListItem disableGutters sx={{ p: 0, width: 'auto', mr: 10 }}>
              <User id={topic.meetingPresidentId}>
                {(user) => {
                  return (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="body2" sx={{ whiteSpace: 'normal' }}>
                        {user?.username}
                      </Typography>
                      <Typography variant="caption" sx={{ opacity: '0.6', whiteSpace: 'normal' }}>
                        {t(keyPrefix + '.assemblyPresident')}{' '}
                      </Typography>
                    </Box>
                  );
                }}
              </User>
            </ListItem>
          )}
          {topic.meetingSecretaryId && (
            <ListItem disableGutters sx={{ p: 0, width: 'auto' }}>
              <User id={topic.meetingSecretaryId}>
                {(user) => {
                  return (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="body2" sx={{ whiteSpace: 'normal' }}>
                        {user?.username}
                      </Typography>
                      <Typography variant="caption" sx={{ opacity: '0.6', whiteSpace: 'normal' }}>
                        {t(keyPrefix + '.assemblySecretary')}{' '}
                      </Typography>
                    </Box>
                  );
                }}
              </User>
            </ListItem>
          )}
        </List>
      )}
    </>
  );
};
