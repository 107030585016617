export interface INavigationSectionProps extends React.PropsWithChildren {
  title?: string;
}
export const NavigationSection: React.FC<INavigationSectionProps> = (props) => {
  const { children, title } = props;

  return (
    <div className="mb-6 px-2">
      {title && (
        <div className="text-xs font-semibold text-slate-400 uppercase text-muted-foreground  select-none">
          {title}
        </div>
      )}
      {children}
    </div>
  );
};
