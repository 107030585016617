import { DateTime } from 'luxon';
import { IMeeting, ITopic } from 'types';

export const calculateTopicsStartTime = (meeting: IMeeting): ITopic[] | undefined => {
  let topics = meeting.topics;

  let startDateTimeIso: string | undefined = undefined;

  if (meeting?.startDateTime) {
    startDateTimeIso = meeting?.startDateTime;
  } else if (meeting.startTime) {
    startDateTimeIso = DateTime.fromFormat(meeting.startTime, 'HH:mm', {
      zone: 'UTC'
    }).toISO();
  }

  if (startDateTimeIso) {
    let cumulativeMeetingDurationUnix = DateTime.fromISO(startDateTimeIso).toMillis() / 1000;

    topics = meeting.topics?.map((topic: ITopic) => {
      const updatedTopic = { ...topic, calculatedTopicStartTime: cumulativeMeetingDurationUnix };
      cumulativeMeetingDurationUnix = cumulativeMeetingDurationUnix + (topic.durationSeconds || 0);
      return updatedTopic;
    });
  }

  return topics;
};
