/* eslint-disable react/jsx-no-bind */
import { IMeeting } from 'types';
import { useState } from 'react';
import { useUserShow, useLocale, useParamsKey, useResourceShow } from '@koopajs/react';
import {
  Button,
  Box,
  Stack,
  Typography,
  Paper,
  Divider,
  ClickAwayListener,
  Tooltip,
  List,
  ListItem
} from '@mui/material';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import _ from 'lodash';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MinutesHeaderCard } from 'components/MinutesHeaderCard';
import { generateMeetingTimeline } from 'utils/generateMeetingTimeline';
import { PageContainer } from 'components/temp/PageContainer';
import { EventsTimeline } from 'components/EventsTimeline';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { MeetingLoadingOrError } from 'components/Meetings/MeetingLoadingOrError';
import { Trans } from 'react-i18next';
import { theme } from '../components/Core/theme';
import { LightBulbIconButton } from 'components/LightBulbIconButton';
import { TopicCardMinutes } from 'components/TopicCard/TopicCardMinutes';
import { useMeetingStatus } from 'components/hooks/useMeetingStatus';
import { Loading } from '@koopajs/mui';
import { PanoramaDocumentWrapper } from 'components/PanoramaDocumentWrapper';

export const i18nTransComponents: { [k: string]: React.ReactElement } = {
  span: <span style={{ color: 'grey' }} />
};

export const MeetingReviewView: React.FC = () => {
  const meetingId = useParamsKey('meetingId');

  const {
    resource: meeting,
    isProcessing,
    errorMessage
  } = useResourceShow<IMeeting>({
    path: '/meetings',
    id: meetingId
  });
  const keyPrefix = 'MeetingStateReview';

  const timelineEvents = generateMeetingTimeline(meeting);

  const { user: currentUser } = useUserShow();

  const history = useHistory();

  const { t } = useLocale();

  const notDeletedTopics = meeting?.topics?.filter((topic) => !topic.deletedAt);
  const isUserReviewer =
    currentUser?.id && meeting?.reviewers?.some((reviewer) => reviewer.userId === currentUser.id);

  const userHasEditAccess = !meeting?.boardApprovedAt && isUserReviewer ? true : false;

  const [isGuideTooltipOpen, setIsGuideTooltipOpen] = useState(false);
  const handleGuideTooltipClose = () => {
    setIsGuideTooltipOpen(false);
  };
  const handleGuideTooltipOpen = () => {
    setIsGuideTooltipOpen(true);
  };
  const { hasMeetingEnded } = useMeetingStatus({ meeting, user: currentUser });

  const doesUserHaveAccessToPage = hasMeetingEnded;
  if (meeting && !doesUserHaveAccessToPage) {
    history.push(`/meetings/${meetingId}`);
  }

  if (!meeting) {
    return <MeetingLoadingOrError isProcessing={isProcessing} errorMessage={errorMessage} />;
  } else if (!doesUserHaveAccessToPage) {
    // loading component while we wait for the redirect to the view page
    return (
      <Stack alignItems="center" sx={{ width: '100%' }}>
        <Loading sx={{ backgroundColor: 'transparent', position: 'static' }} />
      </Stack>
    );
  }
  return (
    <PageContainer
      sxChildren={{ padding: { xs: 3, md: '24px 56px' } }}
      className="rr-block"
      testId="meeting-review-page"
    >
      <Breadcrumbs
        hierarchyArray={[{ path: '/tasks/to-review', text: t('common:navigation.toAdopt') }]}
        pageTitle={t(keyPrefix + '.titleReview')}
      />

      <Box sx={{ mt: 4, mb: 2 }}>
        <Stack flexDirection="row">
          <Button
            component={RouterLink}
            to="/tasks/to-review"
            variant="outlined"
            sx={{ minWidth: 0, px: '5px', display: { sm: 'none' }, mr: '14px' }}
            aria-label={t('common:labelBack')}
          >
            <ArrowBackIcon sx={{ color: 'primary.main' }} />
          </Button>

          <Box
            sx={{
              display: 'flex',
              justifyContent: { sm: 'space-between' },
              alignItems: { sm: 'center' },
              flex: 1
            }}
          >
            <Stack flexDirection="row" alignItems="center">
              <Typography variant="h1" sx={{ display: 'inline-block', fontWeight: 500, fontSize: '20px' }}>
                {t(keyPrefix + '.titleView')}
              </Typography>
              <ClickAwayListener onClickAway={handleGuideTooltipClose}>
                <div>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                      sx: {
                        '& .MuiTooltip-tooltip': {
                          p: 0,
                          backgroundColor: 'transparent',
                          maxWidth: '600px',
                          fontWeight: 400
                        }
                      }
                    }}
                    onClose={handleGuideTooltipClose}
                    open={isGuideTooltipOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <Paper
                        sx={{
                          border: `1px solid ${theme.palette.customGrey.light}`,
                          p: 3
                        }}
                      >
                        <Typography variant="h6">{t(keyPrefix + '.guideTooltip.title')}</Typography>
                        <List
                          sx={{
                            listStyle: 'decimal',
                            listStylePosition: 'inside',
                            fontSize: 'initial',
                            '& > li::marker': { fontWeight: 'bold' }
                          }}
                        >
                          {['bullet1', 'bullet2', 'bullet3', 'bullet4', 'bullet5'].map((bullet) => (
                            <ListItem key={bullet} disablePadding sx={{ display: 'list-item' }}>
                              <Trans i18nKey={keyPrefix + `.guideTooltip.${bullet}`} t={t} />
                            </ListItem>
                          ))}
                        </List>
                      </Paper>
                    }
                  >
                    <Box>
                      <LightBulbIconButton
                        onClick={handleGuideTooltipOpen}
                        ariaLabel={t(keyPrefix + '.guideTooltip.ariaLabel')}
                      />
                    </Box>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            </Stack>
            <Box sx={{ display: { xs: 'none', sm: 'flex' }, gap: 3, mt: 1 }}>
              {userHasEditAccess && currentUser && (
                <Button
                  component={RouterLink}
                  to={{
                    pathname: `/meetings/${meeting.id}/review/edit`,
                    state: { activePath: '/tasks/to-review' }
                  }}
                  variant="outlined"
                >
                  {t('common:labelEdit')}
                </Button>
              )}
            </Box>
          </Box>
        </Stack>{' '}
        <Box sx={{ display: { xs: 'block', sm: 'none' }, mt: '14px' }}>
          {userHasEditAccess && currentUser && (
            <Button
              component={RouterLink}
              to={{
                pathname: `/meetings/${meeting.id}/review/edit`,
                state: { activePath: '/tasks/to-review' }
              }}
              variant="outlined"
            >
              {t('common:labelEdit')}
            </Button>
          )}
        </Box>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 5, my: 3 }}>
        <PanoramaDocumentWrapper>
          <MinutesHeaderCard minutes={meeting} />
          <Divider />

          <Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {notDeletedTopics?.map((topic, index, array) => {
                return (
                  <Box key={topic.id}>
                    <TopicCardMinutes topic={topic} order={index + 1} minutes={meeting} />
                    {index !== array.length - 1 && <Divider sx={{ my: '14px' }} />}
                  </Box>
                );
              })}
            </Box>
          </Box>
        </PanoramaDocumentWrapper>

        <EventsTimeline timelineEvents={timelineEvents} sx={{ alignSelf: 'flex-start' }} />
      </Box>
    </PageContainer>
  );
};
