import { useMemo, useState } from 'react';
import { useLocale, useUserShow } from '@koopajs/react';
import { Typography, Stack, Tabs, Tab, Box } from '@mui/material';
import { Loading } from '@koopajs/mui';
import { EmptyStateUpcomingMeetings } from 'components/EmptyStates/EmptyStateUpcomingMeetings';
import { CreateButton } from 'components/CreateButton';
import { Helmet } from 'react-helmet';
import { PageContainer } from 'components/temp/PageContainer';
import { CalendarView } from 'components/Dashboard/CalendarView';
import { MeetingCards } from 'components/Dashboard/MeetingCards';
import { NoMeetingMessage } from 'components/Dashboard/NoMeetingMessage';
import { useUpcomingMeetings } from 'data/meetings/useUpcomingMeetings';
import { filterMyMeetings } from 'data/meetings/filterMyMeetings';
import { filterMeetingNotEnded } from 'data/meetings/filterMeetingNotEnded';
import { filterNotMyMeetings } from 'data/meetings/filterNotMyMeetings';

export default function Dashboard(): JSX.Element {
  const { t } = useLocale();
  const { user } = useUserShow();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const { meetings, isLoading } = useUpcomingMeetings();

  const myUpcomingMeetings = useMemo(
    () => meetings.filter(filterMyMeetings(user)).filter(filterMeetingNotEnded),
    [meetings]
  );

  const otherUpcomingMeetings = useMemo(
    () => meetings.filter(filterNotMyMeetings(user)).filter(filterMeetingNotEnded),
    [meetings]
  );

  if (isLoading) {
    return (
      <Stack sx={{ position: 'relative', width: '100%', height: '100%' }}>
        <Loading sx={{ backgroundColor: 'transparent', position: 'absolute' }} />
      </Stack>
    );
  }

  if (meetings.length === 0) {
    return (
      <EmptyStateUpcomingMeetings
        button={
          <Box>
            <CreateButton otherButtonProps={{ disableElevation: true }} />
          </Box>
        }
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>{`${t('common:navigation.upcomingMeetings')} - Panorama`}</title>
      </Helmet>

      <PageContainer sxChildren={{ px: { xs: '24px', lg: '48px' }, py: '24px' }} className="rr-block">
        <Typography variant="h1" sx={{ fontSize: '24px' }}>
          {t(`Dashboard.titleUpcomingMeetings`)}
        </Typography>

        <Tabs
          value={selectedTabIndex}
          onChange={(event, selectedIndex) => setSelectedTabIndex(selectedIndex as number)}
          sx={{ display: 'inline-flex', my: 2 }}
        >
          <Tab label={t(`Dashboard.tabMyMeetings`)} data-cy={`dashboard_my-meetings-tab`} />
          <Tab label={t(`Dashboard.tabOtherMeetings`)} data-cy={`dashboard_other-meetings-tab`} />
        </Tabs>

        <Box>
          <CalendarView meetings={selectedTabIndex === 0 ? myUpcomingMeetings : otherUpcomingMeetings} />
          <Typography variant="h2" sx={{ marginTop: '24px', fontWeight: 500, fontSize: '20px' }}>
            {t(`Dashboard.titleAllMyUpcomingMeetings`)}
          </Typography>
        </Box>

        {selectedTabIndex === 0 && myUpcomingMeetings.length === 0 && (
          <NoMeetingMessage text={t('Dashboard.noMyUpcomingMeetings')} />
        )}
        {selectedTabIndex === 1 && otherUpcomingMeetings.length === 0 && (
          <NoMeetingMessage text={t('Dashboard.noOtherUpcomingMeetings')} />
        )}

        <Box sx={{ marginTop: '24px' }}>
          <MeetingCards meetings={selectedTabIndex === 0 ? myUpcomingMeetings : otherUpcomingMeetings} />
        </Box>
      </PageContainer>
    </>
  );
}
