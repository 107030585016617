import React from 'react';
import { useLocale } from '@koopajs/react';
import meetingImageFR from '../../assets/emptyStates/meeting_FR.png';
import meetingImageEN from '../../assets/emptyStates/meeting_EN.png';
import { EmptyStateTemplate } from './EmptyStateTemplate';
import { useCommitteePermissions } from 'data/permissions/useCommitteePermissions';

interface IEmptyStateProps {
  windowTitle?: string;
  button?: React.ReactNode;
}

export const EmptyStateUpcomingMeetings: React.FC<IEmptyStateProps> = ({ windowTitle, button }) => {
  const { t, locale } = useLocale();
  const keyPrefix = 'UpcomingMeetings.EmptyState';

  const { isUserMeetingCreator } = useCommitteePermissions();

  const tipKeys = isUserMeetingCreator ? ['tipUnpublished', 'tipReminder'] : undefined;

  return (
    <EmptyStateTemplate
      windowTitle={windowTitle}
      pageTitle={t('UpcomingMeetings.title')}
      emptyStateTitle={t(`${keyPrefix}.title`)}
      emptyStateDescription={t(`${keyPrefix}.description`)}
      image={locale.includes('en') ? meetingImageEN : meetingImageFR}
      button={button}
      tipKeys={tipKeys}
      keyPrefix={keyPrefix}
      t={t}
    />
  );
};
