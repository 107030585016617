import React from 'react';
import { useLocale, usePermissions } from '@koopajs/react';
import resolutionImageFR from '../../assets/emptyStates/resolution_FR.png';
import resolutionImageEN from '../../assets/emptyStates/resolution_EN.png';
import { EmptyStateTemplate } from './EmptyStateTemplate';
import { useCommitteePermissions } from 'data/permissions/useCommitteePermissions';

interface IEmptyStateProps {
  windowTitle?: string;
  button?: React.ReactNode;
}

export const EmptyStateResolutions: React.FC<IEmptyStateProps> = ({ windowTitle, button }) => {
  const { t, locale } = useLocale();
  const keyPrefix = 'Resolutions.EmptyState';

  const { isUserMeetingCreator, isUserResolutionCreator } = useCommitteePermissions();
  const isUserWorkspaceOwner = usePermissions({ requiredPermissions: 'owner' });

  const tipKeys = [];
  if (isUserMeetingCreator) tipKeys.push('tipAdoptInMeeting');
  if (isUserResolutionCreator) tipKeys.push('tipAdoptWrittenResolution');
  if (isUserWorkspaceOwner) tipKeys.push('tipUpload');

  return (
    <EmptyStateTemplate
      windowTitle={windowTitle}
      pageTitle={t('Resolutions.title')}
      emptyStateTitle={t(`${keyPrefix}.title`)}
      emptyStateDescription={t(`${keyPrefix}.description`)}
      image={locale.includes('en') ? resolutionImageEN : resolutionImageFR}
      button={button}
      tipKeys={tipKeys}
      keyPrefix={keyPrefix}
      t={t}
    />
  );
};
