import { useLocale, useParamsKey, useResourceShow, useResourceUpdate, useUserShow } from '@koopajs/react';
import { Button, Box, Breadcrumbs, Stack, Typography, Paper, Divider, Link } from '@mui/material';
import { IMinute, IApprovedTopic } from 'types';
import { Link as RouterLink } from 'react-router-dom';
import { EventsTimeline } from 'components/EventsTimeline';
import { SignatureList } from 'components/Signature/SignatureList';
import { ErrorMessage, Loading } from '@koopajs/mui';
import { useCallback, useRef } from 'react';
import { TopicCardMinutes } from 'components/TopicCard/TopicCardMinutes';
import { MinutesHeaderCard } from 'components/MinutesHeaderCard';
import { WarningAmber as WarningAmberIcon, ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { MissingSigneesList } from 'components/Dashboard/ToSign/MissingSigneesList';
import { calculateMissingSignatureCount } from 'utils/calculateMissingSignatureCount';
import { generateMeetingTimeline } from 'utils/generateMeetingTimeline';
import { Helmet } from 'react-helmet';
import { RenderPrintButton } from 'components/RenderPrintButton';
import { ViewMinutesPrintVersion } from 'components/Dialogs/ViewMinutesPrintVersion';
import { PageContainer } from 'components/temp/PageContainer';
import { getSignaturesWithTitles } from 'utils/getSignaturesWithTitles';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { extractErrorMessage } from 'utils/extractErrorMessage';

export const ToSignPageMinutes: React.FC = () => {
  const minutesId = useParamsKey('minutesId');

  const { t } = useLocale();
  const keyPrefix = 'ToSign';

  const { user } = useUserShow();
  const history = useHistory();

  const { updateResource: updateMinutes } = useResourceUpdate({
    path: '/items-to-sign/minutes',
    id: minutesId,
    customReducer: {
      path: {
        resourceType: `/minutes`,
        resourceId: minutesId
      },
      mapping: (prevObj: unknown, newObj: unknown) => {
        const previousMinutes = prevObj as IMinute;
        const updatedMinutes = newObj as IMinute;

        return { ...previousMinutes, ...updatedMinutes };
      }
    }
  });

  const handleSignMinutes = useCallback(async (): Promise<void> => {
    await updateMinutes({});
  }, [updateMinutes]);

  const {
    resource: minutes,
    errorMessage,
    isProcessing
  } = useResourceShow<IMinute>({ path: '/minutes', id: minutesId });

  const extractedErrorMessage = extractErrorMessage(errorMessage);

  const canUserSign = Boolean(minutes?.membersLeftToSign?.some((m) => m.userId === user?.id));
  const membersLeftToSignIds = minutes?.membersLeftToSign?.map((m) => m.userId);

  const timelineEvents = generateMeetingTimeline(minutes);
  const componentRef = useRef(null);

  const signaturesWithTitles = getSignaturesWithTitles(minutes);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (errorMessage === "Error 403: You don't have access to these minutes") {
      enqueueSnackbar(extractedErrorMessage, {
        variant: 'error',
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });
      history.push('/tasks/to-sign');
    }
  }, [errorMessage, enqueueSnackbar, closeSnackbar, minutesId]);

  return (
    <>
      <PageContainer sxChildren={{ padding: '24px', position: 'relative' }} className="rr-block">
        <Helmet>
          <title>{`${t('common:navigation.toSignMinutes')} - Panorama`}</title>
        </Helmet>
        {isProcessing ? (
          <Box sx={{ my: 3 }}>
            <Loading sx={{ backgroundColor: 'transparent', position: 'absolute' }} />
          </Box>
        ) : (
          minutes && (
            <>
              <Box sx={{ display: 'none' }}>
                <ViewMinutesPrintVersion
                  minutes={minutes as IMinute}
                  topics={minutes.approvedTopics as IApprovedTopic[]}
                  ref={componentRef}
                />
              </Box>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  color="inherit"
                  to="/tasks/to-sign"
                  sx={{ cursor: 'pointer' }}
                  component={RouterLink}
                >
                  {t('common:navigation.toSign')}
                </Link>
                <Typography color="text.primary">
                  {canUserSign ? t(keyPrefix + '.titleSignMinutes') : t(keyPrefix + '.titleViewMinutes')}
                </Typography>
              </Breadcrumbs>

              <Box sx={{ mx: { lg: 9 } }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mt: 4, mb: 2 }}
                >
                  <Typography
                    variant="h1"
                    sx={{ display: 'inline-block', fontWeight: 500, fontSize: '20px' }}
                  >
                    {canUserSign ? t(keyPrefix + '.titleSignMinutes') : t(keyPrefix + '.titleViewMinutes')}
                  </Typography>

                  {canUserSign && (
                    <Button
                      variant="contained"
                      onClick={handleSignMinutes}
                      data-cy="to-sign-page-minutes_sign-button"
                    >
                      {t('common:sign')}
                    </Button>
                  )}
                </Stack>
                <>
                  <ErrorMessage error={errorMessage} />
                  <Paper variant="outlined" sx={{ p: 3 }}>
                    <MinutesHeaderCard minutes={minutes} />

                    <Divider sx={{ my: '14px' }} />
                    <Stack>
                      {minutes?.approvedTopics?.map((topic, index, array) => {
                        return (
                          <Box key={topic.id}>
                            <TopicCardMinutes topic={topic} order={index + 1} minutes={minutes} />
                            {<Divider sx={{ my: '14px' }} />}
                          </Box>
                        );
                      })}
                    </Stack>

                    {/* Signatures  */}

                    <Box>
                      {signaturesWithTitles && (
                        <Box data-cy="to-sign-page-minutes_signatures">
                          <SignatureList signatures={signaturesWithTitles} />
                        </Box>
                      )}

                      {membersLeftToSignIds && membersLeftToSignIds.length > 0 && (
                        <Box sx={{ my: '14px' }} data-cy="to-sign-page-minutes_missing-signatures">
                          <Stack direction="row" sx={{ mb: 2, alignItems: 'center' }}>
                            <WarningAmberIcon fontSize="small" sx={{ mr: 1 }} />
                            <Typography>
                              {t(`ToSign.ToSignItem.signaturesMissing`, {
                                count: calculateMissingSignatureCount(minutes)
                              })}
                            </Typography>
                          </Stack>
                          <MissingSigneesList signees={membersLeftToSignIds} />
                        </Box>
                      )}
                    </Box>
                  </Paper>

                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                    <EventsTimeline timelineEvents={timelineEvents} />
                  </Box>

                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between', my: 4, alignItems: 'flex-start' }}
                  >
                    {/* eslint-disable-next-line react/jsx-no-bind */}
                    <Button
                      component={RouterLink}
                      to="/tasks/to-sign"
                      sx={{ display: { xs: 'none', sm: 'block' } }}
                    >
                      {t('common:labelBack')}
                    </Button>
                    <Button
                      component={RouterLink}
                      to="/tasks/to-sign"
                      variant="outlined"
                      sx={{ minWidth: 0, px: '5px', display: { sm: 'none' } }}
                      aria-label={t('common:labelBack')}
                    >
                      <ArrowBackIcon sx={{ color: 'primary.main' }} />
                    </Button>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <RenderPrintButton
                        keyPrefix={keyPrefix + '.labelPrintMinutes'}
                        componentRef={componentRef}
                        documentTitle={minutes?.title}
                        variant="outlined"
                      />
                      {canUserSign && (
                        <Button
                          variant="contained"
                          onClick={handleSignMinutes}
                          data-cy="to-sign-page-minutes_sign-button"
                        >
                          {t('common:sign')}
                        </Button>
                      )}
                    </Box>
                  </Box>
                </>
              </Box>
            </>
          )
        )}
      </PageContainer>
    </>
  );
};
