import { IMeeting, IMinute, ITopic } from 'types';
import { Stack, Typography, Box, List, Tooltip, IconButton, Button } from '@mui/material';
import { useLocale, useResourceUpdate } from '@koopajs/react';
import { useCommitteeName } from './hooks/useCommitteeName';
import { DateTimeStartEnd } from 'components/DateTimeStartEnd';
import { Edit as EditIcon } from '@mui/icons-material';
import { useCallback, useState } from 'react';
import { FormControllerTemp } from './temp/FormControllerTemp';
import { UsersCheckboxesRows } from './UsersCheckboxesRows';
import { getCommitteeMembersTitlesObj } from 'utils/getCommitteeMembersTitlesObj';
import { TimeStartEnd } from 'components/TimeStartEnd';
import { UsersWithTitlesList } from './UsersWithTitlesList';

interface IMinutesHeaderCard {
  minutes: IMeeting | IMinute;
  userHasEditAccess?: boolean;
}

//Component used for Review meeting, Sign minutes, Dialog View minutes book

export const MinutesHeaderCard: React.FC<IMinutesHeaderCard> = (props) => {
  const { minutes, userHasEditAccess } = props;

  const [isEditable, setIsEditable] = useState(false);

  const { t } = useLocale();
  const keyPrefix = 'MeetingEdit.MeetingStaticCard';
  const presentParticipants = minutes.participants?.filter((p) => p.isPresent);
  const absentParticipants = minutes.participants?.filter((p) => !p.isPresent);

  const presentParticipantsIds = presentParticipants?.map((p) => p.userId);

  const minutesParticipantsIds = minutes.participants?.map((p) => p.userId);

  const minutesType = useCommitteeName(minutes.committeeId as string);

  const setToEditMode = useCallback(() => {
    setIsEditable(true);
  }, []);

  //if review - the type is IMeeting (there is topics array)
  const isIMeeting = (obj: any): obj is IMeeting => {
    return obj && obj.topics !== undefined;
  };
  let quorumId;
  if (isIMeeting(minutes)) {
    quorumId = minutes.topics?.find((t: ITopic) => t.type === 'quorum')?.id;
  }

  const { updateResource: updateQuorumTopic } = useResourceUpdate({
    path: `/meetings/${minutes.id}/topics`,
    id: quorumId as string,
    customReducer: {
      path: {
        resourceType: `/meetings`,
        resourceId: minutes.id
      },
      mapping: (prevObj: unknown, newObj: unknown) => {
        const meeting = prevObj as IMeeting;
        const editedTopic = newObj as ITopic;

        const updatedMeeting: IMeeting = {
          ...meeting,
          topics: meeting?.topics?.map((t: ITopic) => {
            if (t.id === editedTopic.id) {
              return editedTopic;
            } else {
              return t;
            }
          })
        };

        if (editedTopic.quorumParticipants?.length === updatedMeeting.participants?.length) {
          updatedMeeting.participants = editedTopic.quorumParticipants;
        }
        return updatedMeeting;
      }
    }
  });
  const handleSubmit = useCallback(
    async (formData: object) => {
      const formDataTyped = formData as {
        participants?: { [k: string]: boolean };
      };
      const updatedParticipants =
        formDataTyped.participants &&
        Object.entries(formDataTyped.participants).map((p) => ({
          userId: p[0],
          isPresent: Boolean(p[1])
        }));

      const topicUpdateResponse = await updateQuorumTopic({ quorumParticipants: updatedParticipants });

      if (topicUpdateResponse) {
        setIsEditable(false);
      }
      return topicUpdateResponse;
    },
    [updateQuorumTopic]
  );

  const committeeMembersTitlesObj = getCommitteeMembersTitlesObj(minutes.activeCommitteeMembers);

  const defaultValParticipantsObj = presentParticipantsIds?.reduce((acc: { [k: string]: boolean }, id, i) => {
    acc[id] = true;
    return acc;
  }, {});

  return (
    <FormControllerTemp
      onSubmit={handleSubmit}
      defaultValues={defaultValParticipantsObj}
      onSubmitSuccessResetType="FORM_PAYLOAD"
    >
      <Box
        sx={{
          display: 'flex'
        }}
        data-cy="minutes-header-card"
      >
        <Stack sx={{ flexGrow: 1, minWidth: 0 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              marginBottom: '14px'
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="overline">{t('common:boardMinutes')}</Typography>{' '}
              {userHasEditAccess &&
                (isEditable ? (
                  <Button type="submit" variant="contained">
                    {t('common:labelSave')}
                  </Button>
                ) : (
                  <Tooltip title={t('common:labelEdit')}>
                    <IconButton onClick={setToEditMode}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                ))}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: 1,
                flexWrap: 'wrap'
              }}
            >
              <Typography variant="h6">
                {' '}
                <DateTimeStartEnd isoStringDate={minutes.startDateTime} dateFormat="DATE_HUGE" />
              </Typography>{' '}
              <Typography variant="h6" sx={{ display: { xs: 'none', sm: 'inline-block' } }}>
                {' | '}
              </Typography>
              <Typography variant="h6" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {minutesType}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                flexWrap: 'wrap',
                flexDirection: { xs: 'column', sm: 'row' }
              }}
            >
              <Typography
                variant="body2"
                data-cy="minutes-header-card_minutes-title"
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}
              >
                {minutes?.title}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: 'text.secondary', display: { xs: 'none', sm: 'block' } }}
              >
                &#183;
              </Typography>

              <Box sx={{ display: 'flex', gap: 1 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  <TimeStartEnd isoStringStart={minutes?.startDateTime} isoStringEnd={minutes?.endDateTime} />
                </Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  &#183;
                </Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {t(`common:locationType.${minutes?.location?.type}`)}
                </Typography>
              </Box>
            </Box>
            {minutes.location?.address && (
              <Typography
                variant="body2"
                sx={{
                  color: 'text.secondary',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {minutes?.location?.address}
              </Typography>
            )}
          </Box>

          {isEditable && userHasEditAccess ? (
            <UsersCheckboxesRows
              usersIds={minutesParticipantsIds}
              defaultValues={presentParticipantsIds}
              isCheckboxLabelAvatar
              formName="participants"
              label={t('common:attendance')}
              sxProps={{ mb: 3 }}
              committeeMembersTitles={committeeMembersTitlesObj}
            />
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {presentParticipants && presentParticipants?.length > 0 && (
                <Box>
                  <Typography variant="caption">
                    {t(keyPrefix + '.presentParticipants', { count: presentParticipants.length })}
                  </Typography>
                  <UsersWithTitlesList
                    userIds={presentParticipants?.map((user) => user.userId)}
                    committeeMembersTitles={committeeMembersTitlesObj}
                  />
                </Box>
              )}
              {absentParticipants && absentParticipants?.length > 0 && (
                <Box>
                  <Typography variant="caption">
                    {t(keyPrefix + '.absentParticipants', { count: absentParticipants.length })}
                  </Typography>
                  <UsersWithTitlesList
                    userIds={absentParticipants?.map((user) => user.userId)}
                    committeeMembersTitles={committeeMembersTitlesObj}
                  />
                </Box>
              )}
            </Box>
          )}
        </Stack>
      </Box>
    </FormControllerTemp>
  );
};
