import type React from 'react';
import { Container } from '@mui/material';
import { Helmet } from 'react-helmet';
import { PageContainer } from '../temp/PageContainer';
import EmptyStatePageHeader from './EmptyStatePageHeader';
import { EmptyStatePaper } from './EmptyStatePaper';

interface IEmptyStateTemplateProps {
  windowTitle?: string;
  pageTitle: string;
  emptyStateTitle: string;
  emptyStateDescription: string;
  image: string;
  tipKeys?: string[];
  button?: React.ReactNode;
  keyPrefix: string;
  t: (key: string) => string;
}

export const EmptyStateTemplate: React.FC<IEmptyStateTemplateProps> = (props) => {
  const {
    windowTitle,
    pageTitle,
    emptyStateTitle,
    emptyStateDescription,
    image,
    tipKeys,
    button,
    keyPrefix,
    t
  } = props;

  return (
    <>
      {windowTitle && (
        <Helmet>
          <title>{windowTitle}</title>
        </Helmet>
      )}
      <PageContainer sxChildren={{ padding: '24px' }}>
        <EmptyStatePageHeader pageTitle={pageTitle} button={button} />

        <Container
          sx={{ textAlign: 'center', mt: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <EmptyStatePaper
            emptyStateTitle={emptyStateTitle}
            emptyStateDescription={emptyStateDescription}
            image={image}
            tipKeys={tipKeys}
            keyPrefix={keyPrefix}
            t={t}
          />
        </Container>
      </PageContainer>
    </>
  );
};
