import React, { useCallback, useMemo, useState } from 'react';
import { Form } from '@koopajs/mui';
import { useComponentVisibility, useResourceShow, useResourceUpdate } from '@koopajs/react';
import { Typography, Button, Box, Stack, Divider } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { Alert } from '@mui/material';
import { TextField } from 'components/temp/TextFieldTemp';
import { TextMultiLine } from 'components/temp/TextMultiLineTemp';
import { ICommittee, IMeeting } from 'types';
import { ParticipantsList } from 'components/ParticipantsList';
import { DialogFormTemp } from 'components/temp/DialogFormTemp';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useAppSnackbar } from 'components/hooks/useAppSnackbar';
import { useConfirmDelete } from 'components/hooks/useConfirmDelete';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { theme } from 'components/Core/theme';
import { getSignatureEmailRecipients } from 'utils/getSignatureEmailRecipients';
import { checkIsDecisionTopicType } from 'utils/topicTypeArrays';

export const SealMinutesDialog: React.FC = () => {
  const { t } = useLocale();

  const keyPrefix = 'Dialogs.SealMinutesDialog';
  const { triggerSnackbar } = useAppSnackbar();

  const sendResolutionDialog = useComponentVisibility('sealMinutesDialog');
  const minutesPreviewDialog = useComponentVisibility('minutesPreviewDialog');

  const { meeting } = sendResolutionDialog.getContext() as {
    meeting?: IMeeting;
    defaultValues: { subject: string; message: string };
  };

  const { resource: committee } = useResourceShow<ICommittee>({
    path: '/committees',
    id: meeting?.committeeId
  });

  const signeeIds = useMemo(() => {
    if (meeting && committee) {
      const checkForResolutions = (): boolean => {
        return Boolean(
          meeting?.topics?.some((topic) => {
            return (
              !topic.deletedAt &&
              !topic.lockedAt &&
              topic.resolutionOutcome?.outcome &&
              topic.resolutionOutcome?.outcome !== 'reported' &&
              topic.isResolution &&
              checkIsDecisionTopicType(topic.type)
            );
          })
        );
      };

      const hasResolutions = checkForResolutions();

      const models: ('minutes' | 'resolutions')[] = ['minutes'];
      // only get resolution signees if there are resolutions and committee takes resolutions
      if (hasResolutions && committee.isTakingResolutions) models.push('resolutions');

      return getSignatureEmailRecipients({ meeting, committee, models });
    }
  }, [
    JSON.stringify(meeting?.activeCommitteeMembers),
    JSON.stringify(committee?.operationsRequired),
    JSON.stringify(meeting?.topics),
    committee?.isTakingResolutions
  ]);

  const hasSignees = signeeIds && signeeIds.length > 0;

  const { hasUserTypedDeleteConfirmation, renderConfirmDeleteField, resetConfirmDeleteField } =
    useConfirmDelete({
      i18nKeyPrefix: keyPrefix + '.ConfirmDeleteField'
    });

  const handleShowMinutesPreview = (): void => {
    minutesPreviewDialog.setVisibleWithContext({
      meeting
    });
  };

  const { updateResource } = useResourceUpdate({
    path: `/meetings/${meeting?.id}/approve-meeting`,
    id: '',
    customReducer: {
      path: { resourceType: '/meetings', resourceId: meeting?.id as string },
      mapping: (previousObj: object, newObj: object) => {
        return newObj;
      }
    }
  });

  const handleSubmit = useCallback(
    async (data: object): Promise<boolean> => {
      const formData = data as { message?: string };

      if (!formData.message) {
        formData.message = undefined;
      }
      const res = await updateResource(data);

      if (res) {
        triggerSnackbar({
          snackbarText: t(keyPrefix + '.snackbarSendResolutionSuccess'),
          variant: 'success'
        });

        resetConfirmDeleteField();
      } else {
        triggerSnackbar({
          snackbarText: t(keyPrefix + '.snackbarSendResolutionFail'),
          variant: 'error'
        });
      }

      return res;
    },
    [meeting?.id]
  );

  const meetingReminderOptions = [0.25, 1, 3, 7].map((option) => {
    let label = 'optionLabelDays';
    let count = option;
    if (option > 0 && option < 1) {
      label = 'optionLabelHours';
      count = option * 24;
    }
    return {
      id: `${option}`,
      label: t(`${keyPrefix}.Form.RadioFields.${label}`, { count })
    };
  });

  const handleOnClose = (): void => {
    resetConfirmDeleteField();
  };

  return (
    <DialogFormTemp
      dialogKey="sealMinutesDialog"
      onSubmit={handleSubmit}
      i18n={{
        keyPrefix: `${keyPrefix}.DialogFormSend`
      }}
      maxWidth="md"
      leftAction={<Button onClick={sendResolutionDialog.setHidden}>{t('common:labelCancel')}</Button>}
      secondaryAction={
        <Button onClick={handleShowMinutesPreview}>{t(keyPrefix + '.previewExtractLabel')}</Button>
      }
      dialogProps={{ className: 'rr-block', PaperProps: { 'data-cy': 'seal-minutes-dialog' } }}
      mainActionBtnVariant="contained"
      mainActionBtnSx={{
        transition: 'background-color 0.3s ease-in-out',
        backgroundColor: 'error.main',
        color: 'error.contrastText',

        '&:hover': {
          backgroundColor: 'error.dark'
        }
      }}
      mainActionButtonLabel={hasSignees ? undefined : t(keyPrefix + '.DialogFormSend.ButtonSubmit.labelSeal')}
      isSubmitDisabled={!hasUserTypedDeleteConfirmation}
      onClose={handleOnClose}
    >
      <Alert
        sx={{
          p: 0,
          width: 'fit-content',
          backgroundColor: 'transparent',
          color: 'rgba(0, 0, 0, 0.87)'
        }}
        icon={false}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box
            sx={{
              backgroundColor: 'tertiary.lighter',
              borderRadius: '50%',
              padding: '4px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <LightbulbIcon
              sx={{
                color: 'tertiary.main',
                stroke: `${theme.palette.primary.main}`,
                strokeWidth: 1
              }}
            />
          </Box>
          <Typography>{t(keyPrefix + '.alertAdoptedInMeeting')}</Typography>
        </Stack>
      </Alert>

      {hasSignees && (
        <>
          <Typography sx={{ mb: 2, mt: 1 }}>{t(keyPrefix + '.Form.signatoriesLabel')}</Typography>
          <ParticipantsList
            participants={signeeIds?.map((userId) => ({ userId })) || []}
            chipVariant="outlined"
          />
          <TextField
            name="subject"
            validationRules={{ maxLength: 256 }}
            i18n={{ keyPrefix: keyPrefix + '.Form.FieldSubject' }}
          />
          <TextMultiLine
            rows={4}
            name="message"
            validationRules={{ maxLength: 1000 }}
            isOptional={true}
            i18n={{ keyPrefix: keyPrefix + '.Form.FieldMessage' }}
          />
          <Form.Radio
            name="reminder"
            i18n={{ keyPrefix: keyPrefix + '.Form.RadioFields' }}
            options={meetingReminderOptions}
            row
            sx={{ mt: 1, mb: 0 }}
            isOptional={true}
          />

          <Divider sx={{ mb: '14px', mt: 0.5 }} />
        </>
      )}

      <Stack flexDirection="row" sx={{ ...(!hasSignees ? { mt: 1 } : {}) }}>
        <ErrorOutlineIcon sx={{ mr: 1, color: 'customGrey.darker' }} />
        <Box component="span">{t(`${keyPrefix}.Form.alert`)}</Box>
      </Stack>

      {renderConfirmDeleteField({ sx: { maxWidth: '100%', width: '100%', mt: '14px' } })}
    </DialogFormTemp>
  );
};
