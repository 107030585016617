import React from 'react';
import { useLocale } from '@koopajs/react';
import { EmptyStateTemplate } from './EmptyStateTemplate';
import signImageFR from '../../assets/emptyStates/to-sign_FR.png';
import signImageEN from '../../assets/emptyStates/to-sign_EN.png';
import { useCommitteePermissions } from 'data/permissions/useCommitteePermissions';

interface IEmptyStateProps {
  windowTitle?: string;
  button?: React.ReactNode;
}

export const EmptyStateToSign: React.FC<IEmptyStateProps> = ({ windowTitle, button }) => {
  const { t, locale } = useLocale();
  const keyPrefix = 'ToSign.EmptyState';

  const { isUserResolutionSigner, isUserMinutesSigner, isUserMeetingCreator, isUserResolutionCreator } =
    useCommitteePermissions();

  const tipKeys = [];
  if (isUserResolutionSigner || isUserMinutesSigner) tipKeys.push('tipSignatureNotification');
  if (isUserMeetingCreator) tipKeys.push('tipAdoptMinutes');
  if (isUserResolutionCreator) tipKeys.push('tipAdoptWrittenResolution');

  return (
    <EmptyStateTemplate
      windowTitle={windowTitle}
      pageTitle={t('ToSign.title')}
      emptyStateTitle={t(`${keyPrefix}.title`)}
      emptyStateDescription={t(`${keyPrefix}.description`)}
      image={locale.includes('en') ? signImageEN : signImageFR}
      button={button}
      tipKeys={tipKeys}
      keyPrefix={keyPrefix}
      t={t}
    />
  );
};
