import { Stack, Typography, Box, Button } from '@mui/material';
import { useComponentVisibility, useLocale } from '@koopajs/react';
import { NominationsField } from './NominationsField';
import { ResolutionOutcomeText } from 'components/ResolutionOutcome/ResolutionOutcomeText';
import { ResolutionOutcomeVotes } from 'components/ResolutionOutcome/ResolutionOutcomeVotes';
import { IApprovedTopic, IMeeting, IMinute, ITopic } from 'types';
import { formatTime } from 'utils/DateTime/formatTime';
import { DocumentList } from 'components/DocumentList';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';
import { checkIsDecisionTopicType } from 'utils/topicTypeArrays';
import _ from 'lodash';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { formatDate } from 'utils/DateTime/formatDate';
import { ApprovedMinutesList } from 'components/Minutes/ApprovedMinutesList';
export interface ITopicCardMinutesProps {
  topic: IApprovedTopic | ITopic;
  order: number;
  minutes: IMinute | IMeeting;
}

// component used in the ViewMinutes dialog, Sign minutes page, Review meeting - view version

export const TopicCardMinutes: React.FC<ITopicCardMinutesProps> = (props) => {
  const { topic, order, minutes } = props;

  const isDecisionTopicType = checkIsDecisionTopicType(topic.type);
  const committeeType = useCommitteeName(minutes?.committeeId || '');

  const isReviewMode = Boolean(!minutes.boardApprovedAt);

  const documentsPath = isReviewMode
    ? `/meetings/${topic.meetingId}/topics/${topic.id}/minutes-documents`
    : `/approved-topics/${topic.id}/documents`;

  const hasDocuments = isReviewMode
    ? topic.minutesDocumentsIds && topic.minutesDocumentsIds.length > 0
    : topic.documentsIds && topic.documentsIds.length > 0;

  const isResolutionWithOutcome =
    isDecisionTopicType && topic?.resolutionOutcome?.outcome && topic?.isResolution;

  const { t, locale } = useLocale();

  const { membersAgainst, membersAbstained, membersFor } = topic?.resolutionOutcome || {};
  const showResolutionOutcomeVotes = Boolean(
    membersFor?.length || membersAbstained?.length || membersAgainst?.length
  );

  let adjournmentAt = minutes?.minutesEndedAt;
  if (topic.type === 'adjournment' && topic.version === 1) {
    if (topic.resolutionOutcome?.recordedAt && topic.resolutionOutcome?.outcome === 'approved') {
      adjournmentAt = topic.resolutionOutcome?.recordedAt;
    } else {
      adjournmentAt = '';
    }
  }

  const pastMeetingDialog = useComponentVisibility('pastMeetingDialog');

  const handleOpenPastMeetingDialog = (): void => {
    pastMeetingDialog.setVisibleWithContext({
      pastMeetingId: minutes?.meetingId ? minutes.meetingId : minutes.id
    });
  };

  return (
    <>
      <Box sx={{ minWidth: 0 }} data-cy="topic-card-minutes" className={`topic-card-minutes_${topic.type}`}>
        {/* RESOLUTION - Not displayed on print view */}
        {isResolutionWithOutcome && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <Typography
                variant="overline"
                data-cy="topic-card-minutes_resolution-number"
                sx={{ display: 'block' }}
              >
                {t('common:resolution_one')}
                {topic.resolutionNumber ? ` #${topic.resolutionNumber}` : ''}
              </Typography>
            </Box>
          </Box>
        )}
        <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            sx={{ flex: 1 }}
          >
            <Stack flexGrow={1} sx={{ minWidth: 0 }}>
              {/* ORDER & TITLE */}

              <Typography
                sx={{
                  width: '100%',
                  fontWeight: 'bold',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
                data-cy="topic-card-minutes_title"
              >
                <>
                  {order}. {topic.title ? topic.title : t(`common:topicTypes.${topic.type}`)}
                </>
              </Typography>
            </Stack>
          </Stack>
          {/* QUORUM  */}
          {topic.type === 'quorum' && (
            <Box>
              <Typography data-cy="topic-card-minutes_minutes-started-at">
                {t('MeetingStateReview.meetingStarted', {
                  time: formatTime({ isoString: minutes?.minutesStartedAt || '', locale })
                })}
              </Typography>

              {topic.quorumReachedAt && (
                <Typography data-cy="topic-card-minutes_quorum-reached-at">
                  {t('MeetingStateReview.quorumReached', {
                    time: formatTime({ isoString: topic?.quorumReachedAt, locale })
                  })}
                </Typography>
              )}
            </Box>
          )}
          {/* NOMINATIONS */}
          {topic.type === 'nominations' && <NominationsField topic={topic} />}
          {/* AGENDA ADOPTION */}
          {topic.type === 'agendaApproval' && (
            <Box>
              {' '}
              <Button
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  textTransform: 'none',
                  color: 'link.main',
                  maxWidth: '100%'
                }}
                onClick={handleOpenPastMeetingDialog}
              >
                <CalendarTodayIcon />
                <Typography
                  variant="body1"
                  sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                  component="span"
                >
                  {formatDate({
                    isoString: minutes?.startDateTime as string,
                    locale,
                    format: 'DATE_FULL'
                  })}
                  {' | '}
                  {committeeType}

                  {' | '}

                  {minutes?.title}
                </Typography>
              </Button>
            </Box>
          )}
          {/* PAST MINUTES THAT HAVE BEEN APPROVED */}
          {topic.type === 'pastMinutesApproval' && (
            <ApprovedMinutesList approvedMinutes={topic.pastMeetingsToApprove} />
          )}
          {/* ADJOURNMENT */}
          {topic.type === 'adjournment' && (
            <Box>
              {minutes?.minutesEndedAt && adjournmentAt ? (
                <Typography data-cy="topic-card-minutes_adjournment-at">
                  {t('MeetingStateReview.adjournmentAt', {
                    time: formatTime({
                      isoString: adjournmentAt,
                      locale
                    })
                  })}
                </Typography>
              ) : null}
            </Box>
          )}
          {/* BODY FIELD */}
          {[
            'quorum',
            'nominations',
            'agendaApproval',
            'pastMinutesApproval',
            'ceoReport',
            'information',
            'discussion',
            'resolution',
            'miscellaneous',
            'adjournment'
          ].includes(topic.type) && (
            <>{topic.notes && <RichTextReadOnly value={topic.notes} style="border-width: 0;" />}</>
          )}
          {/* RESOLUTION OUTCOME */}
          {isDecisionTopicType && topic?.resolutionOutcome?.outcome && (
            <Box>
              <Stack spacing={1}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderRadius: '4px',
                    alignItems: 'center'
                  }}
                >
                  <ResolutionOutcomeText topic={topic} />
                </Box>
                {showResolutionOutcomeVotes && <ResolutionOutcomeVotes topic={topic} hideIcon />}
              </Stack>
            </Box>
          )}
          {/* DOCUMENTS LIST */}
          {topic.type !== 'inCamera' && hasDocuments && (
            <Box>
              <Typography variant="caption" sx={{ mb: '4px', opacity: 0.6 }}>
                {t('common:relatedDocumentationLabel')}
              </Typography>
              <DocumentList path={documentsPath} />
            </Box>
          )}
        </Stack>
      </Box>
    </>
  );
};
