import { Box, Paper, Typography, Divider, Link as MUILink } from '@mui/material';
import { ITopic } from 'types';
import { BoardMemberViewTopicCardAccordion } from '../Components/Accordion';
import { BoardMemberViewTopicCardAccordionSummaryChildren } from '../Components/AccordionSummaryChildren';
import { DateTime } from 'luxon';
import { useLocale } from '@koopajs/react';
import { DateTimeStartEnd } from 'components/DateTimeStartEnd';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { OpenInNew as OpenInNewIcon, LocationOn as LocationOnIcon } from '@mui/icons-material';
import { useBoardMemberViewContext } from 'components/BoardMemberView/BoardMemberViewProvider';
import { ParticipantsWithTitlesAccordionList } from './ParticipantsWithTitlesAccordionList';
import { getCommitteeMembersTitlesObj } from 'utils/getCommitteeMembersTitlesObj';
import { TimeStartEnd } from 'components/TimeStartEnd';
import { TopicDescription } from '../Components/TopicDescription';
import { PanoramaDocumentWrapper } from 'components/PanoramaDocumentWrapper';
import { useDocumentCount } from 'components/hooks/BoardMemberView/useDocumentCount';
import { DocumentList } from '../Components/DocumentList';

interface IBoardMemberViewQuorumCard {
  topic: ITopic;
  index: number;
}

export const BoardMemberViewQuorumCard: React.FC<IBoardMemberViewQuorumCard> = (props) => {
  const { topic, index } = props;

  const boardMemberViewContext = useBoardMemberViewContext();
  const { meeting } = boardMemberViewContext;

  const {
    linkDocumentsCount,
    setLinkDocumentsCount,
    fileDocumentsCount,
    setFileDocumentsCount,
    hasDocuments
  } = useDocumentCount({ topic });

  const { t } = useLocale();

  const committeeType = useCommitteeName(meeting?.committeeId || '');

  const committeeMembersTitlesObj = getCommitteeMembersTitlesObj(meeting?.activeCommitteeMembers);

  const keyPrefix = 'BoardMemberView.TopicCards.Quorum';

  const startTimeISOString =
    meeting?.startTime &&
    DateTime.fromFormat(meeting?.startTime as string, 'HH:mm', {
      zone: 'UTC'
    }).toString();
  const endTimeISOString =
    meeting?.endTime &&
    DateTime.fromFormat(meeting?.endTime as string, 'HH:mm', {
      zone: 'UTC'
    }).toString();

  return (
    <BoardMemberViewTopicCardAccordion
      topic={topic}
      index={index}
      accordionSummary={
        <BoardMemberViewTopicCardAccordionSummaryChildren
          topic={topic}
          index={index}
          linkDocumentsCount={linkDocumentsCount}
          fileDocumentsCount={fileDocumentsCount}
        />
      }
      accordionDetails={
        <Box>
          {topic.description && <TopicDescription description={topic.description} />}
          {hasDocuments && (
            <Box sx={{ mb: '24px' }}>
              <DocumentList
                path={`/meetings/${topic.meetingId}/topics/${topic.id}/documents`}
                setLinkDocumentsCount={setLinkDocumentsCount}
                setFileDocumentsCount={setFileDocumentsCount}
              />
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <PanoramaDocumentWrapper>
              <Typography variant="overline">{t(keyPrefix + '.meetingNotice')}</Typography>
              <Divider />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: { xs: 'flex-start', md: 'center' },
                    gap: 1,
                    flexDirection: { xs: 'column', md: 'row' },
                    mb: 1
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap'
                    }}
                  >
                    <DateTimeStartEnd
                      isoStringDate={
                        meeting?.startDateTime ||
                        (meeting?.startDate
                          ? DateTime.fromFormat(`${meeting.startDate}`, 'yyyy-MM-dd').toString()
                          : undefined)
                      }
                      dateFormat="DATE_HUGE"
                    />
                  </Typography>
                  <Typography variant="h6" sx={{ display: { xs: 'none', md: 'block' } }}>
                    |
                  </Typography>
                  <Typography variant="h6" sx={{ whiteSpace: 'normal' }}>
                    {committeeType}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    flexWrap: 'wrap'
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      whiteSpace: 'normal'
                    }}
                  >
                    {meeting?.title}
                  </Typography>
                  <Typography variant="body2" sx={{ opacity: 0.6, display: { xs: 'none', sm: 'block' } }}>
                    &#183;
                  </Typography>

                  <Typography variant="body2" sx={{ opacity: 0.6 }}>
                    <TimeStartEnd
                      isoStringStart={meeting?.startDateTime || startTimeISOString}
                      isoStringEnd={meeting?.endDateTime || endTimeISOString}
                    />
                  </Typography>
                </Box>
                {/* LOCATION */}

                {meeting?.location?.type && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'baseline',
                      flexDirection: { xs: 'column', sm: 'row' },
                      gap: { xs: 1, sm: 0 }
                    }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      {t(`common:locationType.${meeting?.location?.type}`)}
                    </Typography>
                    {meeting?.location?.url && (
                      <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 0 }}>
                        <Box
                          sx={{
                            mx: 1,
                            opacity: 0.6,
                            display: {
                              xs: 'none',
                              sm: 'block'
                            }
                          }}
                        >
                          &#183;
                        </Box>
                        <MUILink
                          href={meeting?.location?.url}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            textDecoration: 'none',
                            color: 'link.main',
                            minWidth: '0px'
                          }}
                          target="_blank"
                          rel="noopener"
                        >
                          <OpenInNewIcon fontSize="small" sx={{ mr: 0.5 }} />
                          <Typography variant="body2">{t('common:meetingLink')}</Typography>
                        </MUILink>
                      </Box>
                    )}
                    {meeting?.location?.address && (
                      <Box sx={{ display: 'flex', alignItems: 'flex-start', flex: 1 }}>
                        <Box
                          sx={{
                            mx: 1,
                            display: {
                              xs: 'none',
                              sm: 'block'
                            }
                          }}
                        >
                          &#183;
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'start' }}>
                          <LocationOnIcon fontSize="small" sx={{ mt: '2px', mr: 0.5, opacity: '0.6' }} />
                          <Typography variant="body2" sx={{ opacity: '0.6', whiteSpace: 'normal' }}>
                            {meeting.location?.address}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
              {/* GUESTS*/}
              {meeting?.participants && meeting.participants.length > 0 && (
                <ParticipantsWithTitlesAccordionList
                  userIds={meeting.participants.map((p) => p.userId)}
                  committeeMembersTitles={committeeMembersTitlesObj}
                  label={`${t('common:guests')} (${meeting?.participants?.length})`}
                />
              )}
            </PanoramaDocumentWrapper>
          </Box>
        </Box>
      }
    />
  );
};
