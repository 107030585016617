import { Box, Paper, Typography, Divider } from '@mui/material';
import { ITopic } from 'types';
import { BoardMemberViewTopicCardAccordion } from '../Components/Accordion';
import { BoardMemberViewTopicCardAccordionSummaryChildren } from '../Components/AccordionSummaryChildren';
import { useLocale } from '@koopajs/react';
import { useBoardMemberViewContext } from 'components/BoardMemberView/BoardMemberViewProvider';
import { AgendaApprovalTopic } from './AgendaApprovalTopic';
import { calculateTopicsStartTime } from 'utils/calculateTopicsStartTime';
import { TimerOutlined as TimerOutlinedIcon } from '@mui/icons-material';
import { calculateMeetingDuration } from 'utils/calculateMeetingDuration';
import { TopicDescription } from '../Components/TopicDescription';
import { PanoramaDocumentWrapper } from 'components/PanoramaDocumentWrapper';
import { DocumentList } from '../Components/DocumentList';
import { useDocumentCount } from 'components/hooks/BoardMemberView/useDocumentCount';
interface IBoardMemberViewAgendaApprovalCard {
  topic: ITopic;
  index: number;
}

export const BoardMemberViewAgendaApprovalCard: React.FC<IBoardMemberViewAgendaApprovalCard> = (props) => {
  const { topic, index } = props;

  const boardMemberViewContext = useBoardMemberViewContext();
  const { meeting } = boardMemberViewContext;

  const {
    linkDocumentsCount,
    setLinkDocumentsCount,
    fileDocumentsCount,
    setFileDocumentsCount,
    hasDocuments
  } = useDocumentCount({ topic });

  const { t } = useLocale();

  const topics = meeting && calculateTopicsStartTime(meeting);
  const meetingDuration = calculateMeetingDuration(t, meeting?.topics);

  const keyPrefix = 'BoardMemberView.TopicCards.AgendaApproval';

  return (
    <BoardMemberViewTopicCardAccordion
      topic={topic}
      index={index}
      accordionSummary={
        <BoardMemberViewTopicCardAccordionSummaryChildren
          topic={topic}
          index={index}
          linkDocumentsCount={linkDocumentsCount}
          fileDocumentsCount={fileDocumentsCount}
        />
      }
      accordionDetails={
        <Box>
          {topic.description && <TopicDescription description={topic.description} />}

          {hasDocuments && (
            <Box sx={{ mb: '24px' }}>
              <DocumentList
                path={`/meetings/${topic.meetingId}/topics/${topic.id}/documents`}
                setLinkDocumentsCount={setLinkDocumentsCount}
                setFileDocumentsCount={setFileDocumentsCount}
              />{' '}
            </Box>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <PanoramaDocumentWrapper>
              <Typography variant="overline">{t('common:agenda')}</Typography>
              <Divider />
              <Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
                  {topics?.map((topic, index, array) => {
                    return (
                      <Box key={topic.id}>
                        <AgendaApprovalTopic topic={topic} order={index + 1} />
                      </Box>
                    );
                  })}
                  <Divider />
                  <Typography
                    variant="body1"
                    sx={{ display: 'flex', justifyContent: 'flex-end', whiteSpace: 'normal' }}
                  >
                    <TimerOutlinedIcon color="action" sx={{ mr: 1 }} />
                    {t(keyPrefix + '.estimatedDurationLabel', { time: meetingDuration })}
                  </Typography>
                </Box>
              </Box>
            </PanoramaDocumentWrapper>
          </Box>
        </Box>
      }
    />
  );
};
