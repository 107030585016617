import React, { useCallback } from 'react';
import { Dialog, ErrorMessage, Form } from '@koopajs/mui';
import { useResourceCreate, useComponentVisibility, useResourceShow } from '@koopajs/react';
import { Typography, Button } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { TrackEvent } from '@koopajs/app';
import { useSnackbar } from 'notistack';
import { IconButton, Alert } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { TextField } from 'components/temp/TextFieldTemp';
import { TextMultiLine } from 'components/temp/TextMultiLineTemp';
import { IResolution } from 'types';
import { useHistory } from 'react-router-dom';
import { ParticipantsList } from 'components/ParticipantsList';

export const SendWrittenResolution: React.FC = () => {
  const { t } = useLocale();

  const history = useHistory();

  const keyPrefix = 'Dialogs.DialogSendWrittenResolution';
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const sendResolutionDialog = useComponentVisibility('sendWrittenResolution');

  const { resolution, isCustomResNum } = sendResolutionDialog.getContext() as {
    resolution: IResolution;
    isCustomResNum?: boolean;
  };

  const isResolutionSent = resolution?.state === 'attached' && resolution.attachedToEmail?.sentForAdoptionAt;

  const missingSignaturesUserIds = resolution?.membersLeftToSign?.map((user) => user.userId);

  const { createResource } = useResourceCreate({
    path: `/resolutions/${resolution?.id}/adoption`,
    customReducer: {
      path: {
        resourceType: '/resolutions',
        resourceId: resolution?.id
      },
      mapping: (prevObj: unknown, newObj: unknown) => {
        return newObj as IResolution;
      }
    }
  });

  const { errorMessage } = useResourceShow({ path: '/resolutions', id: resolution?.id });

  const isCustomResNumMissing = isCustomResNum && !resolution?.resolutionNumber;

  const handleSubmit = useCallback(
    async (data: object): Promise<boolean> => {
      const formData = data as { message?: string };

      if (!formData.message) {
        formData.message = undefined;
      }
      const res = await createResource(data);

      if (res) {
        enqueueSnackbar(t(keyPrefix + '.snackbarSendResolutionSuccess'), {
          variant: 'success',
          action: (key) => (
            // eslint-disable-next-line react/jsx-no-bind
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <CloseIcon sx={{ color: 'white' }} />
            </IconButton>
          )
        });

        history.push(`/resolutions/${resolution.id}/sign`);

        TrackEvent('invitation-sent');
      }

      return res;
    },
    [resolution?.id]
  );

  const meetingReminderOptions = [0.25, 1, 3, 7].map((option) => {
    let label = 'optionLabelDays';
    let count = option;
    if (option > 0 && option < 1) {
      label = 'optionLabelHours';
      count = option * 24;
    }
    return {
      id: `${option}`,
      label: t(`${keyPrefix}.Form.RadioFields.${label}`, { count })
    };
  });

  return (
    <Dialog.Form
      dialogKey="sendWrittenResolution"
      onSubmit={handleSubmit}
      i18n={{
        keyPrefix: isResolutionSent ? `${keyPrefix}.DialogFormResend` : `${keyPrefix}.DialogFormSend`
      }}
      maxWidth="md"
      leftAction={<Button onClick={sendResolutionDialog.setHidden}>{t('common:labelCancel')}</Button>}
      dialogProps={{ className: 'rr-block', PaperProps: { 'data-cy': 'send-resolution-dialog' } }}
    >
      <ErrorMessage error={errorMessage} />
      {isCustomResNumMissing && !isResolutionSent && (
        <Alert severity="warning">{t(keyPrefix + '.Form.customResNumberMissingAlert')}</Alert>
      )}
      <Typography sx={{ my: 2 }}>{t(keyPrefix + '.Form.signatoriesLabel')}</Typography>
      {isResolutionSent ? (
        <>
          <Typography variant="body2">{t(keyPrefix + '.missingSignatures')}</Typography>
          <ParticipantsList
            participants={missingSignaturesUserIds?.map((userId) => ({ userId })) || []}
            chipVariant="outlined"
          />
        </>
      ) : (
        <>
          <ParticipantsList
            participants={resolution?.attachedToEmail?.recipientIds?.map((userId) => ({ userId })) || []}
            chipVariant="outlined"
          />
        </>
      )}
      <TextField
        name="subject"
        validationRules={{ maxLength: 256 }}
        i18n={{ keyPrefix: keyPrefix + '.Form.FieldSubject' }}
      />
      <TextMultiLine
        rows={4}
        name="message"
        validationRules={{ maxLength: 1000 }}
        isOptional={true}
        i18n={{ keyPrefix: keyPrefix + '.Form.FieldMessage' }}
      />

      <Form.Radio
        name="reminder"
        i18n={{ keyPrefix: keyPrefix + '.Form.RadioFields' }}
        options={meetingReminderOptions}
        row
        sx={{ mt: 1, pt: 1 }}
        variant="dense"
        isOptional={true}
      />
    </Dialog.Form>
  );
};
