import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Avatar,
  Stack
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { User, useLocale } from '@koopajs/react';
import React, { useState, useEffect, useRef } from 'react';
import { SystemStyleObject } from '@mui/system';

interface IVisibleByProps {
  title: string;
  prefixVisibleToSome: string;
  prefixVisibleToAll: string;
  visibleByState: 'some' | 'all';
  usersVisibleByIds?: string[];
  chip: {
    label: string;
    sx?: SystemStyleObject;
  };
  isVisibleByAllMessageHidden?: boolean;
  icon?: React.ReactNode;
}

export const VisibleByAccordion: React.FC<IVisibleByProps> = (props) => {
  const {
    title,
    usersVisibleByIds,
    chip,
    visibleByState,
    prefixVisibleToSome,
    prefixVisibleToAll,
    isVisibleByAllMessageHidden,
    icon
  } = props;

  const { t } = useLocale();
  const keyPrefix = 'Components.VisibleByAccordion';

  const containerRef = useRef<HTMLDivElement>(null);
  const chipsRefs = useRef<Array<HTMLDivElement | null>>([]);
  const [alignment, setAlignment] = useState<'flex-start' | 'flex-end'>('flex-start');

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const totalItemsWidth = chipsRefs.current.reduce((total, ref) => {
        return total + (ref?.offsetWidth ?? 0);
      }, 0);

      if (totalItemsWidth > containerWidth) {
        setAlignment('flex-start');
      } else {
        setAlignment('flex-end');
      }
    }
  }, [usersVisibleByIds?.length]);

  return (
    <>
      {visibleByState === 'some' && Boolean(usersVisibleByIds?.length) && (
        <Accordion
          elevation={0}
          sx={{
            borderRadius: '4px'
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="visibleByAccordionSummary-content"
            id="visibleByAccordionSummary-header"
            sx={{
              alignItems: 'center',
              gap: 2,
              minHeight: '0px!important',
              ...{
                '& .MuiAccordionSummary-content': { m: '0px!important' }
              },
              padding: 0
            }}
          >
            <Box sx={{ width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  justifyContent: 'space-between',
                  alignItems: { md: 'center' }
                }}
              >
                <Stack direction="row" gap="14px 24px" alignItems="center" flexWrap="wrap">
                  {icon && <Avatar>{icon}</Avatar>}
                  <Typography variant="overline">{title}</Typography>
                  <Chip label={chip.label} sx={chip.sx} data-cy="visible-by-accordion_state-chip" />
                </Stack>
                <Typography
                  sx={{
                    display: 'flex',
                    mt: { xs: 3, md: 0 }
                  }}
                  variant="body2"
                >
                  {/* TODO: with no length, this breaks */}
                  {t(keyPrefix + '.accordionTitle', {
                    count: usersVisibleByIds?.length,
                    prefix: prefixVisibleToSome
                  })}
                </Typography>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: { xs: 0, md: '8px 16px 0px 16px' }, mt: { xs: 2, md: 0 } }}>
            <Box
              ref={containerRef}
              sx={{
                display: 'flex',
                justifyContent: { xs: 'flex-start', md: alignment },
                flexWrap: 'wrap'
              }}
            >
              {usersVisibleByIds?.map((userId, index) => {
                return (
                  <div ref={(el) => (chipsRefs.current[index] = el)} key={index}>
                    <User key={userId as string} id={userId as string}>
                      {(profile) => {
                        return (
                          <Chip
                            component="li"
                            sx={{
                              mr: 2,
                              my: 0.5,
                              '& .MuiChip-label': {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: { xs: '200px', md: '100%' }
                              }
                            }}
                            avatar={<Avatar alt={profile?.username} src={profile?.profilePicture} />}
                            label={profile?.username}
                          />
                        );
                      }}
                    </User>
                  </div>
                );
              })}
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
      {visibleByState === 'all' && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: { xs: 'flex-start', md: 'center' },
            flexDirection: { xs: 'column', md: 'row' }
          }}
        >
          <Stack direction="row" gap="14px 24px" alignItems="center" flexWrap="wrap">
            {icon && <Avatar>{icon}</Avatar>}
            <Typography variant="overline">{title}</Typography>

            <Chip label={chip.label} sx={chip.sx} data-cy="visible-by-accordion_state-chip" />
          </Stack>
          {!isVisibleByAllMessageHidden && (
            <Box sx={{ display: { xs: 'none', md: 'flex' }, my: { xs: 1, md: 0 }, mt: { xs: 3, md: 0 } }}>
              <Typography variant="body2">
                {t(keyPrefix + '.accordionTitleVisibleByAll', { prefix: prefixVisibleToAll })}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
