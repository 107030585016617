import type React from 'react';
import { Typography, Box, Paper } from '@mui/material';
import TipItem from './TipItem';

interface IEmptyStatePaperProps {
  emptyStateTitle: string;
  emptyStateDescription: string;
  image: string;
  tipKeys?: string[];
  keyPrefix: string;
  t: (key: string) => string;
}

export const EmptyStatePaper: React.FC<IEmptyStatePaperProps> = ({
  emptyStateTitle,
  emptyStateDescription,
  image,
  tipKeys,
  keyPrefix,
  t
}) => {
  const hasTips = tipKeys && tipKeys.length > 0;

  return (
    <Paper
      elevation={0}
      sx={{
        maxWidth: '900px',
        borderRadius: '40px',
        width: '100%',
        padding: 4,
        backgroundColor: '#F3F7F9'
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h6" component="h6" sx={{ mb: 2, textAlign: 'center' }}>
          {emptyStateTitle}
        </Typography>

        <Box sx={{ textAlign: 'left', width: '100%', mb: 2 }}>
          <Typography variant="body2">{emptyStateDescription}</Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: hasTips ? { xs: 'column', md: 'row' } : 'column',
            alignItems: 'center',
            justifyContent: hasTips ? 'flex-start' : 'center',
            width: '100%'
          }}
        >
          <Box
            component="img"
            src={image}
            loading="lazy"
            sx={{
              width: hasTips ? { xs: '60%', md: '40%' } : '60%',
              mb: hasTips ? { xs: 2, md: 0 } : 0,
              maxWidth: '100%',
              height: 'auto'
            }}
          />
          {hasTips && (
            <Box
              sx={{
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                ml: { xs: 0, md: 4 },
                mt: { xs: 2, md: 0 },
                width: { xs: '100%', md: '60%' }
              }}
            >
              {tipKeys.map((tipKey, index) => (
                <TipItem key={index} tipKey={tipKey} keyPrefix={keyPrefix} t={t} />
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
};
