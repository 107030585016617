import type React from 'react';
import { Typography, Box } from '@mui/material';

interface EmptyStatePageHeaderProps {
  pageTitle: string;
  button?: React.ReactNode;
}

const EmptyStatePageHeader: React.FC<EmptyStatePageHeaderProps> = ({ pageTitle, button }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch'
      }}
    >
      <Typography variant="h1" sx={{ fontSize: '24px' }}>
        {pageTitle}
      </Typography>

      {button}
    </Box>
  );
};

export default EmptyStatePageHeader;
